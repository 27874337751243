import React, { useContext } from "react";
import Axios from "axios";
import Dropzone from "react-dropzone";
import RaffleImageUploadList from "./RaffleImageUploadList";
import { AppContext } from "../../../../../context/AppContext";

const RaffleImageUpload = ({ images, update, closeModal }) => {
  const { messages } = useContext(AppContext);

  const uploadImage = async (e) => {
    if (!e || !Array.isArray(e)) return false;

    try {
      const uploadedImages = [];

      for await (let image of e) {
        const formData = new FormData();
        formData.append("image", image);

        const { data } = await Axios.post(
          `${process.env.REACT_APP_API}/api/imageUpload`,
          formData,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );

        if (data.file) {
          uploadedImages.push({
            path: data.file,
            keyImage: images.length > 0 || uploadedImages.length > 0 ? false : true,
          });
        }
      }

      update("images", [...images, ...uploadedImages]);
    } catch (err) {
      if (err.response && err.response.data) {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg },
        });
      }
    }
  };

  const deleteImage = (index) => {
    if (index < 0) return false;

    update(
      "images",
      images.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Upload</h2>

        <div>
          <div className="form-row">
            <Dropzone
              multiple={true}
              accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
              onDropAccepted={(e) => uploadImage(e)}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="input-dropzone">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="form-row">
            <RaffleImageUploadList images={images} update={update} deleteImage={deleteImage} />
          </div>
          <div className="form-row">
            <button onClick={closeModal} className="btn btn-admin">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleImageUpload;
