import React from "react";
import ConfigPromotedRaffle from "./ConfigPromotedRaffle";

const ConfigPromotedRaffles = ({ promotedRaffles = [] }) => {
  const main = promotedRaffles.find(({ position }) => position === "main");
  const secondaries = promotedRaffles.filter(({ position }) => position === "secondary");

  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">Homepage Promoted Raffles</h2>

        {!promotedRaffles.length && <p>Database setup error!</p>}

        {main && <ConfigPromotedRaffle id={main._id} large={true} raffle={main.raffle} />}

        <div>
          {secondaries.map((raffle) => (
            <ConfigPromotedRaffle id={raffle._id} raffle={raffle.raffle} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConfigPromotedRaffles;
