import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Search } from "react-feather";
import LoadingSpinner from "../../../../includes/LoadingSpinner";
import Axios from "axios";
import { CSVLink } from "react-csv";
import moment from "moment";

const RaffleSalesHeader = ({ id, query, setQuery, openModal }) => {
  const [downloading, setDownloading] = useState(true);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const downloadCSVData = async () => {
      if (!csvData.length) {
        const { data } = await Axios.get(
          `${process.env.REACT_APP_API}/api/raffleTickets/raffle/${id}/purchasedCSV`
        );

        setCsvData(
          data.map((ticket) => ({
            orderId: ticket.orderId || "Manual Entry",
            user: !ticket.user?.firstName
              ? "[Deleted User]"
              : `${ticket.user.firstName} ${ticket.user.lastName}`,
            phone: !ticket.user?.phone
              ? "[Deleted User]"
              : ticket.user.phone,
            email: !ticket.user?.email
              ? "[Deleted User]"
              : ticket.user.email,
            ticketNum: ticket.ticketNum,
            questionAnswer: ticket.questionAnswer,
            createdAt: moment(ticket.createdAt).format("DD/MM/YYYY"),
          }))
        );
        setDownloading(false);
      }
    };

    downloadCSVData();
  }, [id, csvData.length]);

  return (
    <div className="row no-padding all-raffles-header">
      <div className="col">
        <h1 className="admin-title">Entries</h1>
      </div>
      <div className="col right">
        <CSVLink
          data={csvData}
          filename="purchased-raffle-tickets.csv"
          onClick={() => !downloading}
          className="btn btn-admin"
          style={{ marginRight: 20 }}
        >
          {downloading ? <LoadingSpinner /> : "Download to CSV"}
        </CSVLink>
        <button onClick={openModal} className="btn btn-admin">
          Add Entry
        </button>
        <div className="search-bar" style={{ marginRight: 20 }}>
          <input
            type="search"
            value={query}
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
          />
          <button>
            <Search />
          </button>
        </div>
        <Link to={`/admin/raffles/${id}`} className="btn btn-admin">
          Back
        </Link>
      </div>
    </div>
  );
};

export default RaffleSalesHeader;
