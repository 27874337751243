import React, { useState, useContext } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import Axios from "axios";
import LoadingSpinner from "../includes/LoadingSpinner";
import { AlertCircle } from "react-feather";
import { AppContext } from "../../context/AppContext";

const ResetPassword = () => {
  const { messages } = useContext(AppContext);
  const { email, token } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/auth/verify-reset-token`, { email, token })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setInvalidToken(true);
      });
  }, [email, token]);

  const submit = (e) => {
    e.preventDefault();
    setRequestLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth/reset-password`, {
      email,
      token,
      newPassword,
      newPasswordAgain,
    })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: {
            type: "success",
            msg: "Password reset successful! You can now log in with your new password",
          },
        });
        history.push("/auth/login");
      })
      .catch((error) => {
        setRequestLoading(false);

        let msg = "Something went wrong.";
        if (error.response && error.response.data) msg = error.response.data.msg;
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg },
        });
      });
  };

  return (
    <div className="row center-cols">
      <div className="col-5">
        <div className="slate">
          {loading ? (
            <LoadingSpinner className="center" />
          ) : invalidToken ? (
            <div>
              <div className="center">
                <AlertCircle size={40} color="#ff313d" />
                <h1>Token Invalid</h1>
                <Link to="/auth/forgot-password" className="btn">
                  Reset Password Again
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <h1 className="title">Reset Password</h1>

              <form onSubmit={submit}>
                <div className="form-row">
                  <input
                    type="password"
                    className="input"
                    id="new-password"
                    autoComplete="new-password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>

                <div className="form-row">
                  <input
                    type="password"
                    className="input"
                    id="new-password-again"
                    autoComplete="new-password-again"
                    placeholder="New Password Again"
                    value={newPasswordAgain}
                    onChange={(e) => setNewPasswordAgain(e.target.value)}
                  />
                </div>

                <button type="submit" className="btn form-btn" disabled={requestLoading}>
                  {requestLoading ? <LoadingSpinner /> : "Reset Password"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
