import React from "react";
import moment from "moment";

const Winner = ({ winner }) => {
  const image = `url(${winner.image})`;
  
  return (
    <div className="winner-card">
      <div className="winner-image" style={{ backgroundImage: image }} />
      <div className="winner-details">
        <h3 className="winner-name">
          {winner.name}
        </h3>
        <h3 className="winner-prize">
          {winner.raffle}
        </h3>
        <div className="winner-ticket">
          <p className="ticket-number">TICKET <strong>#{winner.ticketNumber}</strong></p>
        </div>
        <p className="verify-draw">
          <a target="_blank" rel="noopener noreferrer" href={winner.liveDrawUrl}>
            LIVE DRAW
          </a>
          {" • "}
          <a target="_blank" rel="noopener noreferrer" href="/all">
            BUY TICKETS
          </a>
        </p>
        <p className="draw-date">
          <strong>Draw Date: </strong>{moment(winner.drawDate).format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  );
};

export default Winner;