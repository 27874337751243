import React from "react";
import Slider from "infinite-react-carousel";
import useMediaQuery from "../../../utils/useMediaQuery";

const Testimonials = ({ testimonials: testimonialsData }) => {
  const isMobile = useMediaQuery("(max-width: 1110px)");

  if (!testimonialsData.testimonials || !testimonialsData.testimonials.length) {
    return null;
  }

  return (
    <div className="row">
      <div className="row gutter">
        <div className="col">
          <div className="testimonials">
            <h1 className="title">Testimonials</h1>

            <div>
              <Slider
                className="testimonial-slider"
                dots={true}
                slidesToShow={isMobile ? 1 : 2}
                virtualList={true}
              >
                {testimonialsData.testimonials.map(({ image, quote, author }, i) => (
                  <div className="carousel-slide" key={i}>
                    <div
                      className="testimonial"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    >
                      <div className="testimonial-shade" />
                      <div className="testimonial-inner">
                        <p>{quote}</p>
                        <button className="btn btn-small">{author}</button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
