import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";

const AddEditPage = () => {
  const { messages } = useContext(AppContext);
  const history = useHistory();
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [slugField, setSlugField] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (slug) {
      setLoading(true);

      Axios.get(`${process.env.REACT_APP_API}/api/dynamicPages/${slug}`).then(({ data }) => {
        setId(data._id);
        setName(data.name);
        setSlugField(data.slug);
        setContent(data.content);
        setLoading(false);
      });
    }
  }, [slug]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await Axios.post(`${process.env.REACT_APP_API}/api/dynamicPages${id ? `/${id}` : ""}`, {
        name,
        slug: slugField,
        content,
      });

      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Successfully created page!" },
      });
      history.push("/admin/dynamic-pages");
    } catch (e) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "There was a problem creating this page" },
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          {loading ? (
            <LoadingSpinner dark={true} className="center" />
          ) : (
            <form onSubmit={onSubmit}>
              {/* title */}
              <div className="row no-padding">
                <div className="col">
                  <h1 className="admin-title">{id ? "Edit" : "Create"} Page</h1>
                </div>
                <div className="col right">
                  <Link className="btn btn-admin" to="/admin/dynamic-pages">
                    Back
                  </Link>
                </div>
              </div>

              {/* content */}
              <div className="form-row">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="admin-input white"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                  required={true}
                />
              </div>

              <div className="form-row">
                <label htmlFor="slug">Slug</label>
                <input
                  type="text"
                  className="admin-input white"
                  value={slug}
                  onChange={(e) => setSlugField(e.target.value)}
                  required={true}
                />
              </div>

              <div className="form-row">
                <label htmlFor="slug">Content</label>
                <textarea
                  className="admin-input white"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  rows={30}
                />
              </div>

              <div className="form-row">
                <button type="submit" className="btn btn-admin">
                  {id ? "Edit" : "Create"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddEditPage;
