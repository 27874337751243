import React, { useState } from "react";

const RaffleModifyTicketGen = ({
  maxEntries,
  maxEntriesPerPerson,
  ticketMatrix,
  luckyDipDefault,
  luckyDipsOnly,
  allowPromoBalance,
  guaranteedWinnerInfoBox,
  update,
}) => {
  const [letterOptions, setLetterOptions] = useState([]);
  const [minMaxEntries] = useState(maxEntries || 1);

  const maxEntriesChanged = (entries, noUpdate = false) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""); // array of letters
    const newLetterOptions = [];

    if (entries > 1) {
      for (let i = 0; i < letters.length; i++) {
        if (entries % (i + 1) === 0 && i) {
          newLetterOptions.push({
            toLetterKey: i,
            toLetter: letters[i],
            ticketsPerLetter: entries / (i + 1),
          });
        }
      }

      setLetterOptions(newLetterOptions);
    }

    if (!noUpdate) {
      update("maxEntries", entries);
      update("ticketMatrix", {
        useLetters: false,
        ticketsPerLetter: entries,
      });
    }
  };

  const letterChange = (letter) => {
    const option = letterOptions.find(({ toLetter }) => toLetter === letter);

    if (option && maxEntries > 1) {
      const { toLetter, toLetterKey, ticketsPerLetter } = option;

      update("ticketMatrix", {
        ...ticketMatrix,
        toLetter,
        toLetterKey,
        ticketsPerLetter,
      });
    }
  };

  if (minMaxEntries > 1 && !letterOptions.length) {
    maxEntriesChanged(maxEntries, true);
  }

  return (
    <div className="admin-slate">
      <h2>Ticket Configuration</h2>

      <div className="row no-padding gutter" style={{ marginBottom: 20 }}>
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="letter">Max Entries</label>
            <input
              type="number"
              className="admin-input"
              value={maxEntries}
              onChange={(e) => maxEntriesChanged(parseFloat(e.target.value))}
              required={true}
              min={minMaxEntries}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="letter">Max Entries Per Person</label>
            <input
              type="number"
              className="admin-input"
              value={maxEntriesPerPerson}
              onChange={(e) => update("maxEntriesPerPerson", parseFloat(e.target.value))}
              required={true}
              min={1}
              max={maxEntries}
            />
          </div>
        </div>
      </div>

      {maxEntries > 1 && (
        <div className="row no-padding gutter no-wrap">
          <div className="col-4">
            <div className="form-row">
              <label htmlFor="letters">Use Letters</label>
              <input
                id="letters"
                type="checkbox"
                checked={ticketMatrix.useLetters}
                onChange={(e) =>
                  update("ticketMatrix", {
                    useLetters: !ticketMatrix.useLetters,
                    ticketsPerLetter: maxEntries,
                  })
                }
              />
            </div>
          </div>
          {ticketMatrix.useLetters && (
            <div className="col-4">
              <div className="form-row">
                <label htmlFor="letter">Letter Range</label>
                <select
                  id="letter"
                  className="admin-input-select"
                  value={ticketMatrix.toLetter || ""}
                  onChange={(e) => letterChange(e.target.value)}
                  required={true}
                >
                  <option value="">Select</option>
                  {letterOptions.map(({ toLetter }, i) => (
                    <option key={i} value={toLetter}>
                      A-{toLetter}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="col-4">
            <div className="form-row">
              <label htmlFor="letter">Tickets Per Letter</label>
              <input
                type="number"
                className="admin-input"
                value={ticketMatrix.ticketsPerLetter}
                disabled={true}
              />
            </div>
          </div>
        </div>
      )}

      <div className="row no-padding">
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="allowPromoBalance">Allow Promo Balance</label>
            <input
              id="allowPromoBalance"
              type="checkbox"
              checked={allowPromoBalance}
              onChange={(e) => update("allowPromoBalance", e.target.checked)}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="luckyDips">Only Allow Lucky Dips</label>
            <input
              id="luckyDips"
              type="checkbox"
              checked={luckyDipsOnly}
              onChange={(e) => update("luckyDipsOnly", e.target.checked)}
            />
          </div>
        </div>
      </div>

      <div className="row no-padding">
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="">Lucky Dip Default Amount</label>
            <input
              type="number"
              className="admin-input"
              value={luckyDipDefault || 0}
              onChange={(e) => update("luckyDipDefault", parseFloat(e.target.value))}
              required={true}
              min={1}
              max={maxEntries}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-row">
            <label htmlFor="winnerMessage">Show Guaranteed Winner Message</label>
            <input
              id="winnerMessage"
              type="checkbox"
              checked={guaranteedWinnerInfoBox}
              onChange={(e) => update("guaranteedWinnerInfoBox", e.target.checked)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyTicketGen;
