import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { ShoppingCart, Menu, User } from "react-feather";

import Dropdown from "./navigation/Dropdown";
import moment from "moment";
import useMediaQuery from "../../../utils/useMediaQuery";

const Navigation = () => {
  const isMobile = useMediaQuery("(max-width: 1110px)");

  const location = useLocation();

  const { user } = useContext(AppContext);

  const [showMenu, setShowMenu] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!user.state.cart) return;

    const updateCart = () => {
      setCartQuantity(user.state.cart.filter(({ exp }) => moment(exp).isAfter(moment())).length);
    };

    updateCart();
    const intervalID = setInterval(() => updateCart(), 1000);
    return () => clearInterval(intervalID);
  }, [user.state.cart, cartQuantity]);

  const links = [
    { title: "Home", url: "/" },
    { title: "Competitions", sub: [{ title: "Live Competitions", url: "/all" }, { title: "Completed Competitions", url: "/completed" }] },
    { title: "How it works", url: "/how-it-works" },
    { title: "FAQ", url: "/faq" },
    { title: "Winners", url: "/winners" },
  ];

  return (
    <nav className="main-navigation">
      <div className="row main-navigation-inner">
        <div className="hide-desktop">
          <button className="global-menu" onClick={() => setShowMenu(!showMenu)}>
            <Menu />
            {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
          </button>
          <Link to="/account" className="btn global-account-btn">
            <User />
          </Link>
        </div>

        <div className="col">
          <h1>
            <Link to="/" className="global-logo">
              Massive Competitions
            </Link>
          </h1>
        </div>
        <div className="col">
          <ul className="main-navigation-links" style={{ height: showMenu ? "200vh" : 0 }}>
            {links.map(({ title, sub, url }, i) => {
              if (isMobile)
                return (
                  <>
                    {sub ? (
                      sub.map(({ title, url }) => (
                        <li key={title}><Link to={url}>{title}</Link></li>
                      ))
                    ) : (
                      <li key={title}><Link to={url}>{title}</Link></li>
                    )}
                  </>
                );

              return (
                <li key={title}>
                  {sub ? (
                    <Dropdown sub={sub} title={title} />
                  ) : (
                    <Link to={url}>{title}</Link>
                  )}
                </li>
              );
            })}

            <li className="cta-btn">
              {user.state.isAuthenticated ? (
                <>
                  <Link to="/account" className="btn account-btn">
                    My Account
                  </Link>
                  <Link to="/cart" className="btn cart-btn">
                    <ShoppingCart />
                    {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                  </Link>
                </>
              ) : (
                <Link to="/auth/login" className="btn">
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
