import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../../context/AppContext";
import PromotedRaffles from "./PromotedRaffles";
import HowDoesItWork from "./HowDoesItWork";
import TopRaffles from "./TopRaffles";
import Testimonials from "./Testimonials";
import FeaturedWinner from "./FeaturedWinner";
import LoadingSpinner from "../../includes/LoadingSpinner";

const HomePage = () => {
  const { config, messages } = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [promotedRaffles, setPromotedRaffles] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [howItWorks, setHowItWorks] = useState({});
  const [testimonials, setTestimonials] = useState({});
  const [featuredWinner, setFeaturedWinner] = useState({});
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promotedRafflesConfigs = config.state.filter(({ type }) => type === "promoted_raffle");
  
        if (promotedRafflesConfigs) {
          const { data } = await Axios.get(`${process.env.REACT_APP_API}/api/config/promotedTickets`);
          
          if (data) {
            data.promotedTickets.forEach((promotedTicket) => {
              let promotedRaffleConfig = promotedRafflesConfigs.find(({ raffle }) => raffle._id === promotedTicket.raffle);
  
              if (promotedRaffleConfig)
                promotedRaffleConfig.ticketsPurchased = promotedTicket.ticketsPurchased;
            });
  
            setPromotedRaffles(promotedRafflesConfigs);
  
            setStatistics(config.state.find(({ type }) => type === "statistics") || {});
            setHowItWorks(config.state.find(({ type }) => type === "how_it_works") || {});
            setFeaturedWinner(config.state.find(({ type }) => type === "featured_winner") || {});
            setTestimonials(config.state.find(({ type }) => type === "testimonials") || {});
          } else {
            messages.dispatch({ type: "send", payload: { type: "error", msg: "An error occurred, please refresh!" } });
          }
        }
  
        const { data } = await Axios.get(`${process.env.REACT_APP_API}/api/winners/all`);
  
        if (data) setWinners(data.winners);

        setLoading(false);
      } catch (err) {
        messages.dispatch({ type: "send", payload: { type: "error", msg: "An error occurred, please refresh!" } });
      }
    };

    fetchData();
  }, [config.state]); // eslint-disable-line

  return (
    loading ? (
      <LoadingSpinner className="center" />
    ) : (
      <div>
        <PromotedRaffles promotedRaffles={promotedRaffles} statistics={statistics} winners={winners} />

        <HowDoesItWork howItWorks={howItWorks} />

        <TopRaffles />

        <FeaturedWinner featuredWinner={featuredWinner} />

        <Testimonials testimonials={testimonials} />
      </div>
    )
  );
};

export default HomePage;
