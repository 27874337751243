import React, { useEffect } from "react";
import FlashMessage from "./includes/FlashMessage";
import AdminHeader from "./includes/AdminHeader";
import AdminNavigation from "./includes/AdminNavigation";

const getFaviconEl = () => {
  return document.getElementById("favicon");
};

const AdminLayout = ({ children }) => {
  useEffect(() => {
    // Change favicon
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "/favicon-dark.ico";

    // Change title
    document.title = "Admin — Massive Competitions";

    // Change meta viewport
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=1400");
  }, []);

  return (
    <div className="admin">
      <AdminHeader />
      <FlashMessage />

      <div className="admin-content">
        <AdminNavigation />
        <div className="admin-content-main">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
