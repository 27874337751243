import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Search } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";
import Axios from "axios";
import { Link } from "react-router-dom";
import SingleOrder from "./SingleOrder";

const OrdersPage = () => {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const prev = () => setPage(page - 1);
  const next = () => setPage(page + 1);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/orders/adminBrowse`, {
      query,
      admin: false,
      skip: (page - 1) * limit,
      limit,
    }).then(({ data }) => {
      setOrders(data.orders);
      setTotalPages(Math.ceil(data.totalOrders / limit));
      setLoading(false);
    });
  }, [limit, page, query]);

  return (
    <div className="row">
      <div className="col">
        <div className="all-raffles-header">
          <div className="row no-padding">
            <div className="col">
              <h1 className="admin-title">Orders</h1>
            </div>
            <div className="col right">
              <Link to="/admin/orders/export" className="btn btn-admin">
                Export to CSV
              </Link>
              <div className="search-bar">
                <input
                  type="search"
                  value={query}
                  placeholder="Search"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button>
                  <Search />
                </button>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <table className="raffles-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Payment Ref</th>
                <th>User</th>
                <th>Tickets</th>
                <th>Paid</th>
                <th>Status</th>
                <th>Created</th>
                <th style={{ width: 120 }}></th>
              </tr>
            </thead>
            <tbody>
              {!orders.length ? (
                <tr>
                  <td colSpan="7" className="no-orders">
                    No orders to show
                  </td>
                </tr>
              ) : (
                orders.map((order) => (
                  <SingleOrder order={order} orders={orders} setOrders={setOrders} />
                ))
              )}
            </tbody>
          </table>
        )}

        <div className="raffles-paginate">
          <button onClick={prev} disabled={page === 1} className="btn btn-admin btn-paginate">
            <ChevronLeft />
          </button>
          <button
            onClick={next}
            disabled={page === totalPages}
            className="btn btn-admin btn-paginate"
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
