import React from "react";

const PageNotFound = () => {
  return (
    <div className="row">
      <div className="col">
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    </div>
  );
};

export default PageNotFound;
