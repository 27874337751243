import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const PromotedRaffleCard = ({ large, slug, name, endDate, onSale, maxEntries, originalPrice, ticketsPurchased, price, images = [] }) => {
  const raffleImage = images.find(({ keyImage }) => keyImage)
    ? `url(${images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  const [currentTime, setCurrentTime] = useState(moment());
  const [timeRemaining, setTimeRemaining] = useState(moment());

  useEffect(() => {
    setTimeRemaining(moment.duration((moment(endDate).unix() - moment(currentTime).unix()) * 1000, "milliseconds")._milliseconds);
  }, [currentTime, endDate]);

  useEffect(() => {
    const intervalID = setInterval(() => setCurrentTime(moment()), 1000);
    return () => clearInterval(intervalID);
  }, [slug]);

  return (
    <Link
      to={`/c/${slug}`}
      className={`promoted-raffle-card ${large ? "card-main" : ""}`}
    >
      <div className="raffle-card-bg-shade"></div>
      <div className="raffle-card-image" style={{ backgroundImage: raffleImage }}></div>
      <div className="raffle-card-contents">
        <div className="tickets-bar">
          <div className="tickets-remaining">
            <div className="tickets-bar-container">
              <div className="bar">
                <div
                  className="bar-fill"
                  style={{'--width': ((maxEntries - ticketsPurchased.length) / maxEntries) * 100 + "%" }}
                ></div>
              </div>
              <div className="bar-indices">
              <div className="left">{maxEntries - ticketsPurchased.length} left</div>
                <div className="right">{maxEntries} total</div>
              </div>
            </div>
          </div>
        </div>

        <div className="raffle-name">{name}</div>

        <div className="raffle-price">
          {onSale && (
            <small>
              <s>{originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</s>
            </small>
          )}{" "}
          {price === 0 ? "FREE" : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
        </div>

        <div className="row raffle-timeleft">
          <div className="col-3">
            <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0}</p> days</div>
          </div>
          <div className="col-3">
            <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0}</p> hours</div>
          </div>
          <div className="col-3">
            <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0}</p> minutes</div>
          </div>
          <div className="col-3">
            <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0}</p> seconds</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PromotedRaffleCard;
