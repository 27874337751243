import React from "react";
import { Trash2 } from "react-feather";

const RaffleImageUploadList = ({ images, deleteImage, update }) => {
  const setKeyImage = (index) => {
    // Set all false except for the index selected
    const newImages = images.map((img, i) => ({ ...img, keyImage: index === i ? true : false }));
    update("images", newImages);
  };

  return (
    <div>
      <table className="raffles-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Key Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!images.length ? (
            <tr>
              <td colSpan="3" className="no-raffles">
                No images
              </td>
            </tr>
          ) : (
            images.map(({ path, keyImage }, i) => (
              <tr key={i}>
                <td>
                  <img src={`${path}`} alt="Raffle" className="raffle-table-image" />
                </td>
                <td>
                  <input type="checkbox" checked={keyImage} onChange={() => setKeyImage(i)} />
                </td>
                <td>
                  <button className="delete-btn" onClick={() => deleteImage(i)}>
                    <Trash2 />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RaffleImageUploadList;
