import React, { useState, useContext } from "react";
import { DollarSign, Trash2, Info } from "react-feather";
import Axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import OrderTicketsModal from "./includes/OrderTicketsModal";
import { AppContext } from "../../../context/AppContext";
import OrderRefundModal from "./includes/OrderRefundModal";
import OrderInfoModal from "./includes/OrderInfoModal";

const SingleOrder = ({ order, orders, setOrders }) => {
  const { messages } = useContext(AppContext);
  const [showOrderInfoModal, setShowOrderInfoModal] = useState(false);
  const [showOrderTicketsModal, setShowOrderTicketsModal] = useState(false);
  const [showOrderRefundModal, setShowOrderRefundModal] = useState(false);

  const onDelete = async (id) => {
    if (
      window.confirm(
        "Are you sure you would like to permanently delete this order? It will first remove any associated tickets making them available again for purchase, but will not process a refund."
      )
    ) {
      try {
        await Axios.delete(`${process.env.REACT_APP_API}/api/orders/${id}`);
        setOrders(orders.filter((order) => order._id !== id));
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully deleted order" },
        });
      } catch (e) {
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem deleting this order",
          },
        });
      }
    }
  };

  return (
    <>
      {showOrderInfoModal && (
        <OrderInfoModal
          show={showOrderInfoModal}
          orderId={order.orderId}
          closeModal={() => setShowOrderInfoModal(false)}
        />
      )}

      {showOrderTicketsModal && (
        <OrderTicketsModal
          show={showOrderTicketsModal}
          tickets={order.ticketsPurchased}
          closeModal={() => setShowOrderTicketsModal(false)}
        />
      )}

      {showOrderRefundModal && (
        <OrderRefundModal
          show={showOrderRefundModal}
          orderData={order}
          orders={orders}
          setOrders={setOrders}
          closeModal={() => setShowOrderRefundModal(false)}
        />
      )}

      <tr key={order._id}>
        <td>
          {order.orderId || <em>[Manual Entry]</em>}
          {order.orderId && (
            <button
              className="delete-btn"
              onClick={() => setShowOrderInfoModal(true)}
              data-tip="Order Info"
            >
              <Info />
            </button>
          )}
        </td>
        <td>{order.trustTransactionRef || order.paragonPaymentDetails.orderId}</td>
        <td>{order.user ? `${order.user.firstName} ${order.user.lastName}` : "[User Deleted]"}</td>
        <td>
          {order.ticketsPurchased.length && (
            <>
              {order.ticketsPurchased.length}
              <button
                className="delete-btn"
                onClick={() => setShowOrderTicketsModal(true)}
                data-tip="View Tickets"
              >
                <Info />
              </button>
            </>
          )}
        </td>
        <td>
          {order.totalPricePaid.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          })}
        </td>
        <td>{order.status}</td>
        <td>{moment(order.createdAt).format("DD/MM/YYYY (HH:mm)")}</td>
        <td className="right">
          {!!(
            order.ticketsPurchased.length &&
            order.totalPricePaid &&
            order.trustTransactionRef
          ) && (
            <button
              className="delete-btn"
              onClick={() => setShowOrderRefundModal(true)}
              data-tip="Refund Order"
            >
              <DollarSign />
            </button>
          )}
          <button
            className="delete-btn"
            onClick={() => onDelete(order._id)}
            data-tip="Delete Order"
          >
            <Trash2 />
          </button>

          <ReactTooltip className="tooltip" />
        </td>
      </tr>
    </>
  );
};

export default SingleOrder;
