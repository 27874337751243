import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { HelpCircle, Star } from "react-feather";

import moment from "moment";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import RaffleImageCarousel from "./RaffleImageCarousel";
import ReactMarkdown from "react-markdown";
import ReactTooltip from "react-tooltip";

const RaffleInfo = ({ timeRemaining, raffle }) => {
  const {
    name,
    originalPrice,
    price,
    onSale,
    images,
    description,
    maxEntries,
    ticketsPurchased,
    guaranteedWinnerInfoBox,
    instantWinners,
    instantWins,
    instantWinsDescription
  } = raffle;

  const hasInstantWins = instantWins && instantWins.length > 0;

  const [winnersMap, setWinnersMap] = useState(new Map());

  const [instantShowingMore, setInstantShowingMore] = useState(false);
  const [useInstantShowMore, setUseInstantShowMore] = useState(false);

  const [showingMore, setShowingMore] = useState(false);
  const [useShowMore, setUseShowMore] = useState(false);

  useEffect(() => {
    if (!hasInstantWins)
      return;

    var description = document.getElementById("instant-win-prizes");

    if (description) {
      setUseInstantShowMore(description.clientHeight > 150);
    }
  }, [hasInstantWins]);

  useEffect(() => {
    var description = document.getElementById("description");

    if (description) {
      setUseShowMore(description.clientHeight > 75);
    }
  }, [description]);

  useEffect(() => {
    const winnersMap = new Map();

    instantWinners.forEach((instantWinner) => {
      winnersMap.set(instantWinner.ticketNum, instantWinner.user);
    });

    setWinnersMap(winnersMap);
  }, [instantWinners]);

  return (
    <div className="raffle-info">
      <div className="row gutter">
        <div className="col-6">
          <div className={`raffle-image ${guaranteedWinnerInfoBox ? "has-info-box" : ""}`}>
            {timeRemaining < 0 ? (
              <iframe
                title="livestream"
                className="image-livestream"
                src={raffle.videoUrl}
                frameBorder="0"
                allowFullScreen="true"
                scrolling="no"
              />
            ) : (
              <RaffleImageCarousel onSale={onSale} images={images} />
            )}
          </div>
        </div>
        <div className="col-6 raffle-specifics">
          <h1>{name}</h1>

          <a href="#ticket-selector" className="btn price-btn">
            {onSale && (
              <small>
                <s>
                  {originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                </s>
              </small>
            )}{" "}
            {price === 0
              ? "FREE"
              : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
            <span className="quantity">1x ticket</span>
          </a>

          <div className="live-draw-countdown">
            <h3>Live Draw In</h3>
            {!timeRemaining ? (
              <LoadingSpinner style={{ margin: "20px 0" }} />
            ) : (
              <div className="countdown-numbers">
                <div className="countdown-number">
                  {timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0}
                  <span>Days</span>
                </div>
                <div className="countdown-number">
                  {timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0}
                  <span>Hours</span>
                </div>
                <div className="countdown-number">
                  {timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0}
                  <span>Mins</span>
                </div>
                <div className="countdown-number">
                  {timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0}
                  <span>Secs</span>
                </div>
              </div>
            )}
          </div>

          <div className="live-draw-countdown">
            <a target="_blank" href="/p/terms-and-conditions#free-entry" className="lucky-dip">Click here for our free entry route</a>
          </div>

          <div className="tickets-remaining">
            <h3>Tickets Left</h3>
            <div className="tickets-bar-container">
              <div className="bar">
                <div
                  className="bar-fill"
                  style={{'--width': ((maxEntries - ticketsPurchased) / maxEntries) * 100 + "%" }}
                ></div>
              </div>
              <div className="bar-indices">
                <div className="left">0</div>
                <div className="center tremaining">{maxEntries - ticketsPurchased} remaining</div>
                <div className="right">{maxEntries}</div>
              </div>
            </div>
          </div>

          <div className="row gutter no-padding">
            {hasInstantWins && (
              <div className="guaranteed-winner-info-box star">
                <Star />
                <span>Instant win prizes available!</span>
              </div>
            )}

            {guaranteedWinnerInfoBox && (
              <div className="guaranteed-winner-info-box">
                <span>Guaranteed winner regardless of ticket sales!</span>
                <HelpCircle data-tip="Winner will be selected on a live draw at random regardless of ticket sales at the end of the countdown." />
                <ReactTooltip className="tooltip" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="raffle-description" className="row raffle-description">
        <h1 className="title">Description</h1>

        <div className={"col-12 md-text" + (useShowMore && !showingMore ? " small" : "")} id="description">
          <ReactMarkdown linkTarget="_blank" children={description} />
        </div>

        {useShowMore && (
          <button className="btn" onClick={() => setShowingMore(!showingMore)}>
            {showingMore ? (
              <span>Show less <FaAngleUp /></span>
            ) : (
              <span>Show more <FaAngleDown /></span>
            )}
          </button>
        )}
      </div>

      {hasInstantWins && (
        <div id="instant-wins" className="row no-gutter raffle-description">
          <h1 className="title">Instant Wins</h1>

          <div className="col-12 md-text">
            <ReactMarkdown linkTarget="_blank" children={instantWinsDescription} />
          </div>

          <div className={`col-12 md-text${useInstantShowMore && !instantShowingMore ? " small" : ""}`} style={{ marginTop: "20px" }} id="instant-win-prizes">
            {instantWins && instantWins.length > 0 && (
              <div className="row no-padding gutter">
                {instantWins.map(({ prize, ticketNum }, i) => {
                  let user = winnersMap.get(ticketNum);

                  if (user) {
                    return (
                      <div className="col-2" key={i}>
                        <div className="instant-win-ticket">
                          <span className="ticket-number">
                            <strong>Ticket #{ticketNum}:</strong>
                          </span>

                          <span className="ticket-number">
                            {prize}
                          </span>
                        </div>
                        <p className="instant-win-winner">🎉 Won by: {user.firstName} {user.lastName}</p>
                      </div>
                    );
                  }
                  
                  return (
                    <div className="col-2" key={i}>
                      <div className="instant-win-ticket">
                        <span className="ticket-number">
                          <strong>Ticket #{ticketNum}:</strong>
                        </span>

                        <span className="ticket-number">
                          {prize}
                        </span>
                      </div>
                      <p className="instant-win-winner">❌ No winner yet!</p>
                    </div>
                  );
                })}

                {Array((Math.ceil(instantWins.length / 5) + 1) % 5).fill().map((_, i) => (
                  <div className="col-2" key={i}>
                  </div>
                ))}
              </div>
            )}
          </div>

          {useInstantShowMore && (
            <button className="btn" onClick={() => setInstantShowingMore(!instantShowingMore)}>
              {instantShowingMore ? (
                <span>Show less <FaAngleUp /></span>
              ) : (
                <span>Show more <FaAngleDown /></span>
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default RaffleInfo;
