import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const AdminRoute = ({ path, children }) => {
  const { user } = useContext(AppContext);

  return user.state.isAuthenticated ? (
    <Route path={path}>{children}</Route>
  ) : (
    <Redirect to="/auth/login" />
  );
};

export default AdminRoute;
