import React from "react";
import { Image } from "react-feather";

const RaffleModifyInfo = ({
  image,
  price,
  originalPrice,
  startDate,
  endDate,
  onSale,
  update,
  openModal,
}) => {
  const backgroundImage = image ? `url(${image.path})` : "none";

  return (
    <div className="admin-slate">
      <div className="row no-padding">
        <div className="col">
          <div onClick={openModal} className="raffle-img" style={{ backgroundImage }}>
            <Image />
          </div>
        </div>
        <div className="col raffle-info-item">
          <h3 className="pricing">
            <span>{onSale && "Sale"} Price</span>
            <span>
              <input
                type="checkbox"
                id="on-sale"
                checked={onSale}
                onChange={(e) => update("onSale", e.target.checked)}
              />
              <label htmlFor="on-sale"> On sale?</label>
            </span>
          </h3>
          <span className="input-currency">£</span>
          <input
            type="number"
            className="admin-input currency"
            value={price}
            onChange={(e) => update("price", e.target.value)}
            required={true}
            min={0.0}
            step={0.01}
          />
        </div>
        {onSale && (
          <div className="col raffle-info-item">
            <h3>Original price</h3>
            <span className="input-currency">£</span>
            <input
              type="number"
              className="admin-input currency"
              value={originalPrice}
              onChange={(e) => update("originalPrice", e.target.value)}
              required={true}
              min={0.0}
              step={0.01}
            />
          </div>
        )}
        <div className="col raffle-info-item">
          <h3>Start Date</h3>
          <input
            type="datetime-local"
            className="admin-input"
            value={startDate}
            onChange={(e) => update("startDate", e.target.value)}
            required={true}
          />
        </div>
        <div className="col raffle-info-item">
          <h3>End Date</h3>
          <input
            type="datetime-local"
            className="admin-input"
            value={endDate}
            onChange={(e) => update("endDate", e.target.value)}
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyInfo;
