import React from "react";
import { CheckCircle, AlertCircle } from "react-feather";
import { Link } from "react-router-dom";

const VerificationResult = ({ success }) => {
  return (
    <div className="row center-cols">
      <div className="col-6">
        <div className="slate">
          <div className="center">
            {success ? (
              <CheckCircle size={40} color="#2fde80" />
            ) : (
              <AlertCircle size={40} color="#ff313d" />
            )}
            <h1>
              {success
                ? "Successfully verified account"
                : "There was a problem verifying your account. Your token may have expired (it is only valid for 30 minutes)"}
            </h1>
            <Link to="/all" className="btn">
              Browse Competitions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationResult;
