import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import RaffleSalesHeader from "./includes/RafflesSalesHeader";
import RaffleSalesTable from "./includes/RaffleSalesTable";
import CreateRaffleSale from "./includes/CreateRaffleSale";

const RaffleSalesPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [lastTicketDeleted, setLastTicketDeleted] = useState(false);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${id}/purchased`, {
      query,
      skip: (page - 1) * limit,
      limit,
    }).then(({ data }) => {
      setTickets(data.tickets);
      setTotalPages(Math.ceil(data.totalTickets / limit));
      setLoading(false);
    });
  }, [id, query, page, limit, showCreateModal, lastTicketDeleted]);

  return (
    <div className="row admin-raffle-page">
      <div className="col">
        {showCreateModal && <CreateRaffleSale closeModal={() => setShowCreateModal(false)} />}

        <RaffleSalesHeader
          id={id}
          query={query}
          setQuery={(q) => {
            setQuery(q);
            setPage(1);
          }}
          openModal={() => setShowCreateModal(true)}
        />

        <RaffleSalesTable
          loading={loading}
          tickets={tickets}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          ticketDeleted={setLastTicketDeleted}
        />
      </div>
    </div>
  );
};

export default RaffleSalesPage;
