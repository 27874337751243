import React from "react";

const OrderTicketsModal = ({ tickets = [], closeModal }) => {
  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Tickets</h2>

        {tickets.map(
          (ticket) =>
            ticket && (
              <p style={{ marginTop: 15 }}>
                <a
                  href={`/admin/raffles/${ticket.raffle._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {ticket.raffle.name}
                </a>{" "}
                (#
                {ticket.ticketNum})
              </p>
            )
        )}
      </div>
    </div>
  );
};

export default OrderTicketsModal;
