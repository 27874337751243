import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import { ExternalLink } from "react-feather";

const RaffleHeader = ({ id, name, slug, live, endDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    setTimeRemaining(
      moment.duration((moment(endDate).unix() - moment().unix()) * 1000, "milliseconds")
        ._milliseconds
    );

    const intervalID = setInterval(() => {
      setTimeRemaining(
        moment.duration((moment(endDate).unix() - moment().unix()) * 1000, "milliseconds")
          ._milliseconds
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [endDate]);

  return (
    <div className="row no-padding">
      <div className="col">
        <h1 className="admin-title">{name}</h1>
        <div className={`live-tag ${live ? "live" : "not-live"}`}>{live ? "Live" : "Not Live"}</div>
        <a
          href={`/c/${slug}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: 25 }}
        >
          <ExternalLink />
        </a>
      </div>
      <div className="col right">
        <div className="countdown-numbers">
          {!timeRemaining ? (
            <LoadingSpinner dark={true} style={{ marginTop: 15 }} />
          ) : (
            <>
              <div className="countdown-number">
                {timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0}
                <span>Days</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0}
                <span>Hours</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0}
                <span>Mins</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0}
                <span>Secs</span>
              </div>
            </>
          )}
        </div>
        <Link
          to={`/admin/raffles/${id}/edit`}
          className="btn btn-admin"
          style={{ marginRight: 20 }}
        >
          Edit
        </Link>
        <Link to="/admin/raffles" className="btn btn-admin">
          Back
        </Link>
      </div>
    </div>
  );
};

export default RaffleHeader;
