import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../includes/LoadingSpinner";
import SetAddresses from "./steps/SetAddresses";
import SetCard from "./steps/SetCard";
import SetPaymentDetails from "./steps/SetPaymentDetails";

const OrderPayment = () => {
  const location = useLocation();

  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(-1);
  const [showCards, setShowCards] = useState(false);
  const [step, setStep] = useState(false);
  const [updating, setUpdating] = useState(false);

  const usePromotionalBalance = localStorage.getItem("usePromoBal") === "true";
  const couponCode = localStorage.getItem("appliedCouponCode");
  const newsletter = localStorage.getItem("cartSignupNewsletter") === "true";

  const selectCard = (id) => {
    for (let i = 0; i< cards.length; i++) {
      var card = cards[i];

      if (card._id === id) {
        setSelectedCard(i);
        return;
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("orderId");

    if (orderId) {
      setUpdating(true);

      Axios.post(`${process.env.REACT_APP_API}/api/orders/updatePending/${orderId}`)
        .finally(() => window.close());
    }
  }, [location]);

  useEffect(() => {
    Promise.all([
      Axios.get(`${process.env.REACT_APP_API}/api/card/all`).then(({ data }) => {
        if (data.cards && data.cards.length > 0) {
          setCards(data.cards.map(({ _id, lastFour, type }) => (
            { _id, lastFour, type }
          )));
        }
      }),
      Axios.post(`${process.env.REACT_APP_API}/api/payments/setup`, {
        usePromotionalBalance,
        couponCode,
        newsletter,
      }).then(({ data }) => {
        if (data.totalPrice > 0)
          setShowCards(true);
      })
    ]).then(() => setStep("address"));
  }, [usePromotionalBalance, couponCode, newsletter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return step ? (
    <div className="row">
      <div className="col cart">
        <h1 className="winners-title">Payment</h1>

        <div>
          {updating ? (
            <LoadingSpinner className="center" />
          ) : (
          <>
            {step === "address" && <SetAddresses cards={showCards && cards.length > 0} setStep={setStep} />}
            {step === "card" && <SetCard cards={cards} setCard={selectCard} setStep={setStep} />}
            {step === "payment" && <SetPaymentDetails card={selectedCard > -1 ? cards[selectedCard] : undefined} />}
          </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner className="center" />
  );
};

export default OrderPayment;
