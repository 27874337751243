import React from "react";

const RaffleModifyDesc = ({ description, update }) => {
  return (
    <div className="admin-slate">
      <h2>Description</h2>
      <textarea
        cols="30"
        rows="10"
        className="admin-input"
        value={description}
        onChange={e => update("description", e.target.value)}
      />
    </div>
  );
};

export default RaffleModifyDesc;
