import React, { useState } from "react";
import { Check, Edit2, Trash2 } from "react-feather";
import AddEditAnswerModal from "./add-question/AddEditAnswerModal";

const RaffleModifyQuestions = ({ question = "", answers = [], update }) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const afterSubmit = (answer) => {
    if (typeof showAddModal === "object") {
      return update("question", {
        question,
        answers: answers.map((a, i) => (i === showAddModal.i ? answer : a)),
      });
    }

    return update("question", { question, answers: [...answers, answer] });
  };

  const deleteAnswer = (indexToDelete) => {
    update("question", { question, answers: answers.filter((_, i) => i !== indexToDelete) });
  };

  return (
    <div className="admin-slate">
      <h2>Security Question</h2>

      {showAddModal && (
        <AddEditAnswerModal
          closeModal={() => setShowAddModal(false)}
          answerData={showAddModal}
          afterSubmit={afterSubmit}
        />
      )}

      <div className="form-row">
        <label htmlFor="question">Question</label>
        <input
          type="text"
          value={question}
          onChange={(e) => update("question", { question: e.target.value, answers })}
          className="admin-input"
        />
      </div>

      {/* Table */}
      <div className="form-row">
        <div className="row no-padding no-gutter">
          <div className="col-6">
            <label htmlFor="">Answers</label>
          </div>
          <div className="col-6 right">
            <button
              type="button"
              className="btn btn-admin btn-tiny"
              onClick={() => setShowAddModal(true)}
            >
              Add Answer
            </button>
          </div>
        </div>

        <table className="raffles-table no-side-padding">
          <tbody>
            {!answers.length ? (
              <tr>
                <td colSpan="3" className="no-raffles">
                  No answers to show
                </td>
              </tr>
            ) : (
              answers.map(({ answer, correct }, i) => (
                <tr key={i}>
                  <td className="left">{answer}</td>
                  <td className="text-green">{correct && <Check />}</td>
                  <td className="right">
                    <button
                      type="button"
                      className="delete-btn"
                      onClick={() => setShowAddModal({ i, answer, correct })}
                    >
                      <Edit2 />
                    </button>
                    <button type="button" className="delete-btn" onClick={() => deleteAnswer(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RaffleModifyQuestions;
