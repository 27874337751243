import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

const HowDoesItWork = ({ howItWorks }) => {
  return (
    <div className="cta-slab">
      <div className="row gutter">
        <div className="col-6">
          {/* <div
            className="cta-image hide-mobile"
            style={{
              backgroundImage: `url(${howItWorks.image})`,
            }}
          ></div> */}
          <div className="how-it-works-video">
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube-nocookie.com/embed/7M4wQRzPmJk?modestbranding=1&autohide=1&showinfo=0&controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="col-6">
          <div className="title">How Does It Work</div>
          <ReactMarkdown className="md-text" linkTarget="_blank" children={howItWorks.text} />
          <Link to="/all" className="btn">
            Enter Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowDoesItWork;
