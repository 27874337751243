import React, { useState } from "react";
import { X } from "react-feather";

const RaffleSelectAnswer = ({ question, answers = [], onClose, afterAnswered }) => {
  const [answer, setAnswer] = useState();

  const onSubmit = () => {
    afterAnswered(answer);
  };

  return (
    <div className="raffle-answer-question">
      <button className="close-btn" onClick={onClose}>
        <X />
      </button>
      <h2>{question}</h2>

      <div className="form-row">
        <label className="raffle-selectans">Select Answer</label>

        <div>
          {answers.map(({ answer }, i) => (
            <div className="raffle-answer" key={i}>
              <input
                type="radio"
                name="answer"
                id={answer}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <label htmlFor={answer}>{answer}</label>
            </div>
          ))}
        </div>

        <button type="button" onClick={onSubmit} className="btn" disabled={!answer}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default RaffleSelectAnswer;
