import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import OrderCartSummary from "../cart-summary/OrderCartSummary";

const applePayPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "iPhone", "iPad", "iPod"];

const SetPaymentDetails = ({ card }) => {
  const { messages } = useContext(AppContext);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [couponDiscount, setCouponDiscount] = useState(false);
  const [promoBalDiscount, setPromoBalDiscount] = useState(false);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const [cof, setCof] = useState(false);
  const [cofHtml, setCofHtml] = useState(false);
  const [generatingUrl, setGeneratingUrl] = useState(false);
  const [paymentData, setPaymentData] = useState(false);
  const [pending, setPending] = useState(false);
  const [pendingCof, setPendingCof] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [useFrame, setUseFrame] = useState(false);

  const usePromotionalBalance = localStorage.getItem("usePromoBal") === "true";
  const couponCode = localStorage.getItem("appliedCouponCode");
  const newsletter = localStorage.getItem("cartSignupNewsletter") === "true";

  useEffect(() => {
    const err = async () => {
      setLoading(false);
      history.push("cart");
  
      messages.dispatch({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem retrieving your cart. Please try again later.",
        },
      });
    };
    
    Axios.post(`${process.env.REACT_APP_API}/api/payments/setup`, {
      usePromotionalBalance,
      couponCode,
      newsletter,
    }).then(({ data }) => {
      if (!data.tickets.length) {
        return history.push("cart");
      }

      setTickets(data.tickets);
      setOrderSubtotal(data.subtotal);
      setCouponDiscount(data.couponDiscount);
      setPromoBalDiscount(data.promoBalDiscount);
      setOrderTotal(data.totalPrice);

      setUseFrame(applePayPlatforms.indexOf(window.navigator.platform) === -1);
      setLoading(false);
    }).catch(() => err());
  }, [history, messages.dispatch, usePromotionalBalance, couponCode, newsletter]); // eslint-disable-line

  useEffect(() => {
    const checkExists = (orderId) => {
      Axios.get(`${process.env.REACT_APP_API}/api/orders/exists/${orderId}`).then(({ data }) => {
        if (data.exists) window.location.replace(`account/tickets?ref=orderComplete&orderId=${orderId}`);
      });
    };

    const checkPending = (orderId) => {
      Axios.get(`${process.env.REACT_APP_API}/api/orders/pending/${orderId}`).then(({ data }) => {
        if (data.pending) setPending(true);
      });
    };

    if (paymentData && paymentData["redirect-url"]) {
      let intervalID;

      if (pending) {
        intervalID = setInterval(() => checkExists(paymentData["merchant-order-id"]), 2500);
        checkExists(paymentData["merchant-order-id"]);
      } else {
        intervalID = setInterval(() => checkPending(paymentData["merchant-order-id"]), 2500);
        if (!useFrame) window.open(paymentData["redirect-url"], "_blank");
      }

      return () => clearInterval(intervalID);
    }
  }, [paymentData, pending, useFrame]);

  useEffect(() => {
    const checkExists = (orderId) => {
      Axios.get(`${process.env.REACT_APP_API}/api/orders/exists/${orderId}`).then(({ data }) => {
        if (data.exists) window.location.replace(`account/tickets?ref=orderComplete&orderId=${orderId}`);
      });
    };

    const checkStatus = (orderId) => {
      Axios.post(`${process.env.REACT_APP_API}/api/payments/cofStatus?orderId=${orderId}`).then(({ data }) => {
        if (data.response && data.response.status_code === "30003") setCofHtml(data.response.html);
      });
    }

    if (paymentData && pendingCof) {
      let intervalID;
      
      if (cofHtml)
        intervalID = setInterval(() => checkExists(paymentData["merchant-order-id"]), 2500);
      else
        intervalID = setInterval(() => checkStatus(paymentData["merchant-order-id"]), 5000);

      return () => clearInterval(intervalID);
    }
  }, [cofHtml, paymentData, pendingCof]);

  const getPaymentUrl = async () => {
    setGeneratingUrl(true);

    Axios.get(`${process.env.REACT_APP_API}/api/payments/getPaymentUrl?couponCode=${localStorage.appliedCouponCode}&createToken=${cof}&useFrame=${useFrame}&usePromoBal=${localStorage.usePromoBal}`)
      .then(({ data }) => setPaymentData(data.response))
      .catch((err) => {
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: err.response?.data?.msg || "There was a problem creating your payment!"
          }
        });
      })
      .finally(() => setGeneratingUrl(false));
  };

  // If paying with card on file
  const processCofPayment = async () => {
    setProcessing(true);

    Axios.post(`${process.env.REACT_APP_API}/api/payments/cofPayment?cardId=${card._id}&couponCode=${localStorage.appliedCouponCode}&usePromoBal=${localStorage.usePromoBal}`)
      .then(({ data }) => {
        setPaymentData(data.response);
        setPendingCof(true);
      })
      .catch((err) => {
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: err.response?.data?.msg || "There was a problem processing your order."
          }
        });
        
        setProcessing(false);
      });
  };

  // If payable balance is 0
  const processNoPayment = async () => {
    setProcessing(true);

    Axios.post(`${process.env.REACT_APP_API}/api/payments/noPayment`, {
      usePromotionalBalance,
      couponCode,
      newsletter,
    }).then(({ data }) => {
      window.location.replace(`/account/tickets?ref=orderComplete&orderId=${data.orderId}`);
      setProcessing(false);
    }).catch(() => {
      messages.dispatch({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem processing your order."
        }
      });

      setProcessing(false);
    });
  };

  return (
    <div>
      {loading ? (
        <div className="center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="row gutter no-padding center-cols">
          {!orderTotal !== 0 && (!card || processing) && (
            <div className="col-8">
              <div className="slate cart-items">
                <div className="row">
                  <div className="col-12">
                    <h3 className="cart-items-address-title">Complete Payment</h3>

                    <div id="payment-form" className="payment-form">
                      {paymentData && !pendingCof ? (
                        <>
                          {pending ? (
                            <div className="row no-padding">
                              <div className="col">
                                <p className="center" style={{ marginBottom: "25px" }}>Thank you for your payment, please wait a moment!</p>
                                <LoadingSpinner className="center" />
                              </div>
                            </div>
                          ) : (
                            useFrame ? (
                              <iframe
                                id="payment-window"
                                name="payment-window"
                                src={paymentData["redirect-url"]}
                                allow="payment"
                                title="Payment Window"
                                style={{ minHeight: "850px" }}
                              ></iframe>
                            ) : (
                              <div className="row no-padding no-margin">
                                <div className="col-12">
                                  <p className="center">We are redirecting you to complete your payment...</p>
                                </div>

                                <div className="col-12">
                                  <LoadingSpinner className="center" />
                                </div>

                                <div className="col-12 no-margin">
                                  <div className="redirect-link" onClick={() => window.open(paymentData["redirect-url"], "_blank")}>Page not loaded? Click here to continue</div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) :
                        card ? processing && (
                          cofHtml ? (
                            <iframe
                              title="cof-payment-window"
                              srcDoc={cofHtml.replace("\n", "").replace("<body>", "<body style='color: white'>")}
                              style={{ minHeight: "600px" }}
                            ></iframe>
                          ) : (
                            <div className="row no-padding no-margin">
                              <div className="col-12">
                                <p className="center">We are processing your payment, please wait...</p>
                              </div>

                              <div className="col-12 no-margin">
                                <LoadingSpinner className="center" />
                              </div>
                            </div>
                          )
                        ) : (
                          <>
                            <div className="form-row">
                              <div className="checkbox">
                                <input
                                  id="save-details"
                                  type="checkbox"
                                  checked={cof}
                                  onChange={(e) => setCof(e.target.checked)}
                                />
                                <label htmlFor="save-details">Save card details for later</label>
                              </div>
                            </div>

                            <div className="form-row">
                              <button className="btn" onClick={getPaymentUrl} disabled={generatingUrl}>Continue</button>
                            </div>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-4">
            <OrderCartSummary
              tickets={tickets}
              subtotal={orderSubtotal}
              couponDiscount={couponDiscount}
              promoBalDiscount={promoBalDiscount}
              orderTotal={orderTotal}
            />

            {/* If order doesn't require payment */}
            {orderTotal === 0 ? (
              <button
                className="btn form-btn cart-checkout-btn"
                disabled={processing}
                onClick={processNoPayment}
              >
                {processing ? "Processing..." : "Complete Order"}
              </button>
            ) : (
              card && !processing && ( /* If order is being paid with card on file */
                <button
                  className="btn form-btn cart-checkout-btn"
                  disabled={processing}
                  onClick={processCofPayment}
                >
                  {`Pay with ${card.type} (x-${card.lastFour})`}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SetPaymentDetails;
