import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const PromotedRaffleCard = ({ large, slug, name, images = [], createdAt }) => {
  const raffleImage = images.find(({ keyImage }) => keyImage)
    ? `url(${images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  return (
    <Link
      to={`/c/${slug}`}
      className={`promoted-raffle-card-main ${large ? "card-main" : ""}`}
      style={{ backgroundImage: raffleImage }}
    >
      <div className="raffle-card-bg-shade"></div>
      <div className="raffle-card-contents">
        <h2>{name}</h2>
        <p>{moment(createdAt).format("LLLL")}</p>
        <button className={`btn ${large ? "btn-large" : "btn-small btn-black"}`}>Enter Now</button>
      </div>
    </Link>
  );
};

export default PromotedRaffleCard;
