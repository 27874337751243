import React from "react";
import { useState } from "react";

const AddEditAnswerModal = ({ closeModal, answerData = {}, afterSubmit }) => {
  const [answer, setAnswer] = useState(answerData.answer || "");
  const [correct, setCorrect] = useState(answerData.correct || false);

  const onSubmit = () => {
    afterSubmit({ answer, correct });
    closeModal();
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>{answerData.answer ? "Edit" : "Add"} Answer</h2>

        <div className="form-row">
          <label htmlFor="answer">Answer</label>
          <input
            type="text"
            id="answer"
            className="admin-input"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>

        <div className="form-row">
          <input
            type="checkbox"
            id="correct"
            checked={correct}
            onChange={(e) => setCorrect(e.target.checked)}
          />{" "}
          <label htmlFor="correct" style={{ display: "inline-block" }}>
            Correct answer
          </label>
        </div>

        <button onClick={onSubmit} type="button" className="btn btn-admin">
          {answerData.answer ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddEditAnswerModal;
