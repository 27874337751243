import React, { useState, useContext } from "react";
import { Trash2 } from "react-feather";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import { useEffect } from "react";

const ConfigTestimonials = ({ testimonials: testimonialData = [] }) => {
  const { messages } = useContext(AppContext);
  const [testimonials, setTestimonials] = useState(testimonialData.testimonials || []);
  const [showAddModal, setShowAddModal] = useState(false);

  const [author, setAuthor] = useState("");
  const [quote, setQuote] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    setAuthor("");
    setQuote("");
    setImage("");
  }, [showAddModal]);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/config/${testimonialData._id}`, { testimonials });
  }, [testimonialData._id, testimonials]);

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(({ data }) => {
        if (data.file) setImage(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  const addItem = (e) => {
    e.preventDefault();

    if (!author || !quote || !image)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    const newTestimonials = [{ author, quote, image }, ...testimonials];
    setTestimonials(newTestimonials);
    setShowAddModal(false);
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this testimonial?")) {
      const newTestimonials = testimonials.filter((_, i) => i !== index);
      setTestimonials(newTestimonials);
    }
  };

  return (
    <div>
      {showAddModal && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
          <div className="modal">
            <h2>Add Testimonial</h2>

            <form onSubmit={addItem}>
              <div className="form-row">
                <label htmlFor="image">Image</label>
                <Dropzone
                  multiple={false}
                  accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                  onDropAccepted={(e) => uploadImage(e)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="input-dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                    </section>
                  )}
                </Dropzone>

                {image && (
                  <div
                    className="form-image"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                )}
              </div>

              <div className="form-row">
                <label htmlFor="author">Author</label>
                <input
                  type="text"
                  id="author"
                  className="admin-input"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>

              <div className="form-row">
                <label htmlFor="quote">Quote</label>
                <textarea
                  id="quote"
                  className="admin-input"
                  rows="5"
                  value={quote}
                  onChange={(e) => setQuote(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-admin">
                Add
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">Testimonials</h2>

        {/* Table */}
        <table className="raffles-table">
          <thead>
            <tr>
              <th style={{ width: 250 }}>Image</th>
              <th>Quote</th>
              <th>Author</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!testimonials.length ? (
              <tr>
                <td colSpan="4" className="no-raffles">
                  No testimonials to show
                </td>
              </tr>
            ) : (
              testimonials.map(({ image, quote, author }, i) => (
                <tr key={i}>
                  <td>
                    <img src={`${image}`} alt="Raffle" className="raffle-table-image" />
                  </td>
                  <td>{quote}</td>
                  <td>{author}</td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => deleteItem(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <button
          onClick={() => setShowAddModal(true)}
          className="btn btn-admin btn-small"
          style={{ marginTop: 10 }}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default ConfigTestimonials;
