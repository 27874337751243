import React from "react";

const SortBySwitcher = ({ sortOptions = [], sortBy, setSortBy, className }) => {
  let sortByClass = false;
  
  sortOptions.forEach((sortOption, i) => {
    if (sortBy === sortOption.sort) {
      let num = false;

      switch (i) {
        case 0:
          num = "one";
          break;
        case 1:
          num = "two";
          break;
        case 2:
          num = "three";
          break;
        default:
          return;
      }

      sortByClass = `active-${num}`;
    }
  });

  return (
    <div
      className={`btn-switcher ${className} ${sortByClass ? sortByClass : ""}`}
    >
      {sortOptions.map(({ sort, name }) => (
        <button
          key={name}
          className={sortBy === sort ? "active" : ""}
          onClick={() => setSortBy(sort)}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default SortBySwitcher;
