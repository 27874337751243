import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { ArrowLeft, CheckCircle } from "react-feather";
import LoadingSpinner from "../includes/LoadingSpinner";
import { AppContext } from "../../context/AppContext";

const ForgotPassword = () => {
  const { messages } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth/forgot-password`, { email })
      .then(() => {
        setComplete(true);
      })
      .catch(() => {
        setLoading(false);
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem with that request. Please try again later.",
          },
        });
      });
  };

  return (
    <div className="row center-cols">
      <div className="col-5">
        <div className="slate">
          {complete ? (
            <div>
              <div className="center">
                <CheckCircle size={40} color="#2fde80" />
                <h1>Check your email (and junk folder)</h1>
                <p className="forgot-password-complete-desc">
                  If that email is associated with a Massive Competitions account we will have sent you an email
                  with instructions on how to reset your password. The email may take a few minutes
                  to arrive, and may be in your spam folder.
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="user-account-main-header">
                <h1>
                  <Link to="/account/settings" className="back-btn">
                    <ArrowLeft />
                  </Link>
                  Forgot Password
                </h1>
              </div>

              <p className="forgot-password-desc">
                Not to worry! Enter the email associated with your account below and we will send
                you an email to reset your password if an account with that email exists.
              </p>

              <form onSubmit={submit}>
                <div className="form-row">
                  <input
                    id="email"
                    type="email"
                    className="input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <button type="submit" className="btn form-btn" disabled={!email || loading}>
                  {loading ? <LoadingSpinner /> : "Send Email"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
