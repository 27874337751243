import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { ChevronRight, ChevronLeft } from "react-feather";

const RafflesTable = ({ raffles = [], page, setPage, totalPages }) => {
  const prev = () => setPage(page - 1);
  const next = () => setPage(page + 1);
  return (
    <div>
      <table className="raffles-table">
        <thead>
          <tr>
            <th style={{ width: 30 }}></th>
            <th>Title</th>
            <th>Entrants</th>
            <th>Sales</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th style={{ width: 160 }}></th>
          </tr>
        </thead>
        <tbody>
          {!raffles.length ? (
            <tr>
              <td colSpan="7" className="no-raffles">
                No raffles to show
              </td>
            </tr>
          ) : (
            raffles.map((raffle) => (
              <tr key={raffle._id}>
                <td>
                  <div className={`status-dot ${raffle.live ? "active" : "inactive"}`}></div>
                </td>
                <td>{raffle.name}</td>
                <td>
                  {raffle.ticketpurchases.length}/{raffle.maxEntries}
                </td>
                <td>
                  {raffle.totalSales.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </td>
                <td>{moment(raffle.startDate).format("DD/MM/YYYY (HH:mm)")}</td>
                <td>{moment(raffle.endDate).format("DD/MM/YYYY (HH:mm)")}</td>
                <td className="right">
                  <Link to={`/admin/raffles/${raffle._id}`} className="btn btn-admin">
                    View
                  </Link>
                  {/* <button className="delete-btn" onClick={() => deleteRaffle(raffle._id)}>
                    <Trash2 />
                  </button> */}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="raffles-paginate">
        <button onClick={prev} disabled={page === 1} className="btn btn-admin btn-paginate">
          <ChevronLeft />
        </button>
        <button
          onClick={next}
          disabled={page === totalPages}
          className="btn btn-admin btn-paginate"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default RafflesTable;
