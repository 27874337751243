import React from "react";

const AdminHeader = () => {
  return (
    <header className="admin-header">
      <div className="header-logo"></div>
    </header>
  );
};

export default AdminHeader;
