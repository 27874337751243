import React, { useState, useEffect, useContext } from "react";
import { Trash2 } from "react-feather";
import Axios from "axios";
import AddCategoryModal from "./AddCategoryModal";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import moment from "moment";

const CategoriesPage = () => {
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [categoryAdded, setCategoryAdded] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/categories`).then(({ data }) => {
      setCategories(data.categories);
      setLoading(false);
    });
  }, [categoryAdded]);

  const deleteCat = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      Axios.delete(`${process.env.REACT_APP_API}/api/categories/${id}`)
        .then(() => {
          setCategories(categories.filter((cat) => cat._id !== id));
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem deleting that category" },
          });
        });
    }
  };

  return (
    <div className="row">
      <div className="col">
        {showAddModal && (
          <AddCategoryModal
            closeModal={() => setShowAddModal(false)}
            categoryAdded={setCategoryAdded}
          />
        )}

        {/* Title */}
        <div className="row no-padding">
          <div className="col">
            <h1 className="admin-title">Categories</h1>
          </div>
          <div className="col right">
            <button className="btn btn-admin" onClick={() => setShowAddModal(true)}>
              Add New
            </button>
          </div>
        </div>

        {/* Table */}
        <table className="raffles-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">
                  <LoadingSpinner dark={true} className="center" />
                </td>
              </tr>
            ) : !categories.length ? (
              <tr>
                <td colSpan="3" className="no-raffles">
                  No categories to show
                </td>
              </tr>
            ) : (
              categories.map(({ _id, name, createdAt }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{moment(createdAt).fromNow()}</td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => deleteCat(_id)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoriesPage;
