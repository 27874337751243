import React from "react";
import { Link } from "react-router-dom";

const RaffleCategorySearchVideo = ({ searchTags, categories }) => {
  return (
    <div>
      <div className="row no-padding gutter">
        <div className="col-6">
          <div className="admin-slate">
            <h2>Category</h2>
            {categories.length > 0 ? (
              <div className="tags">
                {categories.map(cat => (
                  <Link to="/admin/categories" key={cat} className="tag">
                    {cat}
                  </Link>
                ))}
              </div>
            ) : (
              <p>No categories set</p>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="admin-slate">
            <h2>Search Tags</h2>
            {searchTags.length > 0 ? (
              <div className="tags">
                {searchTags.map(tag => (
                  <div key={tag} className="tag">
                    {tag}
                  </div>
                ))}
              </div>
            ) : (
              <p>No tags set</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleCategorySearchVideo;
