import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ExternalLink,
  Grid,
  Award,
  TrendingUp,
  Sliders,
  Users,
  FileText,
  Percent,
  ShoppingCart,
} from "react-feather";
import { AppContext } from "../../../context/AppContext";

const AdminNavigation = () => {
  const location = useLocation();
  const { user } = useContext(AppContext);

  const links = [
    { icon: <ExternalLink />, title: "Main Site", url: "/" },
    { icon: <TrendingUp />, title: "Dashboard", url: "/admin" },
    { icon: <Award />, title: "Raffles", url: "/admin/raffles" },
    { icon: <ShoppingCart />, title: "Orders", url: "/admin/orders" },
    { icon: <Grid />, title: "Categories", url: "/admin/categories" },
    { icon: <Percent />, title: "Coupon Codes", url: "/admin/coupon-codes" },
    { icon: <Sliders />, title: "Configure", url: "/admin/config" },
    { icon: <FileText />, title: "Pages", url: "/admin/dynamic-pages" },
    { icon: <Users />, title: "Users", url: "/admin/users" },
  ];

  return (
    <nav className="admin-nav">
      <div className="nav-username">{user.state.firstName}</div>

      <ul className="nav-links">
        {links.map(({ icon, title, url }, i) => (
          <li key={title}>
            <Link
              to={url}
              className={i && location.pathname.split("/")[2] === url.split("/")[2] ? "active" : ""}
            >
              {icon} {title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AdminNavigation;
