import React, { useState } from "react";
import moment from "moment";
import { ChevronDown, ChevronUp, CreditCard } from "react-feather";
import { Link } from "react-router-dom";

const OrderItem = ({ order }) => {
  const {
    orderId,
    status,
    totalPricePaid,
    ticketsSubtotal,
    promotionalBalanceUsed,
    couponCodeDiscount,
    couponCodeUsed,
    ticketsPurchased,
    trustTransactionRef,
    trustPaymentDetails,
    createdAt,
  } = order;

  const [expanded, setExpanded] = useState(false);

  return (
    <div className="order-item-container">
      <div className={`order-item ${expanded ? "expanded" : ""}`}>
        <div className="order-item-details">
          <div className="order-item-detail">
            <div className="detail-header">Order ID</div>
            <div className="detail-body">{orderId}</div>
          </div>

          <div className="order-item-detail">
            <div className="detail-header">Transaction Date</div>
            <div className="detail-body">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</div>
          </div>
        </div>

        <div className="order-item-status-total">
          <div className="order-item-status tag text-primary">{status}</div>
          <div className="order-item-total">
            Total: {totalPricePaid.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
          </div>
          <button className="order-item-expcoll" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        </div>
      </div>

      <div className="order-item-expanded-content" style={{ maxHeight: !expanded ? 0 : 500 }}>
        <div className="order-item-tickets">
          {ticketsPurchased.map(({ raffle, pricePaid, ticketNum }) => (
            <div className="order-item-ticket" key={raffle._id}>
              <Link to={`/c/${raffle.slug}`}>{raffle.name}</Link>
              <span>
                Ticket Number: {ticketNum}
                <span className="text-muted">|</span>
                {pricePaid.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
              </span>
            </div>
          ))}
        </div>
        <div className="order-payment">
          <div className="order-card-details">
            {trustTransactionRef !== "NO_PAYMENT_REQUIRED" && trustPaymentDetails.cardNumber && (
              <>
                <CreditCard /> ({trustPaymentDetails.cardType}) ****{" "}
                {trustPaymentDetails.cardNumber.substr(trustPaymentDetails.cardNumber.length - 4)}
              </>
            )}
          </div>

          <div className="order-breakdown">
            {[
              { line: "Subtotal", amount: ticketsSubtotal },
              { line: "Promo Balance", amount: -promotionalBalanceUsed },
              { line: `Coupon Code (${couponCodeUsed})`, amount: -couponCodeDiscount },
              { line: "Total Payable", amount: totalPricePaid },
            ].map(
              ({ line, amount }) =>
                (line === "Total Payable" || amount !== 0) && (
                  <div className="breakdown-item" key={line}>
                    <div className="breakdown-item-text">{line}</div>
                    <div className="breakdown-item-amount">
                      {amount.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
