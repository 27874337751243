import React from "react";
import { useParams, Redirect } from "react-router-dom";

const RaffleRedirectPage = () => {
  const { slug } = useParams();

  return (
    <Redirect to={`/c/${slug}`} />
  );
};

export default RaffleRedirectPage;