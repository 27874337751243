import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MyTickets from "./tabs/MyTickets";
import AccountSettings from "./tabs/AccountSettings";
//import Referrals from "./tabs/Referrals";
import Support from "./tabs/Support";
import AccountSidebar from "./sidebar/AccountSidebar";
import ChangePassword from "./tabs/account-settings-pages/ChangePassword";
import AccountHistory from "./tabs/account-settings-pages/AccountHistory";
import EditDetails from "./tabs/account-settings-pages/EditDetails";
import TwoFactorAuth from "./tabs/account-settings-pages/TwoFactorAuth";
import PaymentMethods from "./tabs/account-settings-pages/PaymentMethods";
//import InstantWins from "./tabs/InstantWins";

const UserAccount = () => {
  return (
    <div className="row gutter user-account">
      <div className="col-3">
        <AccountSidebar />
      </div>

      <div className="col-9">
        <div className="slate">
          <div className="user-account-main">
            <Switch>
              {/*
              <Route path="/account/instant-wins">
                <InstantWins />
              </Route>
              */}

              <Route path="/account/tickets">
                <MyTickets />
              </Route>

              {/* Settings */}
              <Route path="/account/settings" exact>
                <AccountSettings />
              </Route>
              <Route path="/account/settings/edit">
                <EditDetails />
              </Route>
              <Route path="/account/settings/change-password">
                <ChangePassword />
              </Route>
              <Route path="/account/settings/payment-methods">
                <PaymentMethods />
              </Route>
              <Route path="/account/settings/2fa">
                <TwoFactorAuth />
              </Route>
              <Route path="/account/settings/history">
                <AccountHistory />
              </Route>
              
              {/*
              <Route path="/account/referrals">
                <Referrals />
              </Route>
              */}

              <Route path="/account/support">
                <Support />
              </Route>

              <Route path="/account">
                <Redirect to="/account/tickets" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
