import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const RaffleCard = ({ completed, slug, name, originalPrice, endDate, price, onSale, images, ticketsPurchased, maxEntries, upsell }) => {
  const raffleImage = images.find(({ keyImage }) => keyImage)
    ? `url(${images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  const [currentTime, setCurrentTime] = useState(moment());
  const [timeRemaining, setTimeRemaining] = useState(moment());
  
  useEffect(() => {
    const intervalID = setInterval(() => setCurrentTime(moment()), 1000);
    return () => clearInterval(intervalID);
  }, [slug]);
  
  useEffect(() => {
    const endDateMoment = moment(endDate);
    setTimeRemaining(moment.duration((endDateMoment.unix() - currentTime.unix()) * 1000, "milliseconds")._milliseconds);
  }, [currentTime, endDate]);

  return (
    <Link to={`/c/${slug}`} className={`raffle-card${completed ? " completed" : ""}${upsell ? " upsell" : ""}`}>
      <div className="raffle-card-bg-shade"></div>
      <div className="raffle-card-image" style={{ backgroundImage: raffleImage }}></div>
      <div className="raffle-card-contents">
        <div className="tickets-remaining">
          <div className="tickets-bar-container">
            <div className="bar">
              {completed ? (
                <div className="bar-fill" style={{'--width': (ticketsPurchased.length / maxEntries) * 100 + "%" }}></div>
              ) : (
                <div className="bar-fill" style={{'--width': ((maxEntries - ticketsPurchased.length) / maxEntries) * 100 + "%" }}></div>
              )}
            </div>
            <div className="bar-indices">
              {completed ? (
                <div className="left">{ticketsPurchased.length} sold</div>
              ) : (
                <div className="left">{maxEntries - ticketsPurchased.length} left</div>
              )}
              <div className="right">{maxEntries} total</div>
            </div>
          </div>
        </div>

        <div className="raffle-name">{name}</div>
        <div className="raffle-price">
          {onSale && (
            <small>
              <s>{originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</s>
            </small>
          )}{" "}
          {price === 0
            ? "FREE"
            : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
        </div>

        {!completed && (
          <div className={`row raffle-timeleft${upsell ? " upsell" : ""}`}>
            <div className="col-3">
              <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0}</p> days</div>
            </div>
            <div className="col-3">
              <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0}</p> hours</div>
            </div>
            <div className="col-3">
              <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0}</p> minutes</div>
            </div>
            <div className="col-3">
              <div className="timeleft-box"><p className="time">{timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0}</p> seconds</div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default RaffleCard;
