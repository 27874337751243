import React, { useState, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";

const EditUser = ({ user, userUpdated, onClose }) => {
  const { messages } = useContext(AppContext);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);

  if (!user.addresses) {
    user.addresses = {};
  }

  const [shipLine1, setShipLine1] = useState(user.addresses.shipping?.addressLine1 || "");
  const [shipLine2, setShipLine2] = useState(user.addresses.shipping?.addressLine2 || "");
  const [shipCity, setShipCity] = useState(user.addresses.shipping?.city || "");
  const [shipCounty, setShipCounty] = useState(user.addresses.shipping?.county || "");
  const [shipPostCode, setShipPostCode] = useState(user.addresses.shipping?.postCode || "");
  const [duplicateShippingAddr, setDuplicateShippingAddr] = useState(user.duplicateShippingAddr);

  const [billLine1, setBillLine1] = useState(user.addresses.billing?.addressLine1 || "");
  const [billLine2, setBillLine2] = useState(user.addresses.billing?.addressLine2 || "");
  const [billCity, setBillCity] = useState(user.addresses.billing?.city || "");
  const [billCounty, setBillCounty] = useState(user.addresses.billing?.county || "");
  const [billPostCode, setBillPostCode] = useState(user.addresses.billing?.postCode || "");

  const submit = (e) => {
    e.preventDefault();
    
    user.addresses.shipping = {
      addressLine1: shipLine1,
      addressLine2: shipLine2,
      city: shipCity,
      county: shipCounty,
      postCode: shipPostCode
    };

    if (!duplicateShippingAddr)
      user.addresses.billing = {
        addressLine1: billLine1,
        addressLine2: billLine2,
        city: billCity,
        county: billCounty,
        postCode: billPostCode
      };

    Axios.put(`${process.env.REACT_APP_API}/api/user/${user._id}`, {
      firstName,
      lastName,
      email,
      duplicateShippingAddr,
      addresses: user.addresses,
      phone,
    })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully updated" },
        });
        userUpdated(Date.now());
        onClose();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={onClose} />
      <div className="modal">
        <h2>Edit User</h2>

        <form onSubmit={submit}>
          <div className="row no-padding gutter">
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
          </div>

          <div className="row no-padding gutter">
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
          </div>

          <div className="row no-padding gutter">
            <h3>Shipping Address</h3>
          </div>

          <div className="row no-padding gutter">
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="addressLine1">Address Line 1</label>
                <input
                  type="text"
                  id="shipAddressLine1"
                  value={shipLine1}
                  onChange={(e) => setShipLine1(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="addressLine2">Address Line 2</label>
                <input
                  type="text"
                  id="shipAddressLine2"
                  value={shipLine2}
                  onChange={(e) => setShipLine2(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
          </div>

          <div className="row no-padding gutter">
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="shipCity"
                  value={shipCity}
                  onChange={(e) => setShipCity(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="county">County</label>
                <input
                  type="text"
                  id="shipCounty"
                  value={shipCounty}
                  onChange={(e) => setShipCounty(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
          </div>

          <div className="row no-padding gutter">
            <div className="col-6">
              <div className="form-row">
                <label htmlFor="postCode">Post Code</label>
                <input
                  type="text"
                  id="shipPostCode"
                  value={shipPostCode}
                  onChange={(e) => setShipPostCode(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>
            <div className="col-6">
                <div className="form-row">
                  <label htmlFor="duplicateShippingAddress">Duplicate Shipping Address</label>
                  <input
                      type="checkbox"
                      id="duplicateShippingAddr"
                      checked={duplicateShippingAddr}
                      onChange={(e) => setDuplicateShippingAddr(e.target.checked)}
                    />
                </div>
              </div>
          </div>

          {!duplicateShippingAddr &&
            <>
              <div className="row no-padding gutter">
                <h3>Billing Address</h3>
              </div>
              <div className="row no-padding gutter">
                <div className="col-6">
                  <div className="form-row">
                    <label htmlFor="addressLine1">Address Line 1</label>
                    <input
                      type="text"
                      id="billAddressLine1"
                      value={billLine1}
                      onChange={(e) => setBillLine1(e.target.value)}
                      className="admin-input"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-row">
                    <label htmlFor="addressLine2">Address Line 2</label>
                    <input
                      type="text"
                      id="billAddressLine2"
                      value={billLine2}
                      onChange={(e) => setBillLine2(e.target.value)}
                      className="admin-input"
                    />
                  </div>
                </div>
              </div>

              <div className="row no-padding gutter">
                <div className="col-6">
                  <div className="form-row">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      id="billCity"
                      value={billCity}
                      onChange={(e) => setBillCity(e.target.value)}
                      className="admin-input"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-row">
                    <label htmlFor="county">County</label>
                    <input
                      type="text"
                      id="billCounty"
                      value={billCounty}
                      onChange={(e) => setBillCounty(e.target.value)}
                      className="admin-input"
                    />
                  </div>
                </div>
              </div>

              <div className="row no-padding gutter">
                <div className="col-6">
                  <div className="form-row">
                    <label htmlFor="postCode">Post Code</label>
                    <input
                      type="text"
                      id="billPostCode"
                      value={billPostCode}
                      onChange={(e) => setBillPostCode(e.target.value)}
                      className="admin-input"
                    />
                  </div>
                </div>
              </div>
            </>
          }

          <div className="row no-padding gutter">
            <button type="submit" className="btn btn-admin">
              Edit
            </button>
            <button onClick={onClose} className="btn btn-admin">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
