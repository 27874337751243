import React, { useState, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";

const EditPromoBal = ({ user, userUpdated, onClose }) => {
  const { messages } = useContext(AppContext);
  const [promotionalBalance] = useState(user.promotionalBalance || 0);
  const [newTransaction, setNewTransaction] = useState(0);
  const [newTransactionNotes, setNewTransactionNotes] = useState("");

  const submit = (e) => {
    e.preventDefault();

    const newBalance = parseFloat((promotionalBalance + parseFloat(newTransaction)).toFixed(2));

    if (newBalance < 0) {
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "New balance cannot be less than 0" },
      });
    }

    Axios.put(`${process.env.REACT_APP_API}/api/user/${user._id}`, {
      promotionalBalance: newBalance,
      $push: {
        promotionalBalanceHistory: {
          amount: parseFloat(newTransaction),
          notes: newTransactionNotes,
        },
      },
    })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully updated" },
        });
        userUpdated(Date.now());
        onClose();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={onClose} />
      <div className="modal">
        <h2>Edit Promotional Balance</h2>

        <form onSubmit={submit}>
          <div className="row no-padding gutter">
            <div className="col-8">
              <div className="form-row">
                <label htmlFor="promotionalBalance">Add or Remove Amount</label>
                <span className="input-currency">£</span>
                <input
                  type="number"
                  id="promotionalBalance"
                  value={newTransaction}
                  onChange={(e) => setNewTransaction(e.target.value)}
                  className="admin-input currency"
                />
              </div>

              <div className="form-row">
                <label htmlFor="notes">Transaction Note</label>
                <input
                  type="text"
                  id="notes"
                  value={newTransactionNotes}
                  onChange={(e) => setNewTransactionNotes(e.target.value)}
                  className="admin-input"
                />
              </div>
            </div>

            <div className="col-4">
              <div className="form-row">
                <label>Current Balance</label>
                <h2>
                  {promotionalBalance.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </h2>
              </div>
            </div>
          </div>

          <div className="row no-padding gutter">
            <button type="submit" className="btn btn-admin">
              Done
            </button>
            <button onClick={onClose} className="btn btn-admin">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPromoBal;
