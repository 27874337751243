import React, { useState } from "react";
import { Plus, X } from "react-feather";
import { useEffect } from "react";
import Axios from "axios";

const RaffleModifyCategorySearch = ({ categories, searchTags, update }) => {
  const [showAddCatPopover, setShowAddCatPopover] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allCategoriesFiltered, setAllCategoriesFiltered] = useState([]);
  const [filterCatsQuery, setFilterCatsQuery] = useState("");

  const [searchTagsAsString, setSearchTagsAsString] = useState(searchTags.toString());

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/categories`).then(({ data }) => {
      setAllCategories(data.categories);
    });
  }, []);

  // Filter categories for search popover
  useEffect(() => {
    setAllCategoriesFiltered(
      allCategories.filter(
        ({ name }) =>
          name.toLowerCase().includes(filterCatsQuery.toLowerCase()) && !categories.includes(name)
      )
    );
  }, [allCategories, categories, filterCatsQuery]);

  const addCategory = (name) => {
    update("categories", [...categories, name]);
    setShowAddCatPopover(false);
  };

  const deleteCategory = (name) => {
    update(
      "categories",
      categories.filter((cat) => cat !== name)
    );
  };

  return (
    <div className="row no-padding gutter">
      <div className="col-6">
        <div className="admin-slate">
          <h2>Category</h2>
          <div className="tags">
            {categories.map((cat) => (
              <div key={cat} className="tag">
                {cat}{" "}
                <button onClick={() => deleteCategory(cat)}>
                  <X />
                </button>
              </div>
            ))}
            <div className="new-tag-container">
              <div className="tag new" onClick={() => setShowAddCatPopover(!showAddCatPopover)}>
                <Plus />
              </div>

              {showAddCatPopover && (
                <div className="new-tag-search">
                  <input
                    type="search"
                    className="admin-input"
                    placeholder="Search for categories"
                    autoFocus={true}
                    value={filterCatsQuery}
                    onChange={(e) => setFilterCatsQuery(e.target.value)}
                  />
                  <div className="category-results">
                    {allCategoriesFiltered.length ? (
                      allCategoriesFiltered.map(({ name }) => (
                        <div key={name} onClick={() => addCategory(name)}>
                          {name}
                        </div>
                      ))
                    ) : (
                      <div className="no-cats">No categories to show</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="admin-slate">
          <h2>Search Tags</h2>
          <input
            type="text"
            className="admin-input"
            placeholder="for example: apple, phone, ..."
            value={searchTagsAsString}
            onChange={(e) => {
              setSearchTagsAsString(e.target.value);
              update(
                "searchTags",
                e.target.value
                  .split(",")
                  .map((t) => t.trim())
                  .filter((t) => t)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyCategorySearch;
