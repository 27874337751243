import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RaffleHeader from "./includes/RaffleHeader";
import RaffleInfo from "./includes/RaffleInfo";
import RaffleDesc from "./includes/RaffleDesc";
import RaffleCategorySearch from "./includes/RaffleCategorySearch";
import RaffleTicketsVideo from "./includes/RaffleTicketsQuestionsVideo";
import RaffleInstantWins from "./includes/RaffleInstantWins";

const RafflePage = () => {
  const { messages } = useContext(AppContext);
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [raffleData, setRaffleData] = useState({});

  const messageDispatch = messages.dispatch;

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffles/${id}`)
      .then(({ data }) => {
        const raffleData = data.raffle;

        Axios.get(`${process.env.REACT_APP_API}/api/instantWins/raffle/${id}`).then(({ data }) => {
          const winners = new Map();

          data.instantWins.forEach(({ ticketNum, user }) => {
            winners.set(ticketNum, user);
          });

          raffleData.winners = winners;

          setRaffleData(raffleData);
          setLoading(false);
        }).catch(() => {
          setRaffleData(raffleData);
          setLoading(false);

          messageDispatch({
            type: "send",
            payload: { type: "warning", msg: "Failed to load instant win data for this raffle" }
          });
        });
      })
      .catch(() => {
        setLoading(false);
        messageDispatch({
          type: "send",
          payload: { type: "error", msg: "That raffle has either been deleted or does not exist" },
        });
        history.push("/admin/raffles");
      });
  }, [id, messageDispatch, history]);

  return (
    <div className="row admin-raffle-page">
      <div className="col">
        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <div>
            <RaffleHeader
              id={raffleData._id}
              live={raffleData.live}
              name={raffleData.name}
              slug={raffleData.slug}
              endDate={raffleData.endDate}
            />
            <RaffleInfo
              id={raffleData._id}
              image={raffleData.images.find(({ keyImage }) => keyImage === true)}
              originalPrice={raffleData.originalPrice}
              price={raffleData.price}
              onSale={raffleData.onSale}
              purchases={raffleData.ticketpurchases.length}
              maxEntries={raffleData.maxEntries}
              startDate={raffleData.startDate}
              endDate={raffleData.endDate}
            />
            <RaffleDesc desc={raffleData.description} />
            <RaffleInstantWins instantWins={raffleData.instantWins} instantWinsDescription={raffleData.instantWinsDescription} winners={raffleData.winners} />
            <RaffleCategorySearch
              searchTags={raffleData.searchTags}
              categories={raffleData.categories}
            />
            <RaffleTicketsVideo
              maxEntries={raffleData.maxEntries}
              maxEntriesPerPerson={raffleData.maxEntriesPerPerson}
              ticketMatrix={raffleData.ticketMatrix}
              question={raffleData.question && raffleData.question.question}
              answers={raffleData.question && raffleData.question.answers}
              video={raffleData.videoUrl}
              allowPromoBalance={raffleData.allowPromoBalance}
              luckyDipDefault={raffleData.luckyDipDefault}
              luckyDipsOnly={raffleData.luckyDipsOnly}
              guaranteedWinnerInfoBox={raffleData.guaranteedWinnerInfoBox}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RafflePage;
