import React, { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { ExternalLink } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import MyPromotionalBalance from "./includes/MyPromotionalBalance";

const AccountSidebar = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  const accountLinks = [
    { title: "My Tickets", url: "/account/tickets" },
    { title: "Account", url: "/account/settings" },
    { title: "Support", url: "/account/support" },
    { title: "Logout", url: "/auth/logout" },
    user.state.admin && {
      title: (
        <>
          Admin Console <ExternalLink width={16} height={16} />
        </>
      ),
      url: "/admin",
      className: "admin-link",
      newTab: true,
    },
  ];

  return (
    <div>
      <div className="slate no-padding">
        <div className="user-account-name">{user.state.firstName}</div>

        <MyPromotionalBalance />

        <nav className="user-account-nav">
          <ul>
            {accountLinks.map(
              ({ title, url, className, newTab }) =>
                title && (
                  <li key={title}>
                    <Link
                      to={url}
                      className={`${className} ${location.pathname.includes(url) ? "active" : ""}`}
                      target={newTab && "_blank"}
                    >
                      {title}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AccountSidebar;
