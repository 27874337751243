import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

const RaffleImageCarousel = ({ onSale, images = [] }) => {
  const [sortedImages] = useState(images.sort((a, b) => b.keyImage - a.keyImage));
  const [selectedImage, setSelectedImage] = useState(0);

  const left = () => {
    if (selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    }
  };

  const right = () => {
    if (selectedImage < sortedImages.length - 1) {
      setSelectedImage(selectedImage + 1);
    }
  };

  const imagePath = sortedImages.length ? `${sortedImages[selectedImage].path}` : false;

  return (
    <>
      <div
        className="image-slider-container"
        style={{
          backgroundImage: `url(${imagePath})`,
        }}
      >
        {onSale && <div className="raffle-sale-banner">Sale</div>}

        <button className="next" onClick={left} disabled={selectedImage === 0}>
          <ChevronLeft />
        </button>

        <button
          className="prev"
          onClick={right}
          disabled={!sortedImages.length || selectedImage === sortedImages.length - 1}
        >
          <ChevronRight />
        </button>
      </div>
    </>
  );
};

export default RaffleImageCarousel;
