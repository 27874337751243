import React, { useState } from "react";
import { Edit2, Trash2 } from "react-feather";
import AddEditPrizeModal from "./add-question/AddEditPrizeModal";

const RaffleModifyInstantWins = ({ description = "", prizes = [], update }) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const afterSubmit = (prize) => {
    if (typeof showAddModal === "object") {
      return update("instantWins", prizes.map((p, i) => (i === showAddModal.i ? prize : p)));
    }

    return update("instantWins", [...prizes, prize]);
  };

  const deletePrize = (i) => {
    if (window.confirm("Are you sure that you would like to delete this prize?"))
      update("instantWins", prizes.filter((_, j) => i !== j));
  };

  return (
    <div className="row no-padding gutter">
      <div className="col-6">
        <div className="admin-slate">
          <h2>Instant Wins Description</h2>
          <textarea
            cols="30"
            rows="3"
            className="admin-input"
            value={description}
            onChange={e => update("instantWinsDescription", e.target.value)}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="admin-slate">
          <h2>Instant Wins Prizes</h2>

          {showAddModal && (
            <AddEditPrizeModal
              afterSubmit={afterSubmit}
              closeModal={() => setShowAddModal(false)}
              prizeData={showAddModal}
            />
          )}

          <div className="form-row">
            <div className="row no-padding no-gutter">
              <div className="col right">
                <button type="button" className="btn btn-admin btn-tiny" onClick={() => setShowAddModal(true)}>
                  Add Prizes
                </button>
              </div>
            </div>

            <table className="raffles-table no-side-padding">
              <tbody>
                {!prizes.length ? (
                  <tr>
                    <td colSpan="3" className="no-raffles">
                      No prizes to show
                    </td>
                  </tr>
                ) : (
                  prizes.map(({ prize, ticketNum }, i) => (
                    <tr key={i}>
                      <td className="left">#{ticketNum}</td>
                      <td>{prize}</td>
                      <td className="right">
                        <button
                          type="button"
                          className="delete-btn"
                          onClick={() => setShowAddModal({ i, prize, ticketNum })}
                        >
                          <Edit2 />
                        </button>
                        <button type="button" className="delete-btn" onClick={() => deletePrize(i)}>
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyInstantWins;