import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import Winner from "./includes/Winner";
import { ChevronDown, ChevronUp } from "react-feather";
import moment from "moment";

const WinnersPage = () => {
  const [startYear] = useState(2021);

  const [loading, setLoading] = useState(true);
  const [winners, setWinners] = useState([]);

  const [months] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
  const [years, setYears] = useState([]);

  const [month, setMonth] = useState(`${moment().month() + 1}`);
  const [year, setYear] = useState(`${moment().year()}`);

  const [showMonth, setShowMonth] = useState(false);
  const [showYear, setShowYear] = useState(false);

  useEffect(() => {
    const years = [];
    var year = moment().year();

    while (year >= startYear) {
      years.push(`${year}`);
      year--;
    }

    setYears(years);
  }, [startYear]);

  useEffect(() => {
    setShowMonth(false);
    setShowYear(false);
    
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API}/api/winners/count`).then(({ data }) => {
      if (data.count > 0) {
        Axios.get(`${process.env.REACT_APP_API}/api/winners/all/${year}/${month < 10 ? `0${month}` : month}`).then(({ data }) => {
          setWinners(data.winners);
          setLoading(false);
        });
      } else {
        setWinners([]);
        setLoading(false);
      }
    });
  }, [month, year]);

  return (
    <div className="row">
      <div className="col">
        <h1 className="winners-title">Winners</h1>

        <div className="row no-padding gutter">
          <p className="winners-text">
            Here are the lucky Massive Competitions winners! Winning numbers are picked at random each week using Google's random number generator.{" "}
            All of our previous raffles can be viewed <Link to="/completed">here</Link> and all our live draws can be viewed down below!
          </p>
        </div>

        <div className="row gutter">
          <div className="col">
            <div className="selector-sort-dropdown-container right">
              <button className="btn btn-white" onClick={() => {
                setShowMonth(!showMonth);
                setShowYear(false);
              }}>
                {months[month - 1]} {showMonth ? <ChevronUp /> : <ChevronDown />}
              </button>
              {showMonth && (
                <div className="sort-dropdown month">
                  <ul>
                    {months.map((month, i) => (
                      <li onClick={() => setMonth(i + 1)}>{month}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col">
            <div className="selector-sort-dropdown-container">
              <button className="btn btn-white" onClick={() => {
                setShowMonth(false);
                setShowYear(!showYear);
              }}>
                {year} {showYear ? <ChevronUp /> : <ChevronDown />}
              </button>
              {showYear && (
                <div className="sort-dropdown year">
                  <ul>
                    {years.map((y) => (
                      <li onClick={() => setYear(y)}>{y}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {loading ? (
          <LoadingSpinner className="center" />
        ) :
        winners.length ? (
          <>
            <div className="row no-padding gutter">
              {winners.map((winner, i) => (
                <div className="col-4" key={i}>
                  <Winner winner={winner} />
                </div>
              ))}
              {[winners % 3].fill().map((_, i) => (
                <div className="col-4" key={i} />
              ))}
            </div>
          </>
        ) : (
          <div className="row gutter">
            <div className="col">
              <p style={{ marginBottom: "225px", textTransform: "uppercase", letterSpacing: "3px", textAlign: "center" }}>No winners have been added yet for {months[month - 1]} {year}!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnersPage;