import React from "react";
import moment from "moment";
import PromotedRaffleCard from "./includes/PromotedRaffleCard";
import PromotedRaffleCardMain from "./includes/PromotedRaffleCardMain";

const PromotedRaffles = ({ promotedRaffles, statistics, winners }) => {
  const main = promotedRaffles.find(({ position }) => position === "main") || {};
  const secondaries = promotedRaffles.filter(({ position }) => position === "secondary") || [];

  const active = statistics?.options?.active;
  const charity = statistics?.options?.charity || 0;
  const prizes = statistics?.options?.prizes || 0;

  return (
    <div className="row">
      <div className="col">
        <div className="row no-padding gutter">
          <div className="col-12">
            <PromotedRaffleCardMain
              large={true}
              slug={main.raffle ? main.raffle.slug : ""}
              name={main.raffle ? main.raffle.name : ""}
              images={main.raffle ? main.raffle.images : []}
              createdAt={main.raffle ? moment(main.raffle.endDate).format() : moment()}
            />
          </div>
        </div>

        <div className="row no-padding gutter">
          {secondaries.map(
            ({ raffle, ticketsPurchased }, i) =>
              raffle && ticketsPurchased && (
                <div className="col-4" key={i}>
                  <PromotedRaffleCard
                    slug={raffle.slug}
                    name={raffle.name}
                    images={raffle.images}
                    endDate={raffle.endDate}
                    onSale={raffle.onSale}
                    maxEntries={raffle.maxEntries}
                    ticketsPurchased={ticketsPurchased}
                    price={raffle.price}
                    originalPrice={raffle.originalPrice}
                  />
                </div>
              )
          )}
        </div>

        {active && (
          <div className="row no-padding gutter stats-winners">
            <div className="col-4">
              <div className="stats-winners-img">
                <div className="prizes"></div>
              </div>
              <div className="stats-winners-title">{parseInt(prizes).toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
              <div className="stats-winners-text">Given in prizes</div>
            </div>
            <div className="col-4">
              <div className="stats-winners-img">
                <div className="winners"></div>
              </div>
              <div className="stats-winners-title">{winners.length}</div>
              <div className="stats-winners-text">Winners</div>
            </div>
            <div className="col-4">
              <div className="stats-winners-img">
                <div className="charity"></div>
              </div>
              <div className="stats-winners-title">{parseInt(charity).toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
              <div className="stats-winners-text">Given to charity</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotedRaffles;
