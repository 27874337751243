import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RaffleCard from "../raffles/includes/RaffleCard";

const TopRaffles = () => {
  const [loading, setLoading] = useState(true);
  const [popularRaffles, setPopularRaffles] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffles/byCategory?category=Popular&limit=6`).then(
      ({ data }) => {
        setPopularRaffles(data.raffles);
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="top-raffles">
          <h1 className="title">Popular Competitions</h1>

          {loading ? (
            <LoadingSpinner className="center" />
          ) : (
            <div className="row no-padding gutter">
              {popularRaffles.map((raffle, i) => (
                <div className="col-4" key={i}>
                  <RaffleCard completed={false} {...raffle} />
                </div>
              ))}
              {[popularRaffles % 6].fill().map((_, i) => (
                <div className="col-4" key={i} />
              ))}
            </div>
          )}

          <div className="row gutter">
            <div className="col center">
              <Link to="/all" className="btn">
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRaffles;
