import React from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";

const RafflesFilter = ({ filterBy, onFilterChange, query, onQueryChange }) => {
  return (
    <div className="row no-padding all-raffles-header">
      <div className="col">
        <h1 className="admin-title">Raffles</h1>
      </div>
      <div className="col right">
        <Link to="/admin/raffles/create" className="btn btn-admin new-raffle">
          New
        </Link>

        <div className={`btn-switcher ${filterBy === "active" ? "active-one" : "active-two"}`}>
          {[
            { sort: "active", name: "Active" },
            { sort: "expired", name: "Expired" }
          ].map(({ sort, name }) => (
            <button
              key={name}
              className={filterBy === sort ? "active" : ""}
              onClick={() => onFilterChange(sort)}
            >
              {name}
            </button>
          ))}
        </div>

        <div className="search-bar">
          <input
            type="search"
            value={query}
            placeholder="Search"
            onChange={e => onQueryChange(e.target.value)}
          />
          <button>
            <Search />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RafflesFilter;
