import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";

const OrderRefundModal = ({ orderData, orders, setOrders, closeModal }) => {
  const { orderId } = orderData;
  const { messages } = useContext(AppContext);
  const [refundingProcessing, setRefundingProcessing] = useState(false);

  const onRefund = async () => {
    setRefundingProcessing(true);

    if (
      window.confirm(
        "Are you sure you would like to refund this order? It will make all purchased tickets available again."
      )
    ) {
      try {
        await axios.post(`${process.env.REACT_APP_API}/api/orders/${orderId}/refund`);

        setOrders(
          orders.map((order) =>
            order.orderId === orderId
              ? { ...order, status: "Refunded", ticketsPurchased: [] }
              : order
          )
        );

        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully refunded order" },
        });

        closeModal();
      } catch (e) {
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem refunding this order",
          },
        });
      }
    }

    setRefundingProcessing(false);
  };

  const refundInfo = [
    {
      name: "Subtotal",
      data: orderData.ticketsSubtotal.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      }),
    },
    {
      name: "Return to promo balance",
      data: (-orderData.promotionalBalanceUsed).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      }),
    },
    orderData.couponCodeUsed && {
      name: "Coupon Code Used",
      data: `${orderData.couponCodeUsed} (${(-orderData.couponCodeDiscount).toLocaleString(
        "en-GB",
        {
          style: "currency",
          currency: "GBP",
        }
      )})`,
    },
    {
      name: "Total Refundable to Card",
      data: orderData.totalPricePaid.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      }),
    },
    orderData.trustTransactionRef !== "NO_PAYMENT_REQUIRED" && {
      name: "TRUST Transaction Ref",
      data: orderData.trustTransactionRef,
    },
  ];

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Refund Order {orderId}</h2>

        <div>
          <p style={{ marginBottom: 20 }}>
            {refundInfo.map(
              (item) =>
                item && (
                  <p style={{ marginTop: 15 }}>
                    <strong>{item.name}</strong>:{" "}
                    <span style={{ whiteSpace: "pre-line" }}>{item.data}</span>
                  </p>
                )
            )}
          </p>

          <button className="btn" onClick={onRefund} disabled={refundingProcessing}>
            {refundingProcessing ? <LoadingSpinner /> : "Refund Order"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderRefundModal;
