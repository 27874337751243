import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ChevronDown, ChevronUp } from "react-feather";

const Dropdown = ({ sub, title }) => {
  const ref = useRef(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target) && status) {
        setTimeout(() => setStatus(false), 1);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, [status]);

  return (
    <>
      <Link onClick={() => setStatus(!status)} to="#">
        {title} {status ? <ChevronUp className="nav-chev" /> : <ChevronDown className="nav-chev" />}
      </Link>
      <div className={`dropdown${status ? " open" : ""} hide-mobile`} ref={ref}>
        <ul>
          {sub.map(({ click, title, url }) => (
            <li className="subcategory" key={title}>
              {url ? <Link onClick={() => setStatus(false)} to={url}>{title}</Link> : <Link onClick={click}>{title}</Link>}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
};

export default Dropdown;