import axios from "axios";
import React, { useState, useContext } from "react";
import { Bell, X } from "react-feather";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";

const PersistentMessage = () => {
  const { user, messages } = useContext(AppContext);
  const [show, setShow] = useState(true);
  const [msg] = useState(
    "Welcome to Massive Competitions! Check your email (and junk folder) to verify your account and complete your registration."
  );
  const [resendingEmail, setResendingEmail] = useState(false);

  const resendVerificationEmail = async () => {
    setResendingEmail(true);

    try {
      await axios.post(`${process.env.REACT_APP_API}/api/auth/resend-verification-email`);

      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Account verification email resent" },
      });
      setResendingEmail(false);
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: error.response.data.msg },
      });
      setResendingEmail(false);
    }
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    user.state.isAuthenticated &&
    !user.state.verified &&
    show && (
      <div className="persistent-message">
        <div className="row">
          <div className="col">
            <div className="message-left">
              <Bell />
              <span>{msg}</span>
            </div>
            <div className="message-right">
              <button className="resend-email" onClick={resendVerificationEmail}>
                {resendingEmail ? <LoadingSpinner dark={true} /> : "Resend Email"}
              </button>
              <button onClick={onClose} className="close-btn">
                <X />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PersistentMessage;
