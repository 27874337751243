import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";

const CartCheckoutSummary = ({ subTotal = 0, triggerPopup }) => {
  const history = useHistory();

  const [userPromotionalBalance, setUserPromotionalBalance] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState(false);
  const [usePromotionalBal, setUsePromotionalBal] = useState(localStorage.getItem("usePromoBal") === "true");
  const [newsletter, setNewsletter] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [triggeredPopup, setTriggeredPopup] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/auth/getPromotionalBalance`).then(({ data }) => {
      setUserPromotionalBalance(data.promotionalBalance);
    });
  }, []);

  const applyCoupon = useCallback(
    async (code = false) => {
      setCouponCodeError(false);
      setAppliedCouponCode(false);

      let couponCodeToCheck = (code || couponCode).trim();

      // make request for coupon code here!
      try {
        const { data: couponCodeData } = await Axios.post(
          `${process.env.REACT_APP_API}/api/couponCodes/checkCouponCode`,
          {
            code: couponCodeToCheck,
          }
        );

        localStorage.setItem("appliedCouponCode", couponCodeData.code);
        setAppliedCouponCode(couponCodeData);
      } catch (e) {
        if (e.response && e.response.data && e.response.data.valid === false) {
          setCouponCodeError(e.response.data.msg);
        }

        localStorage.removeItem("appliedCouponCode");
      }
    },
    [couponCode]
  );

  const onClick = () => {
    if (triggeredPopup || !triggerPopup) history.push(`order-payment`);
    else {
      triggerPopup();
      setTriggeredPopup(true);
    }
  };

  const removeCoupon = () => {
    setAppliedCouponCode(false);
    localStorage.removeItem("appliedCouponCode");
  };

  // Whenever cart changes + whenever page loads
  useEffect(() => {
    const couponCodeInSession = localStorage.getItem("appliedCouponCode");

    if (couponCodeInSession) {
      applyCoupon(couponCodeInSession);
    }
  }, [applyCoupon, subTotal]);

  // Whenever promotional balance preference changes store it in localstorage
  useEffect(() => {
    localStorage.setItem("usePromoBal", usePromotionalBal);
  }, [usePromotionalBal]);

  // Whenever newsletter signup changes store in localstorage
  useEffect(() => {
    localStorage.setItem("cartSignupNewsletter", newsletter);
  }, [newsletter]);

  return (
    <div className="cart-checkout row right-cols no-padding">
      <div className="col-4">
        {/* Coupon codes */}
        {!appliedCouponCode && (
          <div className="slate cart-checkout-coupon">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                applyCoupon();
              }}
              className="form-row checkout-coupon-form"
            >
              <label>Coupon Code</label>
              <input
                type="text"
                id="couponCode"
                autoComplete="couponCode"
                placeholder="Enter code"
                className="input checkout-coupon-input"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                className="btn btn-small checkout-coupon-submit"
                disabled={!couponCode.length}
              >
                Apply
              </button>
            </form>

            {couponCodeError && (
              <small className="coupon-code-error text-red">{couponCodeError}</small>
            )}
          </div>
        )}

        {/* Total price & options */}
        <div className="slate cart-checkout-total">
          {appliedCouponCode && (
            <div className="row no-padding text-green">
              <small className="col">
                <span>
                  Discount Code ({appliedCouponCode.code} —
                  {appliedCouponCode.percentDiscount
                    ? ` ${appliedCouponCode.percentDiscount * 100}% OFF`
                    : ` ${appliedCouponCode.fixedDiscount.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })} OFF`}
                  )
                </span>
                <button className="cart-discount-remove-btn" onClick={removeCoupon}>
                  <X />
                </button>
              </small>
              <div className="col right subtotal-price">
                -
                {appliedCouponCode.totalDiscounts.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                })}
              </div>
            </div>
          )}

          <div className="row no-padding">
            <div className="col">Total</div>
            <div className="col right total-price">
              {(appliedCouponCode ? appliedCouponCode.newCartTotal : subTotal).toLocaleString(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              )}
            </div>
          </div>

          <div className="row-no-padding">
            {/* Use Promotional balance yes/no */}
            {!!userPromotionalBalance &&
              (appliedCouponCode ? appliedCouponCode.newCartTotal : subTotal) > 0 && (
                <div className="col terms-and-conditions">
                  <input
                    type="checkbox"
                    id="promo-bal"
                    checked={usePromotionalBal}
                    onChange={(e) => setUsePromotionalBal(e.target.checked)}
                  />
                  <label htmlFor="promo-bal">
                    <span>Use my promotional balance of </span>
                    {userPromotionalBalance.toLocaleString("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })}
                  </label>
                </div>
              )}

            {/* Sign up to newsletter */}
            <div className="col terms-and-conditions">
              <input
                type="checkbox"
                id="newsletter"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
              <label htmlFor="newsletter">
                Sign up for offers, news and more (no spam, promise!)
              </label>
            </div>

            {/* Accept terms (checkout btn won't be clickable until this is selected yes) */}
            <div className="col terms-and-conditions">
              <input
                type="checkbox"
                id="terms"
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
              />
              <label htmlFor="terms">
                <span>I accept the </span>
                <a href="/p/terms-and-conditions" target="_blank">
                  terms
                </a>
                <span> and </span>
                <a href="/p/privacy" target="_blank">
                  privacy policy
                </a>
                <span>.</span>
              </label>
            </div>
          </div>
        </div>

        <button
          className="btn form-btn cart-checkout-btn"
          disabled={!termsChecked}
          onClick={onClick}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default CartCheckoutSummary;
