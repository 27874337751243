import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

const OrderInfoModal = ({ show, orderId, closeModal }) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});

  useEffect(() => {
    const getOrder = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/orders/${orderId}`);
        setOrder(data);
        setLoading(false);
      } catch (error) {}
    };

    getOrder();
  }, [orderId]);

  const {
    status,
    userIp,
    ticketsPurchased = [],
    totalPricePaid,
    ticketsSubtotal,
    promotionalBalanceUsed,
    couponCodeDiscount,
    couponCodeUsed,
    paragonPaymentDetails,
    trustTransactionRef,
    addresses,
    createdAt,
    user
  } = order;

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        {loading ? (
          "Loading"
        ) : (
          <>
            <h2>Order {orderId} Info</h2>

            {[
              { name: "Order ID", data: orderId },
              { name: "Status", data: status },
              { name: "Created At", data: moment(createdAt).format("DD/MM/YYYY HH:mm") },
              { name: "User Email Address", data: user ? user.email : "[No Email Found]" },
              { name: "User IP Address", data: userIp },
              {
                name: `Tickets in order (${ticketsPurchased.length})`,
                data: `\n${ticketsPurchased
                  .map(
                    ({ raffle, ticketNum, pricePaid }) =>
                      `${raffle.name} (#${ticketNum}) - ${pricePaid.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })}\n`
                  )
                  .join("")}`,
              },
              {
                name: "Subtotal",
                data: ticketsSubtotal.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }),
              },
              {
                name: "Promo Balance Used",
                data: (-promotionalBalanceUsed).toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }),
              },
              couponCodeUsed && {
                name: "Coupon Code Used",
                data: `${couponCodeUsed} (${(-couponCodeDiscount).toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                })})`,
              },
              {
                name: "Total Payable To Card",
                data: totalPricePaid.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }),
              },
              trustTransactionRef !== "NO_PAYMENT_REQUIRED" && {
                name: "Payment Ref",
                data: trustTransactionRef || paragonPaymentDetails.orderId,
              },
              addresses &&
                addresses.shipping && {
                  name: "SHIPPING Addr",
                  data: `\n${addresses.shipping.addressLine1},\n${
                    addresses.shipping.addressLine2 ? addresses.shipping.addressLine2 + ",\n" : ""
                  }${addresses.shipping.city ? addresses.shipping.city + ",\n" : ""}${
                    addresses.shipping.county ? addresses.shipping.county + ",\n" : ""
                  }${addresses.shipping.postCode}`,
                },
              addresses &&
                addresses.billing && {
                  name: "BILLING Addr",
                  data: `\n${addresses.billing.addressLine1},\n${
                    addresses.billing.addressLine2 ? addresses.billing.addressLine2 + ",\n" : ""
                  }${addresses.billing.city ? addresses.billing.city + ",\n" : ""}${
                    addresses.billing.county ? addresses.billing.county + ",\n" : ""
                  }${addresses.billing.postCode}`,
                },
            ].map(
              (item) =>
                item && (
                  <p style={{ marginTop: 15 }}>
                    <strong>{item.name}</strong>:{" "}
                    <span style={{ whiteSpace: "pre-line" }}>{item.data}</span>
                  </p>
                )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderInfoModal;
