import React, { useState, useEffect, useContext } from "react";
import LoadingSpinner from "../../../../includes/LoadingSpinner";
import Axios from "axios";
import { AppContext } from "../../../../../context/AppContext";
import { useParams } from "react-router-dom";

const CreateRaffleSale = ({ closeModal }) => {
  const { id: raffleId } = useParams();
  const { messages } = useContext(AppContext);
  const [ticketNum, setTicketNum] = useState("");
  const [user, setUser] = useState({});
  const [showUserFind, setShowUserFind] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [userResults, setUserResults] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/user/find`, { query: userQuery }).then(
      ({ data }) => {
        setUserResults(data.users);
        setLoading(false);
      }
    );
  }, [userQuery]);

  const submit = (e) => {
    e.preventDefault();

    if (!user._id)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "You must select a user" },
      });

    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/create`, {
      ticketNum,
      user: user._id,
      raffle: raffleId,
    })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully added entry" },
        });
        closeModal();
      })
      .catch((err) => {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg },
        });
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Add Entry</h2>

        <form onSubmit={submit}>
          <div className="form-row">
            <label htmlFor="ticketNum">Ticket Number</label>
            <input
              type="text"
              id="ticketNum"
              className="admin-input"
              value={ticketNum}
              onChange={(e) => setTicketNum(e.target.value.toUpperCase())}
              required={true}
            />
          </div>

          <div className="form-row">
            <div onClick={() => setShowUserFind(!showUserFind)}>
              <label>User</label>
              <input
                type="text"
                value={user._id ? `${user.firstName} ${user.lastName}` : undefined}
                className={`admin-input${showUserFind ? " open" : ""}`}
                placeholder="Search for a user"
                disabled={true}
              />
            </div>

            {showUserFind && (
              <div className="find-user-dropdown">
                <input
                  type="search"
                  className="admin-input"
                  value={userQuery}
                  onChange={(e) => setUserQuery(e.target.value)}
                  autoFocus={true}
                />

                {loading ? (
                  <LoadingSpinner className="center" dark={true} />
                ) : (
                  <ul>
                    {!userResults.length ? (
                      <li>No results found</li>
                    ) : (
                      userResults.map(({ _id, firstName, lastName, email }) => (
                        <li
                          onClick={() => {
                            setUser({ _id, firstName, lastName, email });
                            setShowUserFind(false);
                          }}
                        >
                          {firstName} {lastName} ({email})
                        </li>
                      ))
                    )}
                  </ul>
                )}
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-admin">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateRaffleSale;
