import React, { useState, useEffect, useContext } from "react";
import { Edit2, Info, Trash2 } from "react-feather";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import AddEditCouponModal from "./AddEditCouponModal";
import CouponInfoModal from "./CouponInfoModal";

const CouponCodesPage = () => {
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [couponCodes, setCouponCodes] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingCouponData, setEditingCouponData] = useState(false);
  const [showRaffleRestrictionModal, setShowRaffleRestrictionModal] = useState(false);
  const [showUserRestrictionModal, setShowUserRestrictionModal] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/couponCodes`).then(({ data }) => {
      setCouponCodes(data);
      setLoading(false);
    });
  }, []);

  const onAddCoupon = () => {
    setEditingCouponData(false);
    setShowAddEditModal(true);
  };

  const onEditCoupon = (coupon) => {
    setEditingCouponData(coupon);
    setShowAddEditModal(true);
  };

  const onDeleteCoupon = (id) => {
    if (window.confirm("Are you sure you want to delete this coupon code?")) {
      Axios.delete(`${process.env.REACT_APP_API}/api/couponCodes/${id}`)
        .then(() => {
          setCouponCodes(couponCodes.filter(({ _id: couponId }) => couponId !== id));
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem deleting that coupon code" },
          });
        });
    }
  };

  return (
    <div className="row">
      <div className="col">
        {showAddEditModal && (
          <AddEditCouponModal
            closeModal={() => setShowAddEditModal(false)}
            editingCoupon={editingCouponData}
            afterAdd={(newCoupon) => setCouponCodes([newCoupon, ...couponCodes])}
            afterEdit={(updatedCoupon) =>
              setCouponCodes(
                couponCodes.map((coupon) =>
                  coupon._id === updatedCoupon._id ? updatedCoupon : coupon
                )
              )
            }
          />
        )}

        {showRaffleRestrictionModal && (
          <CouponInfoModal
            data={showRaffleRestrictionModal}
            title="Only Applies to Raffles:"
            closeModal={() => setShowRaffleRestrictionModal(false)}
          />
        )}
        {showUserRestrictionModal && (
          <CouponInfoModal
            data={showUserRestrictionModal}
            title="Only Applies to Users:"
            closeModal={() => setShowUserRestrictionModal(false)}
          />
        )}

        {/* Title */}
        <div className="row no-padding">
          <div className="col">
            <h1 className="admin-title">Coupon Codes</h1>
          </div>
          <div className="col right">
            <button className="btn btn-admin" onClick={onAddCoupon}>
              Add New
            </button>
          </div>
        </div>

        {/* Table */}
        <table className="raffles-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Expires</th>
              <th>Restrictions</th>
              <th>Used/Remaining</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6">
                  <LoadingSpinner dark={true} className="center" />
                </td>
              </tr>
            ) : !couponCodes.length ? (
              <tr>
                <td colSpan="6" className="no-raffles">
                  No coupon codes to show. Create one using the "Add new" button above!
                </td>
              </tr>
            ) : (
              couponCodes.map((coupon) => (
                <tr key={coupon._id}>
                  <td>{coupon.code}</td>
                  <td>
                    {coupon.type === "fixed"
                      ? "Fixed Discount"
                      : coupon.type === "percentage"
                      ? "Percentage Discount"
                      : "Account Credit"}
                  </td>
                  <td>
                    {coupon.type === "percentage"
                      ? `${coupon.percentAmount * 100}% off`
                      : `${coupon.fixedAmount.toLocaleString("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        })}`}
                  </td>
                  <td
                    className={
                      coupon.expires && moment().isAfter(moment(coupon.exp)) && "coupon-expired"
                    }
                  >
                    {coupon.expires ? moment(coupon.exp).format("DD MMM YYYY HH:mm") : "No expiry"}
                  </td>
                  <td>
                    {!coupon.allRaffles && (
                      <div>
                        {coupon.appliesToRaffles.length} Raffles
                        <button
                          onClick={() => setShowRaffleRestrictionModal(coupon.appliesToRaffles)}
                          className="coupon-info-tooltip"
                          data-tip="View Restrictions"
                        >
                          <Info />
                        </button>
                        <ReactTooltip className="tooltip" />
                      </div>
                    )}
                    {!coupon.allUsers && (
                      <div>
                        {coupon.appliesToUsers.length} Users
                        <button
                          onClick={() =>
                            setShowUserRestrictionModal(
                              coupon.appliesToUsers.map(({ firstName, lastName }) => ({
                                name: `${firstName} ${lastName}`,
                              }))
                            )
                          }
                          className="coupon-info-tooltip"
                          data-tip="View Restrictions"
                        >
                          <Info />
                        </button>
                        <ReactTooltip className="tooltip" />
                      </div>
                    )}
                    {coupon.allUsers && coupon.allRaffles && "None"}
                  </td>
                  <td>{`${coupon.uses}/${coupon.maxUses || "Unlimited"}`}</td>
                  <td className="right">
                    <button
                      className="delete-btn"
                      onClick={() => onEditCoupon(coupon)}
                      data-tip="Edit"
                    >
                      <Edit2 />
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => onDeleteCoupon(coupon._id)}
                      data-tip="Delete"
                    >
                      <Trash2 />
                    </button>
                  </td>
                  <ReactTooltip className="tooltip" />
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponCodesPage;
