import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import CartItem from "../../cart/includes/CartItem";
import { Link, useHistory } from "react-router-dom";
import { Edit } from "react-feather";
import moment from "moment";

const OrderCartSummary = ({
  tickets = [],
  subtotal = 0,
  couponDiscount = 0,
  promoBalDiscount = 0,
  orderTotal = 0,
}) => {
  const { messages } = useContext(AppContext);
  const history = useHistory();
  const [currentTime, setCurrentTime] = useState(moment());

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!tickets.length) return;

    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [tickets.length]);

  const onTicketExpiry = () => {
    messages.dispatch({
      type: "send",
      payload: {
        type: "error",
        msg: "One of your tickets has expired. You will need to add them to your cart again.",
      },
    });

    return history.push("/");
  };

  return (
    <div>
      <div className="slate checkout-summary">
        <div className="checkout-summary-header">
          <h3>Summary</h3>
          <Link className="edit-btn" to="/cart">
            <Edit /> Edit
          </Link>
        </div>

        {!tickets.length ? (
          <p>Your cart is empty</p>
        ) : (
          tickets.map((ticket, i) => (
            <CartItem
              key={i}
              currentTime={currentTime}
              ticket={ticket}
              removeTicket={onTicketExpiry}
              flashWhenExpired={true}
              minimal={true}
            />
          ))
        )}

        <div className="checkout-summary-totals">
          <div className="summary-subtotal">
            <div className="summary-label">Subtotal</div>
            <div className="summary-amount">
              {subtotal.toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP",
              })}
            </div>
          </div>

          {!!couponDiscount && (
            <div className="summary-subtotal">
              <div className="summary-label">Coupon Discount</div>
              <div className="summary-amount">
                -
                {couponDiscount.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                })}
              </div>
            </div>
          )}

          {!!promoBalDiscount && (
            <div className="summary-subtotal">
              <div className="summary-label">Promotional Balance</div>
              <div className="summary-amount">
                -
                {promoBalDiscount.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP",
                })}
              </div>
            </div>
          )}

          <div className="summary-total">
            <div className="summary-label">Order Total</div>
            <div className="summary-amount">
              {orderTotal.toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP",
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCartSummary;
