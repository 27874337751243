import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../../context/AppContext";
import LoadingSpinner from "../../../../includes/LoadingSpinner";
import PaymentMethod from "../includes/PaymentMethod";

const PaymentMethods = () => {
  const { messages } = useContext(AppContext);

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => updateCards(), []);

  const deleteCard = (card) => {
    if (window.confirm(`Are you sure you would like to delete your saved card ending in ${card.lastFour}?`)) {
      Axios.delete(`${process.env.REACT_APP_API}/api/card/${card._id}`).then(({ data }) => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: data.msg } });
        updateCards();
      }).catch((err) => {
        messages.dispatch({ type: "send", payload: { type: "error", msg: err.response.data.msg } });
      });
    }
  };

  const updateCards = () => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API}/api/card/all`).then(({ data }) => {
      if (data.cards && data.cards.length > 0) {
        setCards(data.cards.map((card) => card));
      } else {
        setCards([]);
      }

      setLoading(false);
    });
  };

  return (
    <div>
      <div className="user-account-main-header">
        <h1>
          <Link to="/account/settings" className="back-btn">
            <ArrowLeft />
          </Link>
          Payment Methods
        </h1>
      </div>
      <div>
      {(loading ? (
          <LoadingSpinner className="center" />
        ) : !cards.length ? (
          <p>You do not have any cards saved.</p>
        ) : (
          <div>
            {cards.map((card) => (
              <PaymentMethod key={card._id} card={card} deleteCard={deleteCard} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;