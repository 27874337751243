import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const InstantWin = ({ createdAt, order, prize, raffle, ticketNum }) => {
  let { name, slug } = raffle;

  return (
    <div className="cart-item-container">
      <div className="cart-item">
        <div className="cart-item-meta">
          <div className="cart-item-name">
            <span className="prize">{prize}</span>
          </div>
        </div>
        <div className="cart-item-pricing">
          <div className="instant-win-meta">
            <div className="ticket-info">
              Ticket <span className="ticket-number">#{ticketNum}</span>
            </div>
            <div className="ticket-info name">
              <Link to={`/c/${slug}`} className="raffle">{name}</Link>
            </div>
          </div>

          <div className="ticket-order-seperator"></div>

          <div className="date-and-order">
            {moment(createdAt).format("DD/MM/YYYY")}
          </div>

          <div className="ticket-order-seperator"></div>

          <div className="date-and-order">
            {order.orderId}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantWin;
