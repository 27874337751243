import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { ChevronDown, ChevronUp } from "react-feather";
import ReactMarkdown from "react-markdown";

const FaqPage = () => {
  const { config } = useContext(AppContext);
  const [faq, setFaq] = useState(false);
  const [tabOpen, setTabOpen] = useState(false);

  useEffect(() => {
    setFaq(config.state.find(({ type }) => type === "faq"));
  }, [config.state]);

  const openTab = (index) => {
    setTabOpen(tabOpen !== index ? index : false);
  };

  return (
    <div className="row">
      <div className="col">
        <h1 className="title">FAQ</h1>

        <div className="faqs row no-padding gutter">
          {faq &&
            faq.faq.map(({ question, answer }, i) => (
              <div key={i} className="col-6">
                <div className={`faq ${tabOpen === i ? "open" : ""}`}>
                  <div onClick={() => openTab(i)} className="faq-question">
                    {question}
                    {tabOpen === i ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  <div className="faq-answer">
                    <ReactMarkdown className="md-text" linkTarget="_blank" children={answer} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
