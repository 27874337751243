import React from "react";

const CouponInfoModal = ({ data, title, closeModal }) => {
  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>{title}</h2>

        <ul>
          {data.map(({ name }) => (
            <li>{name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CouponInfoModal;
