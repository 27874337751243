import React, { useState, useContext, useEffect } from "react";
import { Edit2, Trash2 } from "react-feather";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";

const ConfigFaq = ({ faq = {} }) => {
  const { messages } = useContext(AppContext);

  const [faqs, setFaqs] = useState(faq.faq || []);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [editingItem, setEditingItem] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    sendUpdate(faq._id, faqs);
  }, [faq._id, faqs]);

  useEffect(() => {
    if (editingItem < 0)
      return;
    else if (editingItem >= faqs.length) {
      setEditingItem(-1);
      return;
    }

    const faq = faqs[editingItem];

    setQuestion(faq.question);
    setAnswer(faq.answer);
  }, [editingItem, faqs]);

  useEffect(() => {
    setQuestion("");
    setAnswer("");
  }, [showAddModal]);

  const addItem = (e) => {
    e.preventDefault();

    if (!question || !answer)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    if (editingItem > -1) {
      const editing = { ...faqs[editingItem] };
      editing.question = question;
      editing.answer = answer;

      faqs[editingItem] = editing;

      setFaqs(faqs);
      setEditingItem(-1);

      messages.dispatch({ type: "send", payload: { type: "success", msg: "Successfully edited FAQ!" } });
      sendUpdate(faq._id, faqs);
    } else {
      const newFAQs = [{ question, answer }, ...faqs];

      setFaqs(newFAQs);
      setShowAddModal(false);
    }
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this FAQ?")) {
      const newFAQs = faqs.filter((_, i) => i !== index);
      setFaqs(newFAQs);
    }
  };

  const sendUpdate = (_id, faqs) => Axios.post(`${process.env.REACT_APP_API}/api/config/${_id}`, { faq: faqs });

  return (
    <div>
      {editingItem > -1 && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setEditingItem(-1)}></div>
          <div className="modal">
            <h2>Edit FAQ</h2>

            <form onSubmit={addItem}>
              <div className="form-row">
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  id="question"
                  className="admin-input"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>

              <div className="form-row">
                <label htmlFor="answer">Answer</label>
                <textarea
                  id="answer"
                  className="admin-input"
                  rows="5"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-admin">
                Edit
              </button>
            </form>
          </div>
        </div>
      )}

      {showAddModal && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
          <div className="modal">
            <h2>Add FAQ</h2>

            <form onSubmit={addItem}>
              <div className="form-row">
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  id="question"
                  className="admin-input"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>

              <div className="form-row">
                <label htmlFor="answer">Answer</label>
                <textarea
                  id="answer"
                  className="admin-input"
                  rows="5"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-admin">
                Add
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">Faq</h2>

        <div>
          {/* Table */}
          <table className="raffles-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!faqs.length ? (
                <tr>
                  <td colSpan="4" className="no-raffles">
                    No FAQs to show
                  </td>
                </tr>
              ) : (
                faqs.map(({ question, answer }, i) => (
                  <tr key={i}>
                    <td>{question}</td>
                    <td>{answer}</td>
                    <td className="right">
                      <button className="delete-btn" onClick={() => setEditingItem(i)}>
                        <Edit2 />
                      </button>
                      <button className="delete-btn" onClick={() => deleteItem(i)}>
                        <Trash2 />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <button
            onClick={() => setShowAddModal(true)}
            className="btn btn-admin btn-small"
            style={{ marginTop: 10 }}
          >
            Add New
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigFaq;
