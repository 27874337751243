import React from "react";
import ReactMarkdown from "react-markdown";

const RaffleDesc = ({ desc }) => {
  return (
    <div className="admin-slate">
      <h2>Description</h2>
      <ReactMarkdown
        className="md-text"
        linkTarget="_blank"
        children={desc || "No description set"}
      />
    </div>
  );
};

export default RaffleDesc;
