import React, { useContext, useState } from "react";
import { X } from "react-feather";
import { AppContext } from "../../../../context/AppContext";
import Slider from "react-slider";

const RaffleSelectAmount = ({ afterSelected, maxTickets, onClose }) => {
  const { messages } = useContext(AppContext);
  const [amount, setAmount] = useState(1);

  const onSubmit = () => {
    if (amount < 1) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "You have selected an invalid amount of tickets!" },
      });
      return;
    }

    afterSelected(amount);
    onClose();
  };

  return (
    <div className="raffle-answer-question">
      <button className="close-btn" onClick={onClose}>
        <X />
      </button>
      <h2>How many tickets would you like?</h2>

      <div className="form-row">
        <label className="raffle-selectans">Select Amount Of Tickets</label>
        <div style={{ width: "90%", margin: "0 auto" }}>
          <Slider
            className="lucky-dip-slider"
            thumbClassName="lucky-dip-bubble"
            trackClassName="lucky-dip-slider-track"
            renderThumb={(props, state) => {
              return (<div {...props}>
                <p className="lucky-dip-bubble-text">{state.value}</p>
              </div>) }
            }
            min={1}
            max={maxTickets}
            onChange={(value) => setAmount(value)}
            value={amount}
          />
        </div>

        <button type="button" onClick={onSubmit} className="btn">
          Continue
        </button>
      </div>
    </div>
  );
};

export default RaffleSelectAmount;
