import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import RaffleCard from "./includes/RaffleCard";
import LoadingSpinner from "../../includes/LoadingSpinner";
import { ChevronDown, Check } from "react-feather";

const CategoryRaffles = ({ categoryName, raffles }) => {
  const [showSort, setShowSort] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortedRaffles, setSortedRaffles] = useState(raffles);

  const sortBy = (type) => {
    let sorted;

    switch (type) {
      case "Lowest Price": {
        sorted = raffles.sort((a, b) => a.price - b.price);
        break;
      }
      case "Highest Price": {
        sorted = raffles.sort((a, b) => b.price - a.price);
        break;
      }
      case "Ending Soonest": {
        sorted = raffles.sort((a, b) => moment(a.endDate).toDate() - moment(b.endDate).toDate());
        break;
      }
      default: {
        sorted = raffles;
        break;
      }
    }

    setSortedRaffles(sorted);
    setShowSort(false);
    setSort(type);
  };

  return (
    <div className="row">
      <div className="col">
        <div className="row no-padding">
          <div className="col">
            <h1 className="title">{categoryName}</h1>
          </div>
          <div className="col right">
            <div className="sort-dropdown-container">
              <button className="btn btn-white" onClick={() => setShowSort(!showSort)}>
                {sort ? `Sorted: ${sort}` : "Sort"} <ChevronDown />
              </button>
              {showSort && (
                <div className="sort-dropdown">
                  <ul>
                    <li onClick={() => sortBy("Lowest Price")}>
                      {sort === "Lowest Price" && <Check />} Lowest Price
                    </li>
                    <li onClick={() => sortBy("Highest Price")}>
                      {sort === "Highest Price" && <Check />} Highest Price
                    </li>
                    <li onClick={() => sortBy("Ending Soonest")}>
                      {sort === "Ending Soonest" && <Check />} Ending Soonest
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row no-padding gutter">
          {sortedRaffles.map((raffle, i) => (
            <div className="col-4" key={i}>
              <RaffleCard completed={false} {...raffle} />
            </div>
          ))}
          {[sortedRaffles % 3].fill().map((_, i) => (
            <div className="col-4" key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

const AllRafflesPage = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [allRaffles, setAllRaffles] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/categories/all`).then(({ data }) => {
      const categories = [];
      const names = [];

      data.categories.forEach(({ name }) => {
        names.push(name);
      });
      
      Promise.all([
        Axios.get(`${process.env.REACT_APP_API}/api/raffles/all`).then(({ data }) => {
          setAllRaffles(data.raffles);
        }),
        Axios.get(`${process.env.REACT_APP_API}/api/raffles/byCategory`).then(({ data }) => {
          names.forEach(name => {
            const categoryData = data.raffles.find(({ _id }) => _id === name);

            if (categoryData)
              categories.push(categoryData);
          });

          setCategories(categories);
        })
      ]).then(() => setLoading(false));
    });
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner className="center" />
      ) : (
        <>
          {/* Ending Soon */}
          {categories
            .filter(({ _id }) => _id === "Ending Soon")
            .map(({ _id, raffles }) => (
              <CategoryRaffles key={_id} categoryName={_id} raffles={raffles} />
            ))}

          {/* Other categories */}
          {categories
            .filter(({ _id }) => _id !== "Ending Soon" && _id !== "Popular")
            .map(({ _id, raffles }) => (
              <CategoryRaffles key={_id} categoryName={_id} raffles={raffles} />
            ))}

          {/* All raffles */}
          <CategoryRaffles categoryName="All Competitions" raffles={allRaffles} />
        </>
      )}
    </div>
  );
};

export default AllRafflesPage;
