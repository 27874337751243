import React from "react";

const LoadingSpinner = ({ style, className, dark }) => {
  return (
    <div style={style} className={className}>
      <div className={`lds-ellipsis ${dark ? "dark" : ""}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
