import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp } from "react-feather";

const TicketItem = ({ currentTime, raffle, tickets }) => {
  let { slug, name, endDate } = raffle;
  const [showTicketNums, setShowTicketNums] = useState(false);

  const timeRemaining = moment.duration(
    (moment(endDate).unix() - moment(currentTime).unix()) * 1000,
    "milliseconds"
  )._milliseconds;

  const raffleImage = raffle.images.find(({ keyImage }) => keyImage)
    ? `url(${raffle.images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  return (
    raffle && (
      <div className="cart-item-container">
        <div className="cart-item">
          <div className="cart-item-meta">
            <div className="cart-item-img" style={{ backgroundImage: raffleImage }}></div>
            <div className="cart-item-name">
              <Link to={`/c/${slug}`} className="cart-item-name">
                {name}
              </Link>
            </div>
          </div>
          <div className="cart-item-pricing">
            <div onClick={() => setShowTicketNums(!showTicketNums)} className="account-tag clickable">
              {`${tickets.length} tickets`}&nbsp;
              {showTicketNums ? <ChevronUp size={13} /> : <ChevronDown size={13} />}
            </div>

            <div className="ticket-order-price">
              {raffle.price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
            </div>
            
            <div className="ticket-order-seperator"></div>

            <div className="countdown-numbers">
              <div className="countdown-number">
                {timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0}
                <span>Days</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0}
                <span>Hours</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0}
                <span>Mins</span>
              </div>
              <div className="countdown-number">
                {timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0}
                <span>Secs</span>
              </div>
            </div>
          </div>
        </div>

        <div className="cart-item-tickets" style={{ maxHeight: showTicketNums ? 1000 : 0 }}>
          <div>
            {tickets.map(({ ticketNum }) => (
              <span key={ticketNum}>{ticketNum}</span>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default TicketItem;
