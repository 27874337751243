import React from "react";
import moment from "moment";
import { X } from "react-feather";

const PaymentMethod = ({ card, deleteCard }) => {
  return (
    <div className="order-item-container">
      <div className="order-item">
        <div className="order-item-details">
          <div className="order-item-detail">
            <div className="detail-header">Card Details</div>
            <div className="detail-body">{card.type} (x-{card.lastFour})</div>
          </div>

          <div className="order-item-detail">
            <div className="detail-header">Added On</div>
            <div className="detail-body">{moment(card.createdAt).format("DD/MM/YYYY HH:mm")}</div>
          </div>
        </div>

        <div className="order-item-status-total">
          <button className="order-item-expcoll" onClick={() => deleteCard(card)}>
            <X />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;