import React, { useState } from "react";
import AdminUsers from "./AdminUsers";
import RegularUsers from "./RegularUsers";

const UsersPage = () => {
  const [lastUserUpdated, setLastUserUpdated] = useState(false);

  return (
    <div>
      <AdminUsers lastUserUpdated={lastUserUpdated} setLastUserUpdated={setLastUserUpdated} />
      <br />
      <br />
      <br />
      <RegularUsers lastUserUpdated={lastUserUpdated} setLastUserUpdated={setLastUserUpdated} />
    </div>
  );
};

export default UsersPage;
