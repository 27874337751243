import React, { useReducer, createContext } from "react";
import configReducer from "../reducers/configReducer";
import messagesReducer from "../reducers/messagesReducer";
import usersReducer from "../reducers/usersReducer";

export const AppContext = createContext();

export const WithAppContext = ({ children }) => {
  const [configState, configDispatch] = useReducer(configReducer, []);
  const [messagesState, messagesDispatch] = useReducer(messagesReducer, {});
  const [userState, userDispatch] = useReducer(usersReducer, { isAuthenticated: false });

  const providerValue = {
    config: { state: configState, dispatch: configDispatch },
    messages: { state: messagesState, dispatch: messagesDispatch },
    user: { state: userState, dispatch: userDispatch },
  };

  return <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>;
};
