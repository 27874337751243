import React from "react";
import { Check } from "react-feather";

const RaffleTicketsVideo = ({
  luckyDipDefault,
  maxEntries,
  maxEntriesPerPerson,
  ticketMatrix,
  question,
  answers = [],
  video,
  allowPromoBalance,
  luckyDipsOnly,
  guaranteedWinnerInfoBox,
}) => {
  return (
    <div className="row no-padding gutter">
      <div className="col-6">
        <div className="admin-slate">
          <h2>Ticket Configuration</h2>
          <p>
            <strong>Lucky Dip Default Amount:</strong> {luckyDipDefault || 0}
          </p>
          <p>
            <strong>Max Tickets</strong>: {maxEntries} / <strong>Per Person</strong>:{" "}
            {maxEntriesPerPerson}
          </p>
          <p>
            <strong>Use Letters</strong>: {ticketMatrix.useLetters ? "Yes" : "No"}
          </p>
          {ticketMatrix.toLetter && (
            <p>
              <strong>Letter Range</strong>: A-{ticketMatrix.toLetter}
            </p>
          )}
          <p>
            <strong>Tickets Per Letter</strong>: {ticketMatrix.ticketsPerLetter}
          </p>
          <p>
            <strong>Allow Promotional Balance</strong>:{" "}
            {allowPromoBalance ? "Yes" : "No"}
          </p>
          <p>
            <strong>Only allow lucky dips</strong>:{" "}
            {luckyDipsOnly ? "Yes" : "No"}
          </p>
          <p>
            <strong>Show Guaranteed Winner Info Box</strong>:{" "}
            {guaranteedWinnerInfoBox ? "Yes" : "No"}
          </p>
        </div>

        {question && answers.length && (
          <div className="admin-slate">
            <h2>Security Question</h2>

            <div className="form-row">
              <label htmlFor="question">Question</label>
              {question}
            </div>

            <div className="form-row">
              <label htmlFor="answers">Answers</label>
              <ol>
                {answers.map(({ answer, correct }, i) => (
                  <li>
                    <span className="inline-block">
                      {i + 1}. {answer}
                    </span>{" "}
                    {correct && <Check width="20px" className="inline-block" />}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        )}
      </div>
      <div className="col-6">
        <div className="admin-slate">
          <h2>Video Stream Test</h2>
          <iframe title="video" src={video} frameBorder="0" width="100%" height="300" />
        </div>
      </div>
    </div>
  );
};

export default RaffleTicketsVideo;
