import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

const FeaturedWinner = ({ featuredWinner }) => {
  return (
    <div className="cta-slab">
      <div className="row gutter">
        <div className="col-6">
          <div
            className="cta-image"
            style={{
              backgroundImage: `url(${featuredWinner.image})`,
            }}
          ></div>
        </div>
        <div className="col-6">
          <div className="title">{featuredWinner.header || "Featured Winner"}</div>
          <ReactMarkdown className="md-text" linkTarget="_blank" children={featuredWinner.text} />
          <Link to="/all" className="btn">
            Enter Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedWinner;
