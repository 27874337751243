import React from "react";
import moment from "moment";
import { ChevronLeft, ChevronRight, Edit2, Trash2 } from "react-feather";
import LoadingSpinner from "../../../../includes/LoadingSpinner";

const ConfigWinnersTable = ({ winners = [], deleteItem, editItem, loading, page, setPage, totalPages }) => {
  const next = () => setPage(page + 1);
  const prev = () => setPage(page - 1);

  return (
    <div>
      {/* Table */}
      <table className="config-table">
        <thead>
          <tr>
            <th style={{ width: "250px" }}>Image</th>
            <th>Winner</th>
            <th>Raffle</th>
            <th>Draw Date</th>
            <th>Ticket Number</th>
            <th>Live Draw</th>
            <th>Online Raffle URL</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="8" className="no-raffles">
                <LoadingSpinner dark={true} />
              </td>
            </tr>
          ) : (
            !winners || !winners.length ? (
            <tr>
              <td colSpan="8" className="no-raffles">
                No winners to show
              </td>
            </tr>
            ) : (
              winners.map(({ name, raffle, drawDate, image, liveDrawUrl, onlineRaffleUrl, ticketNumber }, i) => (
                <tr key={i}>
                  <td>
                    <img src={`${image}`} alt="Raffle" className="raffle-table-image" />
                  </td>
                  <td>{name}</td>
                  <td>{raffle}</td>
                  <td>{moment(drawDate).format("DD/MM/YYYY (HH:mm)")}</td>
                  <td>{ticketNumber}</td>
                  <td>
                    <a rel="noopener noreferrer" target="_blank" href={liveDrawUrl}>
                      {liveDrawUrl}
                    </a>
                  </td>
                  <td>
                    <a rel="noopener noreferrer" target="_blank" href={onlineRaffleUrl}>
                      {onlineRaffleUrl}
                    </a>
                  </td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => editItem(i)}>
                      <Edit2 />
                    </button>
                    <button className="delete-btn" onClick={() => deleteItem(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )
          )}
        </tbody>
      </table>

      <div className="raffles-paginate">
        <button onClick={prev} disabled={page <= 0} className="btn btn-admin btn-paginate">
          <ChevronLeft />
        </button>
        <button
          onClick={next}
          disabled={page >= totalPages}
          className="btn btn-admin btn-paginate"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ConfigWinnersTable;