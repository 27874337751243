import React, { useState, useContext } from "react";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";

const AddCategoryModal = ({ closeModal, categoryAdded }) => {
  const { messages } = useContext(AppContext);
  const [name, setName] = useState("");

  const submit = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/categories`, { name })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully created category" },
        });
        categoryAdded(name);
        closeModal();
      })
      .catch((err) => {
        if (err.response.data && err.response.data.msg) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Add Category</h2>
        <form onSubmit={(e) => submit(e)}>
          <div className="form-row">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="admin-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus={true}
            />
          </div>
          <button type="submit" className="btn btn-admin">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCategoryModal;
