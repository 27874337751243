import React, { useContext, useState } from "react";
import Axios from "axios";
import { Trash2, ChevronLeft, ChevronRight, Info } from "react-feather";
import ReactTooltip from "react-tooltip";
import LoadingSpinner from "../../../../includes/LoadingSpinner";
import { AppContext } from "../../../../../context/AppContext";
import OrderInfoModal from "../../../orders/includes/OrderInfoModal";

const RaffleSalesTable = ({ loading, tickets = [], page, setPage, totalPages, ticketDeleted }) => {
  const { messages } = useContext(AppContext);
  const [showOrderInfoModal, setShowOrderInfoModal] = useState(false);

  const prev = () => setPage(page - 1);
  const next = () => setPage(page + 1);

  const deleteTicket = (id) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      Axios.delete(`${process.env.REACT_APP_API}/api/raffleTickets/purchase/${id}`)
        .then(() => {
          ticketDeleted(id);
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully deleted entry" },
          });
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: {
              type: "error",
              msg: "There was a problem deleting that entry",
            },
          });
        });
    }
  };

  return (
    <div>
      {showOrderInfoModal && (
        <OrderInfoModal
          show={!!showOrderInfoModal}
          orderId={showOrderInfoModal}
          closeModal={() => setShowOrderInfoModal(false)}
        />
      )}

      <table className="raffles-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Ticket Number</th>
            <th>Question Answer</th>
            <th>Price Paid</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <td colSpan="5">
              <LoadingSpinner className="center" dark={true} />
            </td>
          ) : !tickets.length ? (
            <tr>
              <td colSpan="5" className="no-raffles">
                No entries to show
              </td>
            </tr>
          ) : (
            tickets.map((ticket) => (
              <tr key={ticket._id}>
                <td>
                  {ticket.orderId || <em>[Manual Entry]</em>}
                  {ticket.orderId && (
                    <button
                      className="delete-btn"
                      onClick={() => setShowOrderInfoModal(ticket.orderId)}
                      data-tip="Order Info"
                    >
                      <Info />
                    </button>
                  )}
                </td>
                <td>
                  {ticket.user ? (
                    `${ticket.user.firstName} ${ticket.user.lastName}`
                  ) : (
                    <em>[Deleted User]</em>
                  )}
                </td>
                <td>{ticket.user ? ticket.user.email : <em>[Deleted User]</em>}</td>
                <td>{ticket.ticketNum}</td>
                <td>{ticket.questionAnswer === "MANUAL_ENTRY" ? "-" : ticket.questionAnswer}</td>
                <td>
                  {ticket.pricePaid.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </td>
                <td className="right">
                  <button
                    className="delete-btn"
                    onClick={() => deleteTicket(ticket._id)}
                    data-tip="Delete"
                  >
                    <Trash2 />
                  </button>
                </td>
                <ReactTooltip className="tooltip" />
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="raffles-paginate">
        <button onClick={prev} disabled={page === 1} className="btn btn-admin btn-paginate">
          <ChevronLeft />
        </button>
        <button
          onClick={next}
          disabled={page === totalPages}
          className="btn btn-admin btn-paginate"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default RaffleSalesTable;
