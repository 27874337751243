import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RafflesFilter from "./RafflesFilter";
import RafflesTable from "./RafflesTable";
import Axios from "axios";

const AllRafflesPage = () => {
  const [loading, setLoading] = useState(true);
  const [filterBy, setFilterBy] = useState("active");
  const [query, setQuery] = useState("");
  const [raffles, setRaffles] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/raffles/adminBrowse`, {
      query,
      filterBy,
      skip: (page - 1) * limit,
      limit,
    }).then(({ data }) => {
      setRaffles(data.raffles);
      setTotalPages(Math.ceil(data.totalRaffles / limit));
      setLoading(false);
    });
  }, [limit, page, query, filterBy]);

  return (
    <div className="row all-raffles">
      <div className="col">
        <RafflesFilter
          filterBy={filterBy}
          onFilterChange={setFilterBy}
          query={query}
          onQueryChange={(q) => {
            setPage(1);
            setQuery(q);
          }}
        />

        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <RafflesTable
            raffles={raffles}
            updateRaffles={setRaffles}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default AllRafflesPage;
