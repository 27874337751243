import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Axios from "axios";
import moment from "moment";
import RafflesRestriction from "../includes/RafflesRestriction";
import UsersRestriction from "../includes/UsersRestriction";

const AddEditCouponModal = ({ closeModal, editingCoupon, afterAdd, afterEdit }) => {
  const { messages } = useContext(AppContext);
  const [code, setCode] = useState("");
  const [type, setType] = useState("fixed");
  const [fixedAmount, setFixedAmount] = useState(0);
  const [percentAmount, setPercentAmount] = useState(0);
  const [shouldExpire, setShouldExpire] = useState(true);
  const [exp, setExp] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [unlimitedUses, setUnlimitedUses] = useState(true);
  const [maxUses, setMaxUses] = useState(0);
  const [allRaffles, setAllRaffles] = useState(true);
  const [allUsers, setAllUsers] = useState(true);
  const [appliesToRaffles, setAppliesToRaffles] = useState([]);
  const [appliesToUsers, setAppliesToUsers] = useState([]);
  const [firstOrder, setFirstOrder] = useState(false);
  const [newsletterOnly, setNewsletterOnly] = useState(false);
  const [oncePerUser, setOncePerUser] = useState(true);

  useEffect(() => {
    setCode(editingCoupon ? editingCoupon.code : "");
    setType(editingCoupon ? editingCoupon.type : "fixed");
    setFixedAmount(editingCoupon ? editingCoupon.fixedAmount : 0);
    setPercentAmount(
      editingCoupon && editingCoupon.percentAmount ? editingCoupon.percentAmount * 100 : 0
    );
    setShouldExpire(editingCoupon ? editingCoupon.expires : true);
    setExp(
      editingCoupon
        ? moment(editingCoupon.exp).format("YYYY-MM-DDTHH:mm")
        : moment().format("YYYY-MM-DDTHH:mm")
    );
    setUnlimitedUses(editingCoupon && editingCoupon.maxUses ? false : true);
    setMaxUses(editingCoupon ? editingCoupon.maxUses : 0);
    setAllRaffles(editingCoupon ? editingCoupon.allRaffles : true);
    setAppliesToRaffles(editingCoupon ? editingCoupon.appliesToRaffles : []);
    setAllUsers(editingCoupon ? editingCoupon.allUsers : true);
    setAppliesToUsers(editingCoupon ? editingCoupon.appliesToUsers : []);
    setFirstOrder(editingCoupon ? editingCoupon.onlyUsersFirstOrder : false);
    setNewsletterOnly(editingCoupon ? editingCoupon.onlyUsersOnNewsletter : false);
    setOncePerUser(editingCoupon ? editingCoupon.oncePerUser : true);
  }, [editingCoupon]);

  const submit = async (e) => {
    e.preventDefault();

    try {
      if (editingCoupon) {
        const { data } = await Axios.put(
          `${process.env.REACT_APP_API}/api/couponCodes/${editingCoupon._id}`,
          {
            code,
            type,
            fixedAmount,
            percentAmount: percentAmount / 100,
            expires: shouldExpire,
            exp,
            maxUses: !unlimitedUses ? maxUses : null,
            allRaffles,
            appliesToRaffles: appliesToRaffles.map(({ _id }) => _id),
            allUsers,
            appliesToUsers: appliesToUsers.map(({ _id }) => _id),
            onlyUsersFirstOrder: firstOrder,
            onlyUsersOnNewsletter: newsletterOnly,
            oncePerUser,
          }
        );

        afterEdit(data.updatedCoupon);
        closeModal();
      } else {
        const { data } = await Axios.post(`${process.env.REACT_APP_API}/api/couponCodes`, {
          code,
          type,
          fixedAmount,
          percentAmount: percentAmount / 100,
          expires: shouldExpire,
          exp,
          maxUses: !unlimitedUses ? maxUses : null,
          allRaffles,
          appliesToRaffles: appliesToRaffles.map(({ _id }) => _id),
          allUsers,
          appliesToUsers: appliesToUsers.map(({ _id }) => _id),
          onlyUsersFirstOrder: firstOrder,
          onlyUsersOnNewsletter: newsletterOnly,
          oncePerUser,
        });

        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully created coupon" },
        });

        afterAdd(data.coupon);
        closeModal();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg },
        });
      }
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>{editingCoupon ? "Edit" : "Add"} Coupon Code</h2>

        <form onSubmit={(e) => submit(e)}>
          <div className="form-row">
            <label htmlFor="code">Code</label>
            <input
              type="text"
              id="code"
              className="admin-input"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoFocus={true}
              required={true}
            />
          </div>

          <div className="form-row">
            <label htmlFor="type">Type</label>
            <select
              id="type"
              className="admin-input"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="fixed">Fixed Amount Discount</option>
              <option value="percentage">Percentage Discount</option>
              <option value="credit">Account Credit</option>
            </select>
          </div>

          {(type === "fixed" || type === "credit") && (
            <div className="form-row">
              <label htmlFor="fixedAmount">Amount</label>
              <span className="input-currency">£</span>
              <input
                type="number"
                id="fixedAmount"
                className="admin-input currency"
                value={fixedAmount}
                onChange={(e) => setFixedAmount(parseFloat(e.target.value))}
                required={type === "fixed"}
              />
            </div>
          )}

          {type === "percentage" && (
            <div className="form-row">
              <label htmlFor="percentageAmount">Percentage Off</label>
              <span className="input-currency">%</span>
              <input
                type="number"
                id="percentageAmount"
                className="admin-input currency"
                value={percentAmount}
                onChange={(e) => setPercentAmount(parseFloat(e.target.value))}
                required={type === "percentage"}
              />
            </div>
          )}

          <div className="row no-padding form-row">
            {type !== "credit" && (
              <div className="col">
                <label htmlFor="unlimitedUses">All raffles</label>
                <input
                  type="checkbox"
                  id="allRaffles"
                  checked={allRaffles}
                  onChange={(e) => setAllRaffles(e.target.checked)}
                />
              </div>
            )}
            <div className="col">
              <label htmlFor="unlimitedUses">All users</label>
              <input
                type="checkbox"
                id="allUsers"
                checked={allUsers}
                onChange={(e) => setAllUsers(e.target.checked)}
              />
            </div>
            <div className="col">
              <label htmlFor="shouldExpire">Expires</label>
              <input
                type="checkbox"
                id="shouldExpire"
                checked={shouldExpire}
                onChange={(e) => setShouldExpire(e.target.checked)}
              />
            </div>
            <div className="col">
              <label htmlFor="unlimitedUses">Unlimited Uses</label>
              <input
                type="checkbox"
                id="unlimitedUses"
                checked={unlimitedUses}
                onChange={(e) => setUnlimitedUses(e.target.checked)}
              />
            </div>
          </div>

          <div className="row no-padding form-row">
            {type === "credit" && (
              <div className="col">
                <label htmlFor="oncePerUser">One use per user</label>
                <input
                  type="checkbox"
                  id="oncePerUser"
                  checked={oncePerUser}
                  onChange={(e) => setOncePerUser(e.target.checked)}
                />
              </div>
            )}
            <div className="col">
              <label htmlFor="firstOrder">
                Only {type === "credit" ? "Before" : "On"} Users First Order
              </label>
              <input
                type="checkbox"
                id="firstOrder"
                checked={firstOrder}
                onChange={(e) => setFirstOrder(e.target.checked)}
              />
            </div>
            <div className="col">
              <label htmlFor="newsletterOnly">Only Users on Newsletter</label>
              <input
                type="checkbox"
                id="newsletterOnly"
                checked={newsletterOnly}
                onChange={(e) => setNewsletterOnly(e.target.checked)}
              />
            </div>
          </div>

          {!allRaffles && (
            <div className="form-row">
              <label>Raffle Whitelist</label>
              <RafflesRestriction
                appliesToRaffles={appliesToRaffles}
                setAppliesToRaffles={setAppliesToRaffles}
              />
            </div>
          )}

          {!allUsers && (
            <div className="form-row">
              <label>Users Whitelist</label>
              <UsersRestriction
                appliesToUsers={appliesToUsers}
                setAppliesToUsers={setAppliesToUsers}
              />
            </div>
          )}

          <div className="row no-padding gutter form-row">
            {shouldExpire && (
              <div className="col">
                <label htmlFor="exp">Expiry</label>
                <input
                  type="datetime-local"
                  id="exp"
                  className="admin-input"
                  value={exp}
                  onChange={(e) => setExp(e.target.value)}
                  required={shouldExpire}
                />
              </div>
            )}

            {!unlimitedUses && (
              <div className="col">
                <label htmlFor="maxUses">Max Uses</label>
                <input
                  type="number"
                  id="maxUses"
                  className="admin-input"
                  value={maxUses}
                  onChange={(e) => setMaxUses(e.target.value)}
                  required={!unlimitedUses}
                />
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-admin">
            {editingCoupon ? "Edit" : "Add"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditCouponModal;
