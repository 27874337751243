import React from "react";
import moment from "moment";
import RaffleModifyView from "./RaffleModifyView";

const CreateRafflePage = () => {
  const raffleData = {
    name: "",
    live: false,
    price: 0,
    originalPrice: 0,
    onSale: false,
    maxEntries: 0,
    maxEntriesPerPerson: 0,
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    endDate: moment().format("YYYY-MM-DDTHH:mm"),
    description: "",
    categories: [],
    searchTags: [],
    ticketMatrix: {
      useLetters: false,
      ticketsPerLetter: 0,
    },
    allowPromoBalance: true,
    luckyDipDefault: 1,
    luckyDipsOnly: false,
    guaranteedWinnerInfoBox: false,
    videoUrl: "",
    images: [],
    instantWins: [],
    instantWinsDescription: ""
  };

  return <RaffleModifyView type="create" raffleData={raffleData} />;
};

export default CreateRafflePage;
