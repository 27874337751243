import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Tiktok, Whatsapp } from "@icons-pack/react-simple-icons";
import { Mail } from "react-feather";

const socialLinks = [
  { name: "Facebook", url: "https://www.facebook.com/massivecompetitions.co.uk/", icon: <Facebook /> },
  { name: "Instagram", url: "https://instagram.com/massive.competitions", icon: <Instagram /> },
  { name: "Tiktok", url: "https://www.tiktok.com/@massive.competitions", icon: <Tiktok /> },
  { name: "WhatsApp", url: "https://api.whatsapp.com/send?phone=447999363333&fbclid=IwAR34j8IUqSZH6h0kqVXhCFpdUuMv1SxW42Z_1qfCFxRT20No0JyWySs-Bbg&_ga=2.58126562.917442613.1651079558-103048675.1647431588", icon: <Whatsapp /> },
  { name: "Email", url: "mailto:support@massive-competitions.co.uk", icon: <Mail /> },
];

const MainFooter = () => {
  return (
    <footer className="app-main-footer" id="footer">
      <div className="row">
        <div className="col">
          <div className="footer-logos">
            <div className="footer-logo"></div>
            <div className="footer-logo-fibonatix"></div>
          </div>
          <p>Massive Competitions Ltd &copy; {new Date().getFullYear()}</p>
          <p>112 Urmston Lane, Stretford, Manchester, M32 9BQ</p>
          <br />
          <p><a href="https://complifi.co.uk" target="_blank" rel="noreferrer noopener">Powered by Complifi.co.uk</a></p>
          <br/>
          <p className="links">
            <Link to="/p/about-us">About Us</Link> | <Link to="/p/acceptable-use">Acceptable Use Policy</Link>{" | "}
            <Link to="/p/terms-and-conditions">Terms and Conditions</Link>
          </p>
          <p className="links">
            <Link to="/p/terms-of-use">Terms of Use</Link> | <Link to="/p/privacy">Privacy Policy</Link>
          </p>
          <div className="social-links">
            {socialLinks.map(({ name, url, icon }) => (
              <a
                href={url}
                key={name}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
