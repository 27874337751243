import React, { useState, useEffect } from "react";
import moment from "moment";
import Axios from "axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RaffleModifyView from "./RaffleModifyView";

const EditRafflePage = () => {
  const { id } = useParams();
  const [err, setErr] = useState(false);
  const [raffleData, setRaffleData] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffles/${id}`)
      .then(({ data }) => {
        setRaffleData({
          ...data.raffle,
          startDate: moment(data.raffle.startDate).format("YYYY-MM-DDTHH:mm"),
          endDate: moment(data.raffle.endDate).format("YYYY-MM-DDTHH:mm"),
        });
      })
      .catch(() => {
        setErr(true);
      });
  }, [id]);

  return err ? (
    "There was a problem getting raffle data"
  ) : !raffleData ? (
    <LoadingSpinner dark={true} className="center " />
  ) : (
    <RaffleModifyView type="edit" raffleData={raffleData} />
  );
};

export default EditRafflePage;
