import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { WithAppContext } from "./context/AppContext";
import AppRouter from "./AppRouter";
import "./app.scss";

const App = () => {
  const [error, setError] = useState(false);

  // Check connection to API
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/api`).catch((e) => {
      setError(true);
    });
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <WithAppContext>
        {!error ? (
          <div className="app">
            <Router>
              <AppRouter />
            </Router>
          </div>
        ) : (
          <code>There was a problem connecting to the Massive Competitions API. Please try again later.</code>
        )}
      </WithAppContext>
    </GoogleOAuthProvider>
  );
};

export default App;
