import React, { useState, useEffect, useContext } from "react";
import { Edit2, Trash2 } from "react-feather";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import moment from "moment";
import { Link } from "react-router-dom";

const DynamicPages = () => {
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);

  let matches = window.location.href.match(/^(https?:\/\/)([^/?#]+)(?:[/?#]|$)/i);
  let domain = matches && (matches[1] + matches[2]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/dynamicPages`).then(({ data }) => {
      setPages(data);
      setLoading(false);
    });
  }, []);

  const deletePage = (id) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      Axios.delete(`${process.env.REACT_APP_API}/api/dynamicPages/${id}`)
        .then(() => {
          setPages(pages.filter((page) => page._id !== id));
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem deleting that page" },
          });
        });
    }
  };

  return (
    <div className="row">
      <div className="col">
        {/* {showAddModal && (
          <AddCategoryModal
            closeModal={() => setShowAddModal(false)}
            categoryAdded={setPageAdded}
          />
        )} */}

        {/* Title */}
        <div className="row no-padding">
          <div className="col">
            <h1 className="admin-title">Pages</h1>
          </div>
          <div className="col right">
            <Link className="btn btn-admin" to="/admin/dynamic-pages/create">
              Add New
            </Link>
          </div>
        </div>

        {/* Table */}
        <table className="raffles-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">
                  <LoadingSpinner dark={true} className="center" />
                </td>
              </tr>
            ) : !pages.length ? (
              <tr>
                <td colSpan="3" className="no-raffles">
                  No pages to show
                </td>
              </tr>
            ) : (
              pages.map(({ _id, name, slug, createdAt }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>
                    <a
                      href={`${domain}/p/${slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`${domain}/p/${slug}`}</a>
                  </td>
                  <td>{moment(createdAt).fromNow()}</td>
                  <td className="right">
                    <Link className="delete-btn" to={`/admin/dynamic-pages/${slug}/edit`}>
                      <Edit2 />
                    </Link>
                    <button className="delete-btn" onClick={() => deletePage(_id)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DynamicPages;
