import React, { useEffect, useState } from "react";
import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/statistics`).then(({ data }) => {
      setStatistics(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h1 className="admin-title">Dashboard</h1>
        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <div className="admin-slate center">
            <div className="row no-padding">
              <div className="col-3">
                <p>Active Raffles</p>
                <h1>{statistics.totalRaffles}</h1>
              </div>
              <div className="col-3">
                <p>Total Tickets Sold</p>
                <h1>{statistics.totalTicketsSold}</h1>
              </div>
              <div className="col-3">
                <p>Total Sales</p>
                <h1>
                  {statistics.totalSales.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </h1>
              </div>
              <div className="col-3">
                <p>Currently Reserved Tickets</p>
                <h1>{statistics.currentReservations}</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
