import React, { useState, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";
import moment from "moment";

const ConfigJustText = ({ title, data: configData = {} }) => {
  const { messages } = useContext(AppContext);
  const [text, setText] = useState(configData.text);
  const [lastUpdated, setLastUpdated] = useState(configData.updatedAt);

  const save = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, { ...configData, text })
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">{title}</h2>

        <form onSubmit={save} className="config-dynamic-text">
          <textarea
            className="admin-input white"
            rows="10"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <div className="dynamic-text-actions">
            <button type="submit" className="btn btn-admin btn-small">
              Save
            </button>
            <small>Last updated {moment(lastUpdated).fromNow()}</small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigJustText;
