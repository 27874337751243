import React, { useState, useContext, useEffect } from "react";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";
import ConfigWinnersTable from "./winners/ConfigWinnersTable";

const ConfigWinners = () => {
  const { messages } = useContext(AppContext);

  const [limit] = useState(5);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [winners, setWinners] = useState([]);
  const [editingItem, setEditingItem] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);

  const [name, setName] = useState("");
  const [raffle, setRaffle] = useState("");
  const [drawDate, setDrawDate] = useState("");
  const [image, setImage] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [liveDrawUrl, setLiveDrawUrl] = useState("");
  const [onlineRaffleUrl, setOnlineRaffleUrl] = useState("");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/winners/count`).then(({ data }) => {
      if (data.count > 0) {
        Axios.post(`${process.env.REACT_APP_API}/api/winners/all/page`, { order: -1, skip: page * limit, limit }).then(({ data }) => {
          setWinners(data.winners);
          setLoading(false);
        });

        var totalPages = Math.floor(data.count / limit);
        setTotalPages(totalPages <= 0 ? 1 : totalPages);
      } else {
        setWinners([]);
        setLoading(false);
      }
    });
  }, [limit, page]);

  useEffect(() => {
    setName("");
    setRaffle("");
    setDrawDate("");
    setImage("");
    setTicketNumber("");
    setLiveDrawUrl("");
    setOnlineRaffleUrl("");
  }, [showAddModal]);

  useEffect(() => {
    if (editingItem < 0) {
      return;
    } else if (editingItem >= winners.length) {
      setEditingItem(-1);
      return;
    }
    
    const winner = winners[editingItem];

    setName(winner.name);
    setRaffle(winner.raffle);
    setDrawDate(moment(winner.drawDate).format("YYYY-MM-DDTHH:mm"));
    setImage(winner.image);
    setTicketNumber(winner.ticketNumber);
    setLiveDrawUrl(winner.liveDrawUrl);
    setOnlineRaffleUrl(winner.onlineRaffleUrl);
  }, [editingItem, winners]);

  const addItem = (e) => {
    e.preventDefault();

    if (!name || !raffle || !drawDate || !image || !ticketNumber || !onlineRaffleUrl)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    const winner = { raffle, name, drawDate, image, ticketNumber, liveDrawUrl, onlineRaffleUrl };

    if (editingItem > -1) {
      setLoading(true);

      Axios.patch(`${process.env.REACT_APP_API}/api/winners/${winners[editingItem]._id}`, winner)
        .then(({ data }) => {
          const newWinners = [...winners];
          newWinners[editingItem] = data.winner;

          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully edited winner" }
          });

          setEditingItem(-1);
          setWinners(newWinners);

          setLoading(false);
        });
    } else {
      Axios.post(`${process.env.REACT_APP_API}/api/winners`, winner)
        .then(({ data }) => {
          const newWinners = [data.winner, ...winners];

          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully added winner" }
          });

          setShowAddModal(false);
          setWinners(newWinners);
        }).catch((err) => {
          if (err.response && err.response.data)
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: err.response.data.msg }
            });
        });
      }
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this winner?")) {
      const winner = winners[index];
      const newWinners = winners.filter((_, i) => i !== index);

      setLoading(true);

      Axios.delete(`${process.env.REACT_APP_API}/api/winners/${winner._id}`)
        .then(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully deleted winner" }
          });

          setWinners(newWinners);
          setLoading(false);
        }).catch((err) => {
          if (err.response && err.response.data)
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: err.response.data.msg }
            });

          setLoading(false);
        });
    }
  };

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(( { data } ) => {
        if (data.file) setImage(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
        }
      });
  };

  return (
    <div>
      {showAddModal && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
        <div className="modal">
          <h2>Add Winner</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label htmlFor="Winner">Winner</label>
              <input
                type="text"
                id="name"
                className="admin-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Raffle">Raffle</label>
              <input
                type="text"
                id="raffle"
                className="admin-input"
                value={raffle}
                onChange={(e) => setRaffle(e.target.value)}
              />
            </div>
            
            <div className="form-row">
              <label htmlFor="Draw Date">Draw Date</label>
              <input
                type="datetime-local"
                className="admin-input"
                value={drawDate}
                onChange={(e) => setDrawDate(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Ticket Number">Ticket Number</label>
              <input
                type="text"
                id="ticketNumber"
                className="admin-input"
                value={ticketNumber}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Live Draw URL">Live Draw URL</label>
              <input
                type="text"
                id="liveDrawUrl"
                className="admin-input"
                value={liveDrawUrl}
                onChange={(e) => setLiveDrawUrl(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Online Raffle URL">Online Raffle URL</label>
              <input
                type="text"
                id="onlineRaffleUrl"
                className="admin-input"
                value={onlineRaffleUrl}
                onChange={(e) => setOnlineRaffleUrl(e.target.value)}
              />
            </div>

            <button type="submit" className="btn btn-admin">
              Add
            </button>
          </form>
        </div>
      </div>
      )}

      {editingItem > -1 && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setEditingItem(-1)}></div>
        <div className="modal">
          <h2>Edit Winner</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label htmlFor="Winner">Winner</label>
              <input
                type="text"
                id="name"
                className="admin-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Raffle">Raffle</label>
              <input
                type="text"
                id="raffle"
                className="admin-input"
                value={raffle}
                onChange={(e) => setRaffle(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Draw Date">Draw Date</label>
              <input
                type="datetime-local"
                className="admin-input"
                value={drawDate}
                onChange={(e) => setDrawDate(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Ticket Number">Ticket Number</label>
              <input
                type="text"
                id="ticketNumber"
                className="admin-input"
                value={ticketNumber}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Live Draw URL">Live Draw URL</label>
              <input
                type="text"
                id="liveDrawUrl"
                className="admin-input"
                value={liveDrawUrl}
                onChange={(e) => setLiveDrawUrl(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Online Raffle URL">Online Raffle URL</label>
              <input
                type="text"
                id="onlineRaffleUrl"
                className="admin-input"
                value={onlineRaffleUrl}
                onChange={(e) => setOnlineRaffleUrl(e.target.value)}
              />
            </div>

            <button type="submit" className="btn btn-admin">
              Edit
            </button>
          </form>
        </div>
      </div>
      )}

      <div className="config-section">
        <div className="row gutter no-padding">
          <div className="col">
            <h2 className="admin-subtitle top">Winners</h2>
          </div>
          <div className="col right">
            <button
              onClick={() => setShowAddModal(true)}
              className="btn btn-admin btn-small"
              style={{ marginTop: 10 }}
            >
              Add New
            </button>
          </div>
        </div>

        <ConfigWinnersTable
          winners={winners}
          deleteItem={deleteItem}
          editItem={setEditingItem}
          loading={loading}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default ConfigWinners;