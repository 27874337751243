const messagesReducer = (state, action) => {
  switch (action.type) {
    case "send": {
      return action.payload;
    }
    case "clear": {
      return {};
    }
    default: {
      throw new Error();
    }
  }
};

export default messagesReducer;
