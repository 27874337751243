import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import AdminRoute from "./components/routes/AdminRoute";
import MainRouter from "./components/MainRouter";
import AuthRouter from "./components/AuthRouter";
import AdminRouter from "./components/AdminRouter";
import { tokenValid } from "./utils/auth";
import { AppContext } from "./context/AppContext";
import LoadingSpinner from "./components/includes/LoadingSpinner";
import Axios from "axios";
import moment from "moment";
import MainLayout from "./components/layouts/MainLayout";
import CompleteRegistration from "./components/auth/CompleteRegistration";
import DynamicPage from "./components/pages/dynamic-page/DynamicPage";

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation();

  const { config, user } = useContext(AppContext);

  const [lastConfigLoadTime, setLastConfigLoadTime] = useState(moment().subtract(15, "s"));
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);

  const isAuthenticated = user.state.isAuthenticated;

  useEffect(() => {
    if (moment().diff(lastConfigLoadTime, "s") < 10) return;

    setLoadingConfig(true);

    Axios.get(`${process.env.REACT_APP_API}/api/config`)
      .then(({ data }) => {
        config.dispatch({ type: "load", payload: data.config });
        setLastConfigLoadTime(moment());
      })
      .finally(() => setLoadingConfig(false));
  }, [config.dispatch, lastConfigLoadTime, location.pathname]); // eslint-disable-line

  // When route changes, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Load in user data
  useEffect(() => {
    const validTokenData = tokenValid();

    setLoadingUser(true);

    if (validTokenData) {
      if (!isAuthenticated) {
        Axios.get(`${process.env.REACT_APP_API}/api/auth`)
          .then(({ data }) => {
            user.dispatch({
              type: "load",
              payload: { isAuthenticated: true, ...validTokenData, ...data.user },
            });
            setLoadingUser(false);
          })
          .catch(() => {
            localStorage.removeItem("sessionToken");
            history.push("/");
          });
      } else {
        setLoadingUser(false);
      }
    } else {
      user.dispatch({ type: "load", payload: { isAuthenticated: false } });
      setLoadingUser(false);
    }
  }, [history, location, isAuthenticated, user.dispatch]); // eslint-disable-line

  return loadingConfig || loadingUser ? (
    <LoadingSpinner className="absolute-center" />
  ) : user.state.confirmationRequired ? (
    <MainLayout>
      <Switch>
        <Route path="/p/:slug">
          <DynamicPage />
        </Route>
        <Route path="*">
          <CompleteRegistration />
        </Route>
      </Switch>
    </MainLayout>
  ) : (
    <Switch>
      {user.state.admin && (
        <AdminRoute path="/admin">
          <AdminRouter />
        </AdminRoute>
      )}
      <Route path="/auth">
        <AuthRouter />
      </Route>
      <Route path="/">
        <MainRouter />
      </Route>
    </Switch>
  );
};

export default AppRouter;
