import React from "react";
import { Switch, Route } from "react-router-dom";
import PageNotFound from "./pages/error/PageNotFound";
import AdminLayout from "./layouts/AdminLayout";
import DashboardPage from "./admin-pages/dashboard/DashboardPage";
import AllRafflesPage from "./admin-pages/all-raffles/AllRafflesPage";
import CreateRafflePage from "./admin-pages/create-edit-raffle/CreateRafflePage";
import RafflePage from "./admin-pages/raffle/RafflePage";
import EditRafflePage from "./admin-pages/create-edit-raffle/EditRafflePage";
import RaffleSalesPage from "./admin-pages/raffle/sales/RaffleSalesPage";
import CategoriesPage from "./admin-pages/categories/CategoriesPage";
import ConfigPage from "./admin-pages/config/ConfigPage";
import UsersPage from "./admin-pages/users/UsersPage";
import DynamicPages from "./admin-pages/dynamic-pages/DynamicPages";
import CreateEditPage from "./admin-pages/dynamic-pages/CreateEditPage";
import CouponCodesPage from "./admin-pages/coupon-codes/CouponCodesPage";
import OrdersPage from "./admin-pages/orders/OrdersPage";
import OrdersExportPage from "./admin-pages/orders/OrdersExportPage";

const AdminRouter = () => {
  return (
    <AdminLayout>
      <Switch>
        <Route path="/admin" exact>
          <DashboardPage />
        </Route>
        <Route path="/admin/raffles" exact>
          <AllRafflesPage />
        </Route>
        <Route path="/admin/raffles/create" exact>
          <CreateRafflePage />
        </Route>
        <Route path="/admin/raffles/:id/edit" exact>
          <EditRafflePage />
        </Route>
        <Route path="/admin/raffles/:id/sales" exact>
          <RaffleSalesPage />
        </Route>
        <Route path="/admin/raffles/:id">
          <RafflePage />
        </Route>
        <Route path="/admin/orders" exact>
          <OrdersPage />
        </Route>
        <Route path="/admin/orders/export" exact>
          <OrdersExportPage />
        </Route>
        <Route path="/admin/categories">
          <CategoriesPage />
        </Route>
        <Route path="/admin/coupon-codes">
          <CouponCodesPage />
        </Route>
        <Route path="/admin/config">
          <ConfigPage />
        </Route>
        <Route path="/admin/dynamic-pages" exact>
          <DynamicPages />
        </Route>
        <Route path="/admin/dynamic-pages/create" exact>
          <CreateEditPage />
        </Route>
        <Route path="/admin/dynamic-pages/:slug/edit" exact>
          <CreateEditPage />
        </Route>
        <Route path="/admin/users">
          <UsersPage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </AdminLayout>
  );
};

export default AdminRouter;
