import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";

import RaffleCard from "./includes/RaffleCard";
import LoadingSpinner from "../../includes/LoadingSpinner";
import { ChevronDown, ChevronUp } from "react-feather";

const CompletedRafflesPage = () => {
  const [startYear] = useState(2021);

  const [allRaffles, setAllRaffles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showMonth, setShowMonth] = useState(false);
  const [showYear, setShowYear] = useState(false);

  const [month, setMonth] = useState(`${moment().month() + 1}`);
  const [year, setYear] = useState(`${moment().year()}`);

  const [months] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const years = [];
    var year = moment().year();

    while (year >= startYear) {
      years.push(`${year}`);
      year--;
    }

    setYears(years);
  }, [startYear]);

  useEffect(() => {
    setShowMonth(false);
    setShowYear(false);

    setLoading(true);

    axios.get(`${process.env.REACT_APP_API}/api/raffles/all/completed/${year}/${month < 10 ? `0${month}` : month}`).then(({ data }) => {
      setAllRaffles(data.raffles);
    }).then(() => setLoading(false));
  }, [month, year]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner className="center" />
      ) : (
        <div className="row">
          <div className="col">
            <div className="row no-padding">
              <div className="col">
                <h1 className="title">Completed Competitions</h1>
              </div>
            </div>

            <div className="row gutter">
              <div className="col">
                <div className="selector-sort-dropdown-container right">
                  <button className="btn btn-white" onClick={() => {
                    setShowMonth(!showMonth);
                    setShowYear(false);
                  }}>
                    {months[month - 1]} {showMonth ? <ChevronUp /> : <ChevronDown />}
                  </button>
                  {showMonth && (
                    <div className="sort-dropdown month">
                      <ul>
                        {months.map((month, i) => (
                          <li onClick={() => setMonth(i + 1)}>{month}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="selector-sort-dropdown-container">
                  <button className="btn btn-white" onClick={() => {
                    setShowMonth(false);
                    setShowYear(!showYear);
                  }}>
                    {year} {showYear ? <ChevronUp /> : <ChevronDown />}
                  </button>
                  {showYear && (
                    <div className="sort-dropdown year">
                      <ul>
                        {years.map((y) => (
                          <li onClick={() => setYear(y)}>{y}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row no-padding gutter">
              {allRaffles.length > 0 ? (
                <>
                  {allRaffles.map((raffle, i) => (
                    <div className="col-4" key={i}>
                      <RaffleCard completed={moment() > moment(raffle.endDate)} {...raffle} />
                    </div>
                  ))}
                  {[allRaffles % 3].fill().map((_, i) => (
                    <div className="col-4" key={i} />
                  ))}
                </>
              ) : (
                <div className="col">
                  <p style={{ marginBottom: "250px", textAlign: "center", textTransform: "uppercase", letterSpacing: "3px" }}>No competitions found for {months[month - 1]} {year}!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompletedRafflesPage;
