import React, { useState, useEffect } from "react";
import Axios from "axios";
import ConfigPromotedRaffles from "./includes/ConfigPromotedRaffles";
import ConfigDynamicText from "./includes/ConfigDynamicText";
import ConfigTestimonials from "./includes/ConfigTestimonials";
import ConfigWinners from "./includes/ConfigWinners";
import LoadingSpinner from "../../includes/LoadingSpinner";
import ConfigFaq from "./includes/ConfigFaq";
import ConfigJustText from "./includes/ConfigJustText";
import ConfigPromoPopup from "./includes/ConfigPromoPopup";
import ConfigStatistics from "./includes/ConfigStatistics";

const ConfigPage = () => {
  const [loading, setLoading] = useState(true);
  const [promotedRaffles, setPromotedRaffles] = useState([]);
  const [statistics, setStatistics] = useState(false);
  const [howItWorks, setHowItWorks] = useState(false);
  const [testimonials, setTestimonials] = useState(false);
  const [featuredWinner, setFeaturedWinner] = useState(false);
  const [faq, setFaq] = useState(false);
  const [referrals, setReferrals] = useState(false);
  const [support, setSupport] = useState(false);
  const [globalPromoPopup, setGlobalPromoPopup] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/config`).then(({ data }) => {
      setPromotedRaffles(data.config.filter(({ type }) => type === "promoted_raffle"));
      setStatistics(data.config.find(({ type }) => type === "statistics"));
      setHowItWorks(data.config.find(({ type }) => type === "how_it_works"));
      setTestimonials(data.config.find(({ type }) => type === "testimonials"));
      setFeaturedWinner(data.config.find(({ type }) => type === "featured_winner"));
      setFaq(data.config.find(({ type }) => type === "faq"));
      setReferrals(data.config.find(({ type }) => type === "referrals"));
      setSupport(data.config.find(({ type }) => type === "support"));
      setGlobalPromoPopup(data.config.find(({ type }) => type === "global_promotional_popup"));
      setLoading(false);
    });
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h1 className="admin-title">Site Configuration</h1>

        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <div>
            <ConfigPromotedRaffles promotedRaffles={promotedRaffles} />

            <ConfigStatistics data={statistics} />

            <ConfigDynamicText title="How it Works" data={howItWorks} />
            <ConfigDynamicText title="Featured Winner" data={featuredWinner} hasHeader={true} />

            <ConfigTestimonials testimonials={testimonials} />

            <ConfigWinners />

            <ConfigFaq faq={faq} />

            <ConfigJustText title="Referrals" data={referrals} />

            <ConfigJustText title="Support" data={support} />

            <ConfigPromoPopup data={globalPromoPopup} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigPage;
