import React, { useState } from "react";

const AddEditPrizeModal = ({ afterSubmit, closeModal, prizeData = {} }) => {
  const [prize, setPrize] = useState(prizeData.prize || "");
  const [ticketNum, setTicketNum] = useState(prizeData.ticketNum || "");

  const onSubmit = () => {
    afterSubmit({ prize, ticketNum });
    closeModal();
  }

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>{prizeData.prize ? "Edit" : "Add"} Prize</h2>

        <div className="form-row">
          <label htmlFor="prize">Prize</label>
          <input
            type="text"
            id="prize"
            className="admin-input"
            value={prize}
            onChange={(e) => setPrize(e.target.value)}
          />
        </div>

        <div className="form-row">
          <label htmlFor="ticket">Ticket Number</label>
          <input
            type="text"
            id="ticket"
            className="admin-input"
            value={ticketNum}
            onChange={(e) => setTicketNum(e.target.value)}
          />
        </div>

        <button onClick={onSubmit} type="button" className="btn btn-admin">
          {prizeData.prize ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddEditPrizeModal;