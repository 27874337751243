import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";

const RafflesRestriction = ({ all = false, appliesToRaffles = [], multiple = true, setAppliesToRaffles }) => {
  const [showAddRafflePopover, setShowAddRafflePopover] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rafflesQuery, setRafflesQuery] = useState("");
  const [rafflesResults, setRafflesResults] = useState([]);

  // Filter categories for search popover
  useEffect(() => {
    setLoading(true);

    const data = {
      query: rafflesQuery,
      filterIds: appliesToRaffles.map(({ _id }) => _id),
      limit: 5,
    };

    if (all)
      data.filterBy = "none";

    Axios.post(`${process.env.REACT_APP_API}/api/raffles/adminBrowse`, data).then(({ data }) => {
      setRafflesResults(data.raffles);
      setLoading(false);
    });
  }, [all, rafflesQuery, appliesToRaffles]);

  const addRaffle = (raffle) => {
    setAppliesToRaffles([...appliesToRaffles, raffle]);
    setShowAddRafflePopover(false);
  };

  const deleteRaffle = (id) => setAppliesToRaffles(appliesToRaffles.filter(({ _id }) => _id !== id));

  return (
    <div>
      <div className="coupon-code-select-raffles">
        {appliesToRaffles.map(({ _id, name }) => (
          <div key={_id} className="tag">
            {name}{" "}
            <button type="button" onClick={() => deleteRaffle(_id)}>
              <X />
            </button>
          </div>
        ))}

        <div className="new-tag-container">
          {(multiple || appliesToRaffles.length === 0) && (
            <div className="tag new" onClick={() => setShowAddRafflePopover(!showAddRafflePopover)}>
              <Plus />
            </div>
          )}

          {showAddRafflePopover && (
            <div className="new-tag-search">
              <input
                type="search"
                className="admin-input"
                placeholder="Search for raffles"
                autoFocus={true}
                value={rafflesQuery}
                onChange={(e) => setRafflesQuery(e.target.value)}
              />

              {loading ? (
                <LoadingSpinner className="center" dark={true} />
              ) : (
                <div className="category-results">
                  {rafflesResults.length ? (
                    rafflesResults.map(({ _id, name }) => (
                      <div key={name} onClick={() => addRaffle({ _id, name })}>
                        {name}
                      </div>
                    ))
                  ) : (
                    <div className="no-cats">No raffles to show</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RafflesRestriction;
