import React from "react";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const token = localStorage.getItem("sessionToken");
  if (token) {
    localStorage.removeItem("sessionToken");
  }

  return <Redirect to="/" />;
};

export default Logout;
