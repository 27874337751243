import React, { useState, useContext } from "react";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";

const ConfigStatistics = ({ data: configData = {} }) => {
  const { messages } = useContext(AppContext);

  const [active, setActive] = useState(configData?.options?.active);
  const [charity, setCharity] = useState(configData?.options?.charity || 0);
  const [prizes, setPrizes] = useState(configData?.options?.prizes || 0);

  const [lastUpdated, setLastUpdated] = useState(configData?.updatedAt);

  const save = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, {
      ...configData,
      options: { active, charity, prizes },
    })
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">Statistics</h2>

        <div className="row no-padding gutter">
          <div className="col">
            <form onSubmit={save} className="config-promo-popup">
              <div className="form-row">
                <label htmlFor="Charity">Charity Total</label>
                <input
                  type="number"
                  id="charity"
                  className="admin-input white"
                  value={charity}
                  onChange={(e) => setCharity(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="Prizes">Prizes Total</label>
                <input
                  type="number"
                  id="prizes"
                  className="admin-input white"
                  value={prizes}
                  onChange={(e) => setPrizes(e.target.value)}
                />
              </div>
              <div className="promo-option">
                <input
                  type="checkbox"
                  id="active"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
                <label htmlFor="active">Display Statistics on Home Page</label>
              </div>

              <button type="submit" className="btn btn-admin btn-small">
                Save
              </button>
              <small>Last updated {moment(lastUpdated).fromNow()}</small>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigStatistics;
