import React from "react";
import { Link, useParams } from "react-router-dom";

const RaffleModifyHeader = ({ type, name, live, update }) => {
  const { id } = useParams();

  return (
    <div className="row no-padding" style={{ marginBottom: 30 }}>
      <div className="col-5">
        <input
          type="text"
          placeholder="Name"
          className="admin-input large white"
          value={name}
          onChange={e => update("name", e.target.value)}
          autoFocus={true}
          required={true}
        />
      </div>
      <div className="col-1">
        <div
          className={`live-tag toggleable ${live ? "live" : "not-live"}`}
          onClick={() => update("live", !live)}
        >
          {live ? "Live" : "Not Live"}
        </div>
      </div>
      <div className="col-6 right">
        <button type="submit" className="btn btn-admin" style={{ marginRight: 20 }}>
          Publish {type === "edit" && "Changes"}
        </button>
        <Link to={`/admin/raffles${type === "edit" ? `/${id}` : ""}`} className="btn btn-admin">
          Back
        </Link>
      </div>
    </div>
  );
};

export default RaffleModifyHeader;
