import React, { useState, useEffect, useContext } from "react";
import { Edit2 } from "react-feather";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";

const ConfigPromotedRaffle = ({ id, large, raffle = {} }) => {
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [selectedRaffle, setSelectedRaffle] = useState(raffle || {});
  const [raffleQuery, setRaffleQuery] = useState("");
  const [raffleResults, setRaffleResults] = useState([]);
  const [showRaffleModal, setShowRaffleModal] = useState(false);
  const [showRaffleFind, setShowRaffleFind] = useState(false);

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/raffles/adminBrowse`, {
      query: raffleQuery,
      limit: 5,
    }).then(({ data }) => {
      setRaffleResults(data.raffles);
      setLoading(false);
    });
  }, [raffleQuery]);

  const save = () => {
    Axios.post(`${process.env.REACT_APP_API}/api/config/${id}`, {
      raffle: selectedRaffle._id,
    }).then(() => {
      messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
      setShowRaffleModal(false);
    });
  };

  return (
    <>
      {showRaffleModal && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setShowRaffleModal(false)}></div>
          <div className="modal">
            <h2>Select Raffle</h2>

            <div className="form-row">
              <div onClick={() => setShowRaffleFind(!showRaffleFind)}>
                <label>Raffle</label>
                <input
                  type="text"
                  value={selectedRaffle.name}
                  className="admin-input"
                  disabled={true}
                />
              </div>

              {showRaffleFind && (
                <div className="find-user-dropdown">
                  <input
                    type="search"
                    className="admin-input"
                    value={raffleQuery}
                    onChange={(e) => setRaffleQuery(e.target.value)}
                    autoFocus={true}
                  />

                  {loading ? (
                    <LoadingSpinner className="center" dark={true} />
                  ) : (
                    <ul>
                      {!raffleResults.length ? (
                        <li>No results found</li>
                      ) : (
                        raffleResults.map((raffle) => (
                          <li
                            onClick={() => {
                              setSelectedRaffle(raffle);
                              setShowRaffleFind(false);
                            }}
                          >
                            {raffle.name}
                          </li>
                        ))
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>

            <button onClick={save} className="btn btn-admin">
              Save
            </button>
          </div>
        </div>
      )}

      <div
        className={`config-promoted-raffle ${large ? "large" : ""}`}
        onClick={() => setShowRaffleModal(true)}
        style={{
          backgroundImage: `url(${
            selectedRaffle.images && selectedRaffle.images.length > 0 ? selectedRaffle.images.find(({ keyImage }) => keyImage).path : ""
          })`,
        }}
      >
        <div className="edit-icon">
          <Edit2 />
        </div>
        <div className="inner">
          <small>{large ? "Main" : "Secondary"}</small>
          <p>{selectedRaffle.name}</p>
        </div>
      </div>
    </>
  );
};

export default ConfigPromotedRaffle;
