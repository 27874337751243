import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import { useHistory, Link } from "react-router-dom";
import RaffleSelectAmount from "./RaffleSelectAmount";
import RaffleSelectAnswer from "./RaffleSelectAnswer";
import { ChevronDown, ChevronUp } from "react-feather";
import Slider from "react-slider";

const POLL_FOR_UNAVAILABLE_TICKETS_IN_SECONDS = 10;

const validateSetAmount = (currentUserTotal, newAmount, raffle, unavailableTickets) => {
  let maxTickets = Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal));

  if (newAmount < 0)
    return 0;
  if (newAmount > maxTickets)
    return maxTickets;
  
  return newAmount;
};

const TicketSelector = ({ available, raffle }) => {
  const { _id, slug, instantWins, maxEntries, maxEntriesPerPerson, luckyDipsOnly, ticketMatrix } = raffle;
  const { useLetters, toLetter, toLetterKey, ticketsPerLetter } = ticketMatrix;

  const hasInstantWins = (instantWins && instantWins.length > 0);
  const letterRange = useLetters
    ? "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").slice(0, toLetterKey + 1)
    : ["A"]; // array of letters

  const history = useHistory();
  const { user, messages } = useContext(AppContext);

  const [numberRange] = useState([...Array(ticketsPerLetter).keys()]);

  const [addingToCart, setAddingToCart] = useState(false);
  const [canAutoSelect, setCanAutoSelect] = useState(false);
  const [currentUserTotal, setCurrentUserTotal] = useState(0);
  const [userAvailable, setUserAvailable] = useState(0);
  const [randomTickets, setRandomTickets] = useState(1);
  const [selected, setSelected] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState("A");
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [unavailableTicketNumbers, setUnavailableTicketNumbers] = useState([]);
  const [unavailableTickets, setUnavailableTickets] = useState([]);
  const [answeringQuestion, setAnsweringQuestion] = useState(false);
  const [selectingAmount, setSelectingAmount] = useState(false);

  const [currentNumberRange, setCurrentNumberRange] = useState([...numberRange]);
  const [currentRange, setCurrentRange] = useState("1-100");
  const [sorterRanges, setSorterRanges] = useState([]);
  const [showNumberSorter, setShowNumberSorter] = useState(false);
  const [useNumberSorter, setUseNumberSorter] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const addToCart = document.getElementById("add-to-cart");
      
      if (addToCart) {
        const ticketSelector = document.getElementById("ticket-selector");

        if (ticketSelector) {
          const cartHeight = addToCart.getBoundingClientRect().height;
          const selectorHeight = ticketSelector.getBoundingClientRect().height;
          const startStickAt = cartHeight + ticketSelector.offsetTop;
          const stopStickAt = (startStickAt + selectorHeight) - cartHeight;

          let bottom = window.innerHeight + window.scrollY;

          if (bottom >= startStickAt && bottom < stopStickAt) {
            addToCart.classList.add("sticky-bottom");
          } else {
            addToCart.classList.remove("sticky-bottom");
          }
        }
      }
    }

    return () => window.onscroll = () => {};
  }, []);

  useEffect(() => {
    const currentNumRange = [];
    
    if (!luckyDipsOnly) {
      const rangeSplit = currentRange.split("-");

      let min = 1;
      let max = numberRange.length;

      if (rangeSplit.length > 1) {
        min = parseInt(rangeSplit[0]);
        max = parseInt(rangeSplit[1]);
      }

      for (var i = (min - 1); i < max; i++) {
        if (i < numberRange.length)
          currentNumRange.push(numberRange[i]);
      }
    }

    setCurrentNumberRange(currentNumRange);
  }, [currentRange, luckyDipsOnly, maxEntries, numberRange]);

  useEffect(() => {
    if (!canAutoSelect || selected) return;
    else if (((!raffle.instantWins || raffle.instantWins.length === 0) && !raffle.luckyDipsOnly) || (raffle.luckyDipDefault || 0) === 0) return;

    setSelected(true);

    const newAmount = validateSetAmount(currentUserTotal, raffle.luckyDipDefault, raffle, unavailableTickets);

    if (newAmount > 0) {
      setRandomTickets(newAmount);
    }
  }, [canAutoSelect, currentUserTotal, raffle, selected, unavailableTickets]);

  useEffect(() => {
    const getCurrentUserTotal = () => {
      if (user.state._id)
        Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/${user.state._id}/total`).then(
          ({ data }) => {
            setCurrentUserTotal(data.total);
            setCanAutoSelect(true);
          }
        )
        .catch(
          () => setCurrentUserTotal(0)
        );
      else
        setCurrentUserTotal(0);
    };

    const getUnavailableTickets = () => {
      Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/unavailable`).then(
        ({ data }) => {
          const numbers = [];
          data.unavailableNumbers.map((number) => numbers.push(number.ticketNum));

          setUnavailableTicketNumbers(numbers);
          setUnavailableTickets(data.unavailableNumbers);
        }
      );
    };

    getCurrentUserTotal();
    getUnavailableTickets();

    const poll = setInterval(
      () => {
        getCurrentUserTotal();
        getUnavailableTickets();
      },
      POLL_FOR_UNAVAILABLE_TICKETS_IN_SECONDS * 1000
    );

    if (maxEntries > 100) {
      const ranges = ["All"];

      for (var i = 1; i < maxEntries; i += 100) {
        ranges.push(`${i}-${i + 99}`);
      }

      setCurrentRange(ranges.length === 1 ? ranges[0] : ranges[1]);
      setSorterRanges(ranges);
      setUseNumberSorter(!luckyDipsOnly && !useLetters);
    }

    return () => clearInterval(poll);
  }, [_id, luckyDipsOnly, maxEntries, user.state._id, useLetters]);

  useEffect(() => {
    setUserAvailable(Math.min(maxEntriesPerPerson, (maxEntries - unavailableTickets.length), (maxEntriesPerPerson - currentUserTotal)));
  }, [currentUserTotal, maxEntries, maxEntriesPerPerson, unavailableTickets]);

  useEffect(() => {
    if (answeringQuestion || selectingAmount)
      document.getElementById("ticket-selector");
  }, [answeringQuestion, selectingAmount]);

  const selectTicket = (ticketNum) => {
    if (selectedTickets.includes(ticketNum)) {
      return setSelectedTickets((tickets) => tickets.filter((ticket) => ticket !== ticketNum)); //remove
    }

    return setSelectedTickets((tickets) => [...tickets, ticketNum]); //add
  };

  const luckyDip = (amount) => {
    let allTickets = new Set();

    const minLetterBound = useLetters ? "A".charCodeAt(0) : false;
    const maxLetterBound = useLetters ? toLetter.charCodeAt(0) : false;
    const minNumberBound = 1;
    const maxNumberBound = ticketsPerLetter;

    if (minLetterBound && maxLetterBound)
      for (let i = minLetterBound; i <= maxLetterBound; i++) {
        for (let j = minNumberBound; j <= maxNumberBound; j++) {
          allTickets.add(String.fromCharCode(i) + j);
        }
      }
    else
      for (let i = minNumberBound; i <= maxNumberBound; i++) {
        allTickets.add(i.toString());
      }

    unavailableTicketNumbers.forEach(ticket => allTickets.delete(ticket));

    allTickets = Array.from(allTickets);
    let selectedTickets = [];

    for (let i = 0; i < amount; i++) {
      let selectedTicket = allTickets[Math.floor(Math.random() * allTickets.length)];

      selectedTickets = [...selectedTickets, selectedTicket];
      allTickets = allTickets.filter((ticket) => ticket !== selectedTicket);
    }
    
    setSelectedTickets(selectedTickets);
    setAnsweringQuestion(true);
  };

  const addToCart = (ticketNums, questionAnswer) => {
    setAddingToCart(true);

    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/reserve`, {
      ticketNums,
      questionAnswer,
    })
      .then(({ data }) => {
        user.dispatch({ type: "addToCart", payload: data.tickets });
        history.push("/cart");
      })
      .catch((err) => {
        setAddingToCart(false);
        if (err.response && typeof err.response.data.msg === "string")
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
      });
  };

  const addToCartHidden = (amount, questionAnswer) => {
    setAddingToCart(true);

    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/reserve/${amount}`, {
      amount,
      questionAnswer,
    }).then(({ data }) => {
      user.dispatch({ type: "addToCart", payload: data.tickets });
      history.push("/cart");
    }).catch((err) => {
      setAddingToCart(false);

      if (err.response && typeof err.response.data.msg === "string")
        messages.dispatch({ type: "send", payload: { type: "error", msg: err.response.data.msg } });
    });
  };

  const findOwner = (ticketNum) => {
    var owner = undefined;

    unavailableTickets.forEach((unavailable) => {
      if (unavailable.ticketNum === ticketNum) {
        return (owner = unavailable.name);
      }
    })

    return owner;
  }

  if (!available)
    return null;

  return (
    <div>
      {userAvailable > 0 && (
        <>
          <div className="raffle-ticket-selector-header">
            <div className="row" style={{ paddingBottom: 0 }}>
              <div className="col">
                <h1 className="title">Select a Ticket</h1>
              </div>
            </div>

            {selectingAmount && (
              <RaffleSelectAmount
                afterSelected={luckyDip}
                onClose={() => setSelectingAmount(false)}
                maxTickets={Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal))}
              />
            )}

            {answeringQuestion && (
              <RaffleSelectAnswer
                question={raffle.question && raffle.question.question}
                answers={raffle.question && raffle.question.answers}
                afterAnswered={(ans) => hasInstantWins || luckyDipsOnly ? addToCartHidden(randomTickets, ans) : addToCart(selectedTickets, ans)}
                onClose={() => setAnsweringQuestion(false)}
              />
            )}

            {!hasInstantWins && !luckyDipsOnly && (
              <div className="row">
                <div className="col lucky-dip-container">         
                  {user.state.isAuthenticated && (
                    <>
                      <button className="btn lucky-dip-btn" onClick={() => setSelectingAmount(true)}>
                        Lucky Dip
                      </button>
                    </>
                  )}

                  {useNumberSorter && (
                    <div className="selector-sort-dropdown-container">
                      <button className="btn number-range-btn" onClick={() => setShowNumberSorter(!showNumberSorter)}>
                        {currentRange} {showNumberSorter ? <ChevronUp /> : <ChevronDown />}
                      </button>
                          
                      {showNumberSorter && (
                        <div className={`sort-dropdown${!user.state.isAuthenticated ? " noauth" : ""}`}>
                          <ul>
                            {sorterRanges.map((range) => (
                              <li onClick={() => {
                                setCurrentRange(range);
                                setShowNumberSorter(false);
                              }}>{range}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div
              className="row"
              style={answeringQuestion || selectingAmount ? { filter: "blur(10px)", pointerEvents: "none" } : {}}
            >
              {useLetters && (
                <div className="col-12 ticket-selector-letters-col">
                    <div className="ticket-selector-letters">
                      {letterRange.map((letter) => (
                        <button
                          key={letter}
                          onClick={() => setSelectedLetter(letter)}
                          className={`selector-btn ${selectedLetter === letter ? "selected" : ""}`}
                        >
                          {letter}
                        </button>
                      ))}
                    </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="raffle-ticket-selector-main" id="ticket-selector"
            style={answeringQuestion || selectingAmount ? { filter: "blur(10px)", pointerEvents: "none" } : {}}
          >
            <div className="row">
              <div className="col">
                <div className="ticket-selector-numbers-container">
                  {!hasInstantWins && !luckyDipsOnly ? (
                    <div className="ticket-selector-numbers">
                      {currentNumberRange.map((i) => {
                        const ticketNum = (useLetters ? selectedLetter : "") + (i + 1);
                        const owner = findOwner(ticketNum);
                        
                        return (
                          <button
                            data-tip
                            data-for="ticketOwner"
                            type="button"
                            data-placement="bottom"
                            key={i}
                            onClick={() => selectTicket(ticketNum)}
                            className={`selector-btn ${
                              selectedTickets.includes(ticketNum) ? "selected" : ""
                            }`}
                            disabled={owner !== undefined}
                          >
                            {owner !== undefined ? (
                              <div className="ownerTooltip">
                                {ticketNum}
                                <div className="bottom">
                                  <p>{ticketNum} - {owner}</p>
                                  <i></i>
                                </div>
                              </div>
                            ) : (
                              <>{ticketNum}</>
                            )}
                          </button>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="raffle-lucky-dip">
                      <h2>Your numbers will be revealed after payment!</h2>

                      <div className="form-row">
                        <label className="raffle-selectans">Select Amount Of Tickets</label>
                        <div style={{ width: "90%", margin: "0 auto" }}>
                          <Slider
                            className="lucky-dip-slider"
                            thumbClassName="lucky-dip-bubble"
                            trackClassName="lucky-dip-slider-track"
                            renderThumb={(props, state) => (<div {...props}><p className="lucky-dip-bubble-text">{state.value}</p></div>)}
                            min={1}
                            max={userAvailable}
                            onChange={(value) => setRandomTickets(value)}
                            value={randomTickets}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div id="add-to-cart" className="add-to-cart-btn-container sticky">
        {user.state.isAuthenticated ? (
          <div>
            {!user.state.verified && (
              <div className="add-to-cart-light-text">
                You must verify your account before buying your first ticket!
              </div>
            )}

            {userAvailable === 0 && (
              <div className="add-to-cart-light-text">
                You are unable to purchase any more tickets for this competition!
              </div>
            )}

            <button
              className="btn add-to-cart-btn"
              disabled={userAvailable === 0 || (!hasInstantWins && !luckyDipsOnly && !selectedTickets.length) || addingToCart || !user.state.verified}
              onClick={() => setAnsweringQuestion(true)}
            >
              {addingToCart ? <LoadingSpinner /> : "Add to Cart"}
              <span className="quantity">{hasInstantWins || luckyDipsOnly ? (userAvailable > 0 ? randomTickets : 0) : selectedTickets.length}</span>
            </button>
          </div>
        ) : (
          <div>
            <div className="add-to-cart-light-text">
              You need to be logged in order to buy a ticket
            </div>
            <Link
              to={`/auth/login?redirect=${encodeURIComponent(`/c/${slug}`)}`}
              className="btn"
            >
              Log in
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketSelector;
