import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";

const UsersRestriction = ({ appliesToUsers = [], multiple = true, setAppliesToUsers }) => {
  const [showAddUserPopover, setShowAddUserPopover] = useState(false);

  const [loading, setLoading] = useState(false);
  const [usersQuery, setUsersQuery] = useState("");
  const [usersResults, setUsersResults] = useState([]);

  // Filter categories for search popover
  useEffect(() => {
    setLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/user/adminBrowse`, {
      query: usersQuery,
      filterIds: appliesToUsers.map(({ _id }) => _id),
      limit: 5,
    }).then(({ data }) => {
      setUsersResults(data.users);
      setLoading(false);
    });
  }, [usersQuery, appliesToUsers]);

  const addUser = (user) => {
    setAppliesToUsers([...appliesToUsers, user]);
    setShowAddUserPopover(false);
  };

  const deleteUser = (id) => setAppliesToUsers(appliesToUsers.filter(({ _id }) => _id !== id));

  return (
    <div>
      <div className="coupon-code-select-raffles">
        {appliesToUsers.map(({ _id, firstName, lastName }) => (
          <div key={_id} className="tag">
            {firstName} {lastName}{" "}
            <button type="button" onClick={() => deleteUser(_id)}>
              <X />
            </button>
          </div>
        ))}

        <div className="new-tag-container">
          {(multiple || appliesToUsers.length === 0) && (
            <div className="tag new" onClick={() => setShowAddUserPopover(!showAddUserPopover)}>
              <Plus />
            </div>
          )}

          {showAddUserPopover && (
            <div className="new-tag-search">
              <input
                type="search"
                className="admin-input"
                placeholder="Search for users"
                autoFocus={true}
                value={usersQuery}
                onChange={(e) => setUsersQuery(e.target.value)}
              />

              {loading ? (
                <LoadingSpinner className="center" dark={true} />
              ) : (
                <div className="category-results">
                  {usersResults.length ? (
                    usersResults.map(({ _id, firstName, lastName }) => (
                      <div key={_id} onClick={() => addUser({ _id, firstName, lastName })}>
                        {firstName} {lastName}
                      </div>
                    ))
                  ) : (
                    <div className="no-cats">No users to show</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersRestriction;
