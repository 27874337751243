import React, { useState, useEffect } from "react";
import { Search } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";
import UsersTable from "./UsersTable";
import Axios from "axios";

const RegularUsers = ({ lastUserUpdated, setLastUserUpdated }) => {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("created-asc");

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/user/adminBrowse`, {
      query,
      admin: false,
      skip: (page - 1) * limit,
      limit,
      sortBy,
    }).then(({ data }) => {
      setUsers(data.users);
      setTotalPages(Math.ceil(data.totalUsers / limit));
      setLoading(false);
    });
  }, [limit, page, query, lastUserUpdated, sortBy]);

  const onSortBy = (newSort) => {
    setPage(1);
    setSortBy(newSort);
  };

  return (
    <div className="row">
      <div className="col">
        <div className="all-raffles-header">
          <div className="row no-padding">
            <div className="col">
              <h1 className="admin-title">Standard Users</h1>
            </div>
            <div className="col right">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <select
                  id="users-sort"
                  className="btn btn-white"
                  value={sortBy}
                  onChange={(e) => onSortBy(e.target.value)}
                >
                  <option value="created-asc">Oldest First</option>
                  <option value="created-desc">Newly Created</option>
                </select>

                <div className="search-bar">
                  <input
                    type="search"
                    value={query}
                    placeholder="Search"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <button>
                    <Search />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {loading ? (
            <LoadingSpinner dark={true} className="center" />
          ) : (
            <UsersTable
              users={users}
              updateUsers={setUsers}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              userUpdated={setLastUserUpdated}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularUsers;
