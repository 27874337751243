import React from "react";

const RaffleModifyVideoLink = ({ url, update }) => {
  return (
    <div className="admin-slate">
      <h2>Video Link</h2>

      <input
        type="url"
        className="admin-input"
        value={url}
        onChange={(e) => update("videoUrl", e.target.value)}
      />
      <p style={{ marginTop: 10, fontSize: "0.8rem" }}>
        The following link must be an embed link: for example{" "}
        <em>https://www.facebook.com/plugins/video.php?...</em>
      </p>
    </div>
  );
};

export default RaffleModifyVideoLink;
