import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const ProtectedRoute = ({ path, children }) => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  return user.state.isAuthenticated ? (
    <Route path={path}>{children}</Route>
  ) : (
    <Redirect to={`/auth/login?redirect=${encodeURIComponent(location.pathname)}`} />
  );
};

export default ProtectedRoute;
