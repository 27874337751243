import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";

const OrdersExportPage = () => {
  const [downloading, setDownloading] = useState(true);
  const [csvData, setCsvData] = useState([]);

  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DDTHH:mm")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DDTHH:mm"));

  useEffect(() => {
    setDownloading(true);

    const downloadCSVData = async () => {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/orders/exportCsv`, {
        dateFrom,
        dateTo,
      });

      setCsvData(
        data.map((ticket) => ({
          orderId: ticket.orderId,
          user: `${ticket?.user?.firstName} ${ticket?.user?.lastName}`,
          status: ticket.status,
          createdAt: ticket.createdAt,
          ticketsPurchased: ticket.ticketsPurchased
            .map(({ raffle, ticketNum }) => `${raffle.name} (#${ticketNum})`)
            .join(", "),
          ticketsSubtotal: ticket.ticketsSubtotal,
          promotionalBalanceUsed: ticket.promotionalBalanceUsed,
          couponCodeUsed: ticket.couponCodeUsed,
          couponCodeDiscount: ticket.couponCodeDiscount,
          totalPricePaidOnCard: ticket.totalPricePaid,
          trustTransactionRef: ticket.trustTransactionRef,
        }))
      );
      setDownloading(false);
    };

    downloadCSVData();
  }, [dateFrom, dateTo]);

  return (
    <div className="row">
      <div className="col">
        <div className="all-raffles-header">
          <div className="row no-padding">
            <div className="col">
              <h1 className="admin-title">Export Orders to CSV</h1>
            </div>
            <div className="col right">
              <Link to="/admin/orders" className="btn btn-admin">
                Back
              </Link>
            </div>
          </div>
        </div>

        <div>
          <div className="form-row">
            <div className="row no-padding gutter">
              <div className="col">
                <label htmlFor="name">Date From</label>
                <input
                  type="datetime-local"
                  id="name"
                  className="admin-input white"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </div>
              <div className="col">
                <label htmlFor="name">Date To</label>
                <input
                  type="datetime-local"
                  id="name"
                  className="admin-input white"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </div>
            </div>
          </div>

          <CSVLink
            data={csvData}
            filename="orders.csv"
            onClick={() => !downloading}
            className="btn btn-admin"
          >
            {downloading ? <LoadingSpinner /> : "Download to CSV"}
          </CSVLink>
        </div>
      </div>
    </div>
  );
};

export default OrdersExportPage;
