import React, { useState, useContext } from "react";
import { Edit2 } from "react-feather";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";
import Dropzone from "react-dropzone";

const ConfigDynamicText = ({ title, data: configData = {}, hasHeader = false }) => {
  const { messages } = useContext(AppContext);

  const [showImageUpload, setShowImageUpload] = useState(false);

  const [header, setHeader] = useState(hasHeader && configData.header ? configData.header : title);
  const [image, setImage] = useState(configData.image);
  const [text, setText] = useState(configData.text);
  const [lastUpdated, setLastUpdated] = useState(configData.updatedAt);

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    messages.dispatch({ type: "send", payload: { type: "info", msg: "Uploading..." } });

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(({ data }) => {
        if (data.file) {
          Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, {
            image: data.file,
          })
            .then(() => {
              messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
              setImage(data.file);
              setShowImageUpload(false);
            })
            .catch((e) => {
              if (e.response && e.response.data)
                messages.dispatch({
                  type: "send",
                  payload: { type: "error", msg: e.response.data.msg.message },
                });
            });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  const save = (e) => {
    e.preventDefault();

    const data = hasHeader ? { ...configData, text, header } : { ...configData, text };

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, data)
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      {showImageUpload && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setShowImageUpload(false)}></div>
          <div className="modal">
            <h2>Upload Image</h2>

            <div className="form-row">
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            <button className="btn btn-admin" onClick={() => setShowImageUpload(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">{title}</h2>

        <div className="row no-padding gutter">
          <div className="col-2">
            <div
              className="config-dynamic-image"
              onClick={() => setShowImageUpload(true)}
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <div className="edit-icon">
                <Edit2 />
              </div>
            </div>
          </div>
          <div className="col-10">
            <form onSubmit={save} className="config-dynamic-text">
              {hasHeader && (
                <div className="admin-input white" style={{ marginBottom: "5px" }}>
                  <input
                    type="text"
                    id="header"
                    className="admin-input white"
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                  />
                </div>
              )}

              <textarea
                className="admin-input white"
                rows="10"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />

              <div className="dynamic-text-actions">
                <button type="submit" className="btn btn-admin btn-small">
                  Save
                </button>
                <small>Last updated {moment(lastUpdated).fromNow()}</small>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigDynamicText;
