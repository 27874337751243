import React, { useEffect, useContext } from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

const HowItWorksPage = () => {
  const { config } = useContext(AppContext);
  const [howItWorks, setHowItWorks] = useState({});

  useEffect(() => {
    setHowItWorks(config.state.find(({ type }) => type === "how_it_works") || {});
  }, [config.state]);

  return (
    <div className="row">
      <div className="col">
        <h1 className="title">How it Works</h1>

        <div className="slate cta-slate">
          <div className="row no-padding gutter">
            <div className="col-6">
              {/* <div
                className="cta-image hide-mobile"
                style={{
                  backgroundImage: `url(${howItWorks.image})`,
                }}
              ></div> */}
              <div className="how-it-works-video">
                <iframe
                  width="100%"
                  height="343"
                  src="https://www.youtube-nocookie.com/embed/7M4wQRzPmJk?modestbranding=1&autohide=1&showinfo=0&controls=0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-6">
              <ReactMarkdown className="md-text" linkTarget="_blank" children={howItWorks.text} />
              <Link to="/all" className="btn">
                Enter Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksPage;
