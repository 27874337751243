import React, { useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { AlertTriangle, AlertCircle, CheckCircle, Info, X } from "react-feather";

const FlashMessage = () => {
  const { messages } = useContext(AppContext);
  const [running, setRunning] = useState(false);

  const onClose = () => {
    messages.dispatch({ type: "clear" });
  };

  if (messages.state.msg && !running) {
    setRunning(true);

    setTimeout(() => {
      messages.dispatch({ type: "clear" });
      setRunning(false);
    }, 10000); //7500
  }

  let { type, msg } = messages.state;
  if (typeof msg === "object") msg = JSON.stringify(msg);

  return (
    <>
      <div
        className={`flash-message ${type}`}
        style={{ top: msg ? 30 : -100, opacity: msg ? 1 : 0 }}
        onClick={onClose}
      >
        <div className="message-icon">
          {type === "error" && <AlertCircle />}
          {type === "warning" && <AlertTriangle />}
          {type === "success" && <CheckCircle />}
          {type === "info" && <Info />}
        </div>
        <div className="message-text">
          <span>{msg}</span>
          <span className="close-icon">
            <X size={16} />
          </span>
        </div>
      </div>
    </>
  );
};

export default FlashMessage;
