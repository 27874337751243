import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { AppContext } from "../../../../context/AppContext";

const Support = () => {
  const { config } = useContext(AppContext);

  const support = config.state.find(({ type }) => type === "support");

  return (
    <div>
      <div className="user-account-main-header">
        <h1>Support</h1>
      </div>

      <ReactMarkdown className="md-text" linkTarget="_blank" children={support && support.text} />
    </div>
  );
};

export default Support;
