import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import TicketItem from "./includes/TicketItem";
import Axios from "axios";
import ReactGA from "react-ga4";
import SortBySwitcher from "./includes/SortBySwitcher";
import { useLocation } from "react-router-dom";
import InstantWin from "./includes/InstantWin";
import Confetti from "react-confetti";
import { X } from "react-feather";
import { AppContext } from "../../../../context/AppContext";

const MyTickets = () => {
  const { user } = useContext(AppContext);

  const location = useLocation();

  const [sortBy, setSortBy] = useState("current");
  const [currentTime, setCurrentTime] = useState(moment());

  const [instantWins, setInstantWins] = useState([]);
  const [raffles, setRaffles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [winner, setWinner] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // If referred from "orderComplete" clear stored cart session details
    if (queryParams.get("ref") === "orderComplete") {
      localStorage.removeItem("usePromoBal");
      localStorage.removeItem("appliedCouponCode");

      let orderId = queryParams.get("orderId");

      if (orderId) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

        Axios.get(`${process.env.REACT_APP_API}/api/orders/my/${orderId}`).then(({ data }) => {
          const order = data;
          const eventData = { transaction_id: order.orderId, currency: "GBP", value: order.totalPricePaid };

          if (order.couponCodeUsed)
            eventData.coupon = order.couponCodeUsed;

          const items = [];

          for (var i in order.ticketsPurchased) {
            var ticket = order.ticketsPurchased[i];
            items.push({ item_name: ticket.raffle.name, currency: "GBP", item_variant: ticket.ticketNum, quantity: 1 });
          }

          if (items.length > 0) {
            eventData.items = items;
            ReactGA.gtag("event", "purchase", eventData);
          }
        }).finally(() => user.dispatch({ type: "clearCart" }));

        Axios.get(`${process.env.REACT_APP_API}/api/instantWins/order/${orderId}`).then(({ data }) => {
          if (data.instantWins && data.instantWins.length > 0) {
            setWinner(data.instantWins);
            setSortBy("instant");
          } else {
            setSortBy("current");
          }
        }).catch(() => {
          setSortBy("current");
        });
      }
    }
  }, [location]); // eslint-disable-line

  useEffect(() => {
    Promise.all([
      Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/purchased`).then(({ data }) => {
        setRaffles(data.raffles);
      }),
      Axios.get(`${process.env.REACT_APP_API}/api/instantWins`).then(({ data }) => {
        setInstantWins(data.instantWins);
      })
    ]).then(() => setLoading(false));
  }, [sortBy]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!raffles.length) return;

    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [raffles.length]);

  const filteredTickets = sortBy !== "instant" ?
    (raffles.filter(({ raffle }) =>
      sortBy === "current"
        ? moment(raffle[0].endDate).isAfter(moment())
        : moment(raffle[0].endDate).isBefore(moment()) 
    )) : [];

  return (
    <div>
      {winner && (
        <div className="modal-container winner">
          <div className="modal-bg winner" onClick={() => setWinner(false)}></div>

          <Confetti
            gravity={0.05}
            numberOfPieces={750}
            style={{ height: "100vh", width: "100vw" }}
          />

          <div className="modal winner">
            <button onClick={() => setWinner(false)} className="close-btn">
              <X />
            </button>

            <h1>Winner winner, chicken dinner!</h1>

            {winner.map((win, i) => {
              if (i > 2)
                return null;

              return (
                <div className="prize">
                  {win.prize}
                </div>
              )
            })}

            {winner.length > 3 ? (
              <div className="info more">
                + {winner.length - 3} more
              </div>
            ) : (
              <div className="info more"></div>
            )}

            <div className="info">
              Congratulations, a ticket in a competition had an instant win prize! We will contact you shortly to arrange delivery of your prize.
            </div>
          </div>
        </div>
      )}

      <div className="row no-padding user-account-main-header">
        <div className="col">
          <h1>
            {sortBy === "current" ? (
              "Current Tickets"
            ) : sortBy === "instant" ? (
              "Instant Wins"
            ) : (
              "Past Tickets"
            )}
          </h1>
        </div>
        <div className="col right">
          <SortBySwitcher
            className="tickets-sorter"
            sortOptions={[
              { sort: "current", name: "Current Tickets" },
              { sort: "instant", name: "Instant Wins" },
              { sort: "past", name: "Past Tickets" },
            ]}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </div>
      </div>

      <div>
        {loading ? (
          <LoadingSpinner className="center" />
        ) : sortBy !== "instant" ? (
          !filteredTickets.length ? (
            <p>There are no tickets to show</p>
          ) : ( 
            filteredTickets.map(({ raffle, tickets }, i) => (
              <TicketItem key={i} currentTime={currentTime} raffle={raffle[0]} tickets={tickets} />
            ))
          )
        ) : (
          !instantWins.length ? (
            <p>There are no instant wins to show</p>
          ) : (
            instantWins.map(({ createdAt, order, prize, raffle, ticketNum }, i) => (
              <InstantWin key={i} createdAt={createdAt} order={order} prize={prize} raffle={raffle} ticketNum={ticketNum} />
            ))
          )
        )}
      </div>
    </div>
  );
};

export default MyTickets;
