import React, { useEffect } from "react";
import FlashMessage from "./includes/FlashMessage";
import MainNavigation from "./includes/MainNavigation";
import MainFooter from "./includes/MainFooter";
import PersistentMessage from "./includes/PersistentMessage";
import CookieNotice from "./includes/CookieNotice";
import PromoPopup from "./includes/PromoPopup";
import TrustpilotBanner from "./includes/TrustpilotBanner";

const getFaviconEl = () => {
  return document.getElementById("favicon");
};

const MainLayout = ({ children }) => {
  useEffect(() => {
    // Change favicon
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "/favicon.ico";

    // Change title
    document.title = "Massive Competitions";

    // Change meta viewport
    document
      .querySelector("meta[name=viewport]")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=0.86, maximum-scale=0.86, minimum-scale=0.86"
      );
  }, []);

  return (
    <div>
      {/* <div className="site-banner">
        Disclaimer: This site is currently a demo and none of the competitions you see are real.
      </div> */}

      <PersistentMessage />
      <TrustpilotBanner />
      <MainNavigation />
      <FlashMessage />
      <div className="app-main-content">{children}</div>
      <MainFooter />
      <CookieNotice />
      <PromoPopup />
    </div>
  );
};

export default MainLayout;
