import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import PageNotFound from "./pages/error/PageNotFound";
import MainLayout from "./layouts/MainLayout";

import HomePage from "./pages/home/HomePage";
import UserAccount from "./pages/account/UserAccount";
import AllRafflesPage from "./pages/raffles/AllRafflesPage";
import CompletedRafflesPage from "./pages/raffles/CompletedRafflesPage";
import RafflePage from "./pages/raffles/RafflePage";
import RaffleRedirectPage from "./pages/raffles/RaffleRedirectPage";
//import ReferralPage from "./pages/referral/ReferralPage";
import Cart from "./pages/cart/Cart";
import HowItWorksPage from "./pages/how-it-works/HowItWorksPage";
import FaqPage from "./pages/faq/FaqPage";
import DynamicPage from "./pages/dynamic-page/DynamicPage";
import OrderPayment from "./pages/order-payment/OrderPayment";
import WinnersPage from "./pages/winners/WinnersPage";

const MainRouter = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>

        <Route path="/p/:slug">
          <DynamicPage />
        </Route>

        {/*
        <Route path="/r/:code">
          <ReferralPage />
        </Route>
        */}

        <Route path="/how-it-works">
          <HowItWorksPage />
        </Route>

        <Route path="/faq">
          <FaqPage />
        </Route>
        
        {/* Raffle Routes */}
        <Route path="/all">
          <AllRafflesPage />
        </Route>

        <Route path="/completed">
          <CompletedRafflesPage />
        </Route>

        <Route path="/raffles">
          <Redirect to="/all" />
        </Route>

        <Route path="/c/:slug">
          <RafflePage />
        </Route>

        <Route path="/raffle/:slug">
          <RaffleRedirectPage />
        </Route>

        <Route path="/winners">
          <WinnersPage />
        </Route>

        <ProtectedRoute path="/cart">
          <Cart />
        </ProtectedRoute>

        <ProtectedRoute path="/order-payment">
          <OrderPayment />
        </ProtectedRoute>

        <ProtectedRoute path="/account">
          <UserAccount />
        </ProtectedRoute>

        <Route path="*">
          <PageNotFound />
        </Route>

      </Switch>
    </MainLayout>
  );
};

export default MainRouter;
