const usersReducer = (state, action) => {
  switch (action.type) {
    case "load": {
      return action.payload;
    }
    case "update": {
      return { ...state, ...action.payload };
    }
    case "addToCart": {
      return { ...state, cart: [...state.cart, ...action.payload] };
    }
    case "removeFromCart": {
      return { ...state, cart: [...state.cart.filter(({ _id }) => _id !== action.payload)] };
    }
    case "clearCart": {
      return { ...state, cart: [] };
    }
    default: {
      throw new Error();
    }
  }
};

export default usersReducer;
