import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import { Slider } from "infinite-react-carousel";
import { X } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";
import CartItem from "./includes/CartItem";
import CartCheckoutSummary from "./CartCheckoutSummary";
import RaffleCard from "../raffles/includes/RaffleCard";
import useMediaQuery from "../../../utils/useMediaQuery";

const Cart = () => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 1110px)");

  const { config, messages } = useContext(AppContext);
  const promoPopup = config.state.find(({ type }) => type === "global_promotional_popup");

  const [canShowPopup, setCanShowPopup] = useState(true);
  const [currentTime, setCurrentTime] = useState(moment());
  const [displayPopup, setDisplayPopup] = useState(false);
  const [otherRaffles, setOtherRaffles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const dispatchMessage = messages.dispatch;

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/reserved`)
      .then(({ data }) => {
        setLoading(false);
        if (data.tickets) setTickets(data.tickets);
        if (data.totalPrice) setCartTotal(data.totalPrice);
      })
      .catch((e) => {
        setLoading(false);
        dispatchMessage({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem retrieving your cart. Please try again later.",
          },
        });
      });
  }, [dispatchMessage]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    };

    Axios.get(`${process.env.REACT_APP_API}/api/raffles/all`).then(({ data }) => {
      const otherRaffles = shuffleArray(data.raffles);

      const ticketsIn = new Set();
      tickets.forEach((ticket) => ticketsIn.add(ticket.raffle._id));
      
      for (var i = 0; i < otherRaffles.length; i++)
        if (ticketsIn.has(otherRaffles[i]._id))
          otherRaffles.splice(i--, 1);

      if (otherRaffles.length < 1)
        setCanShowPopup(false);

      setOtherRaffles(otherRaffles);
    });

    if (localStorage.getItem("upsellPopup") === null)
      localStorage.setItem("upsellPopup", "true");

    setCanShowPopup(localStorage.getItem("upsellPopup") === "true");
  }, [tickets]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!tickets.length) return;

    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [tickets.length]);

  const doNotShow = async () => {
    try {
      localStorage.setItem("upsellPopup", "false");
      history.push(`order-payment`)
    } catch (err) {
      if (err.response && err.response.data) {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg },
        });
      }
    }
  };

  const removeTicket = (ticket) => {
    const newTickets = tickets.filter((t) => t._id !== ticket._id);

    setTickets(newTickets);
    setCartTotal(cartTotal - ticket.raffle.price);
  };

  return (
    <div className="row">
      <div className="col cart">
        <h1 className="title">Cart</h1>

        {loading ? (
          <div className="center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div style={displayPopup ? { filter: "blur(10px)" } : {}}>
              <div className="slate cart-items">
                {!tickets.length ? (
                  <p>Your cart is empty</p>
                ) : (
                  tickets.map((ticket, i) => (
                    <CartItem
                      key={i}
                      currentTime={currentTime}
                      ticket={ticket}
                      removeTicket={() => removeTicket(ticket)}
                    />
                  ))
                )}
              </div>

              {!!tickets.length &&
                <CartCheckoutSummary
                  subTotal={cartTotal}
                  triggerPopup={canShowPopup && promoPopup.options.upsellActive ? () => setDisplayPopup(true) : undefined}
                />
              }
            </div>

            {displayPopup && otherRaffles.length > 0 && (
              <div className="global-promo-popup-container">
                <div className="global-promo-popup-bg" onClick={() => setDisplayPopup(false)}></div>
        
                <div className="global-promo-popup-modal">
                  <button className="global-promo-popup-closebtn" onClick={() => setDisplayPopup(false)}>
                    <X />
                  </button>
                  <div className="global-promo-popup-upsell">
                    <h1 className="title popup">Did you see these?</h1>
                    <Slider
                      autoplay={true}
                      autoplayScroll={1}
                      autoplaySpeed={5000}
                      className={"other-raffle-slider" + (isMobile ? " mobile" : "")}
                      arrows={true}
                      dots={false}
                      slidesToShow={isMobile ? 1 : 2}
                      virtualList={true}
                    >
                      {otherRaffles.map((raffle, i) => (
                        <div className="carousel-slide" key={i}>
                          <div
                            className="other-raffle"
                          >
                            <RaffleCard completed={false} {...raffle} upsell={true} />
                          </div>
                        </div>
                      ))}
                    </Slider>
                    
                    <div className="global-promo-popup-upsell-continue">
                      <button className="btn btn-popup" onClick={() => history.push(`order-payment`)}>Continue to Checkout</button>
                      <Link className="do-not-show-again" onClick={doNotShow} to="#">Do not show this again</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
